import React, { useEffect, useRef, useState } from 'react';
import { Container, Button, Form, Modal, } from 'react-bootstrap';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import CustomiseWorkspaceStandard from './initiateWorkspace/CustomiseStandard';
import { useWorkspaceStandard } from '../../../contexts/InitiateContexts';
import '../../../App.scss';
import { useParams } from "react-router-dom";
import CustomAlert from '../shared/CustomAlert';
import BlueprintPartPicker from '../shared/BlueprintPartPicker';
import { AutoAwesome } from '@mui/icons-material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InitiateWorkspace = ({ handleMenuSelection, fetchWorkspaces, blueprints, triggerAlert }) => {
    const { currentOrg, workspace } = useParams();
    const { workspaceStandard, setWorkspaceStandard } = useWorkspaceStandard();
    const [currentStep, setCurrentStep] = useState(0);
    const [showOverwriteConfirmation, setShowOverwriteConfirmation] = useState(false);
    const cancelTokenSource = useRef(null); // To handle cancel requests
    const [selectedBlueprint, setSelectedBlueprint] = useState({});

    const handleBlueprintSelect = (blueprint) => {

        if (selectedBlueprint === blueprint) {
            setSelectedBlueprint({})
        } else {
            setSelectedBlueprint(blueprint);
        }
    };

    const handlePrimaryAction = () => {

        if (currentStep === 0) {
            // Check if they have selected a blueprint
            if (Object.keys(selectedBlueprint).length > 0) (

                // Add blueprint classifiers to the workspaceStandard
                setWorkspaceStandard((prevState) => ({
                    ...prevState,
                    classifiers: Object.values(selectedBlueprint.classifiers),
                }))
            )

            // Move to next step
            setCurrentStep(1)
        }
        // Must be on the second step.
        else {
            // Check if the workspaceStandard has any classifiers
            if (workspaceStandard.classifiers.length > 0) {
                // Commit the workspace standard to storage and redirect to the workspace page
                handleSessionCommit()
            } else {
                triggerAlert('Categories cannot be empty', 'warning')
            }
        }
    };

    const handleSecondaryAction = () => {
        // Apply skip behaviour
        if (currentStep === 0) {
            setCurrentStep(1)
        }
        // Must be on the second step
        else {
            if (workspaceStandard.classifiers.length > 0) {
                setShowOverwriteConfirmation(true)
            }
            else {
                setCurrentStep(0)
            }

            setSelectedBlueprint({})

        }
    }

    const handleSessionCommit = async () => {

        const workspaceStandardJSON = JSON.stringify(workspaceStandard);
        const workspaceStandardJSONforBlob = new Blob([workspaceStandardJSON], { type: 'application/json' });

        const formData = new FormData();
        formData.append('file', workspaceStandardJSONforBlob, 'workspace_standard.json');
        formData.append('organisation', currentOrg);
        formData.append('workspace', workspace);

        cancelTokenSource.current = axios.CancelToken.source();

        try {

            // Perform the POST request to upload the file
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/api/CompleteWorkspaceConfigTrigger`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY, // Add function key if necessary
                    },
                    cancelToken: cancelTokenSource.current.token,
                }
            );

            // Handle success response
            console.log('File uploaded successfully:', response.data);
            triggerAlert('Workspace created'); // Show a success message
        } catch (error) {
            console.error('Error uploading file:', error);
            triggerAlert('Error uploading workspace standard.', 'Error');
        }
        handleMenuSelection(`${currentOrg}/${workspace}`, 'workspace')
        fetchWorkspaces(currentOrg)
    };

    const handlePageRender = () => {
        if (currentStep === 0) {
            return <>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    {/* Title */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4a4bd6', flexGrow: 1 }}>
                        Select blueprint
                    </Typography>
                </Box>
                <BlueprintPartPicker
                    blueprints={blueprints}
                    onBlueprintSelect={handleBlueprintSelect}
                    selectedBlueprint={selectedBlueprint}
                    setSelectedBlueprint={setSelectedBlueprint}
                />
            </>
        }
        else {
            return <CustomiseWorkspaceStandard triggerAlert={triggerAlert} />
        }
    }

    return (
        <div>
            <Container className="pt-3 mb-5">
                {handlePageRender()}
            </Container>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    height: '64px', // Adjust the height as needed
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to the top of the bar
                    padding: '0 1.5rem'
                }}
            >
                <Box sx={{display: 'flex', color: 'grey'}}>
                <AutoAwesome/>
                <Typography sx={{mx: 1}}>
                    Coming soon - generate blueprint using AI!
                </Typography>
                    </Box>
                    
                <Box sx={{display: 'flex'}}>
                <button
                    style={{
                        all: 'unset', // Removes default button styling
                        color: 'blue',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        marginRight: '8px'
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'darkblue')}
                    onMouseLeave={(e) => (e.target.style.color = 'blue')}
                    onClick={() => handleSecondaryAction()}
                >
                    {currentStep === 0 ? "Start from blank canvas" : 'Go back'}
                </button>
                {/* Continue Button */}
                <button
                    className='btn btn-primary mt-2'
                    onClick={() => handlePrimaryAction()}
                    disabled={Object.keys(selectedBlueprint).length === 0}
                >
                    {currentStep === 1 ? 'Finish' : 'Continue'}
                </button>
                </Box>
            </Box>
            <Modal show={showOverwriteConfirmation} onHide={() => setShowOverwriteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>All changes will be lost.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowOverwriteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={
                            () => {
                                setWorkspaceStandard((prevState) => ({
                                    ...prevState,
                                    classifiers: [],
                                }))
                                setShowOverwriteConfirmation(false)
                                setCurrentStep(0)
                            }
                        }
                    >
                        I'm sure
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InitiateWorkspace;
