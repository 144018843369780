import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import RequestADemo from './RequestADemo';
import NavBar from './NavBar'
import FooterBar from '../subcomponents/shared/FooterBar';
import Popup from '../subcomponents/shared/Popup';
import ArticleCard from '../subcomponents/shared/ArticleCard';
import { Box, Card, Chip, Grid, Stack, Typography, Container, Accordion, AccordionSummary, AccordionDetails, Tab } from '@mui/material';
import MainHeader from './MainHeader';
import { ArrowDownwardOutlined, ExpandMore, OpenInNew } from '@mui/icons-material';


const Product = () => {
    const [showModal, setShowModal] = useState(false)
    const [expanded, setExpanded] = useState('');

    const faqs = [
        {
            question: "What is Hoppa?",
            answer: `Hoppa is the first-of-its-kind data refinery for the built environment, allowing you to cleanse, restructure and extract intelligence from whole-life asset data in a few clicks. 

Built using state-of-the-art AI, Hoppa’s technology augments your existing workflows as you manage your asset portfolio, gear up for construction handover, or ready for new regulation (e.g. ISO 19650). `
        },
        {
            question: "How does it work?",
            answer: `Hoppa uses state-of-the-art AI and custom-built connectors to crack, analyse and label your documents. Hoppa Workbench, our analytics product, is cloud-agnostic by design and can be deployed to any geography or architecture.`
        },
        {
            question: "Why choose Hoppa?",
            answer: `Our services enable you to tackle data challenges at scales you'd previously only dreamt of. 

We tailor our offering to suit your needs - anywhere from licensing our technology through to data enrichment services led by our team - freeing you up to focus on the steps that add most value to your business.`
        },
        {
            question: "Is my data secure?",
            answer: `Yes. From the development of new features to the way we implement technical and organisational privacy measures, data protection is fundamental to maintaining and building trust with our customers. 

Hoppa is Cyber Essentials Plus certified, which means our security controls (from firewalls to access controls and malware protection) have been independently assessed by experts. We apply the UK National Cyber Security Centre (NCSC) Cloud Security Principles throughout the software development lifecycle. Copies of our data and privacy policies and a template data processing addendum can be accessed from our website.`
        },
    ]

    const partners = [
        {
            img: 'google-logo.svg',
            title: 'Google',
            height: '25px'
        },
        {
            img: 'openai-logo.svg',
            title: 'OpenAI',
            height: '25px'
        },
        {
            img: 'autodesk-logo.svg',
            title: 'Autodesk',
            height: '20px'
        },
        {
            img: 'microsoft-logo.svg',
            title: 'Microsoft',
            height: '25px'
        },
    ]

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box style={{
            backgroundColor: '#f0f8ff',
            minHeight: '100vh',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0
        }}
        >
            <NavBar variant='blue' fixed={true} />
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    minHeight: '600px',
                    height: '100%',
                    maxHeight: '100vw',
                    zIndex: -1,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/purple-haze.png)`,
                    backgroundSize: { xs: '500px', md: '200vw' }, // Ensures the image covers the entire area
                    backgroundPosition: { xs: 'top 0% left 50%', md: 'top 90% right -125vw' },
                    backgroundRepeat: 'no-repeat', // Prevents tiling of the image
                }}
            ></Box>
            <Box sx={{
                maxWidth: '1320px',
                width: '90%',
                padding: '0 2%',
                paddingTop: { xs: '100px', sm: '120px' },
                paddingBottom: '0px',
                display: 'flex',         // Enables flexbox
                flexDirection: 'column', // Ensures the content stacks vertically
                alignItems: 'center',    // Centers horizontally
                margin: '0 auto',        // Horizontally center the box
            }}>
                <Stack
                    spacing={4}
                    direction={{ xs: 'column-reverse', md: 'row' }}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Chip
                            label='Product'
                            sx={{
                                backgroundColor: 'transparent',
                                border: '1px solid #0051b6',
                                color: '#0051b6',
                                m: '8px 0'
                            }}
                        />
                        <p className='blue-font xlarge-font bold-statement'>
                            The AI engine room for your project
                        </p>
                        <Typography sx={{ width: { xs: '100%', sm: '70%' } }}>
                            Easily create and run workflows to make data fit for human use. Configurable across web, desktop and cloud for seamless knowledge management without the overhead.
                        </Typography>
                        <button className='btn btn-primary ms-0 mt-2'>
                        <a href="https://www.youtube.com/watch?v=HZPVp-XoIS4" target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>Watch a demo</a>
                        <OpenInNew sx={{pl:1}}/>
                        </button>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            sx={{
                                maxWidth: { xs: '450px', md: '1000px' },
                                width: { xs: '100%', md: '120%' },
                                display: 'block',
                                marginLeft: { xs: '-3%', sm: '10%' }, // Shifts image to the left on xs to centre the main screenshot
                            }}
                            src={`${process.env.PUBLIC_URL}/product-hero.png`}
                            alt="Product Hero"
                        />
                    </Box>
                </Stack>
            </Box>
            <Box style={{ backgroundColor: 'transparent', margin: '64px 0', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        maxWidth: '1320px',
                        width: '90%',
                        padding: { xs: '0px 2%', sm: '0px 10%' },
                        margin: '0 auto',
                        minHeight: '100%',
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}
                >
                    <Stack direction='column' sx={{ alignItems: 'center' }}>
                        <Typography sx={{ color: 'grey' }}>
                            Building on:
                        </Typography>
                        <Stack direction="row" spacing={3} useFlexGap sx={{ m: '16px 0', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {partners.map((item) => (
                                <img
                                    src={`${process.env.PUBLIC_URL}/${item.img}`}
                                    loading="lazy"
                                    style={{
                                        maxHeight: `${item.height}`, // Set a consistent height
                                        width: 'auto', // Maintain aspect ratio
                                        display: 'block'
                                    }}
                                    alt={item.title}
                                />
                            ))}
                            <Typography sx={{ color: 'grey' }}>
                                and others
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <Box sx={{
                maxWidth: '1320px',
                width: '90%',
                padding: { xs: '0 2%' },
                margin: '0 auto',        // Horizontally center the box
                marginBottom: '50px',
            }}>
                <Card sx={{ borderRadius: '16px', marginBottom: '32px' }}>
                    <Stack
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '15vw',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Stack direction='column' sx={{ textAlign: 'left', minWidth: '55%', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: { xs: '0 32px', md: '32px 0' },
                                marginLeft: { md: '48px' },
                                marginBottom: { xs: '32px' }
                            }}
                            >
                                <Chip
                                    label='Integrations'
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #0051b6',
                                        color: '#0051b6',
                                        m: '8px 0'
                                    }}
                                />
                                <Typography variant='h4' sx={{ color: '#0051b6' }}>
                                    Connect to your data, anywhere, any time
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Pull from your favourite data sources and 17+ data types to assemble your single source of truth for whole-life built asset data.
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    You choose which documents to analyse, in batches or in bulk. Documents remain in the source so you retain control of who has access to your data.
                                </Typography>
                                <button className='btn btn-primary ms-0 mt-3'>
                                    <a href="/integrations" style={{ textDecoration: 'none', color: 'inherit' }}>Find out more</a>
                                </button>
                            </Box>
                        </Stack>
                        <Box sx={{ minWidth: '45%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '80%', md: '100%' }, // Ensures responsive width
                                    aspectRatio: 1.2,
                                    objectFit: 'cover', // Crops top/bottom while maintaining aspect ratio
                                    objectPosition: 'center', // Adjusts cropping (optional)
                                }}
                                src={`${process.env.PUBLIC_URL}/integrations-wheel-haze.png`}
                            />
                        </Box>

                    </Stack>
                </Card>
                <Card sx={{ borderRadius: '16px', marginBottom: '32px' }}>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '15vw',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ minWidth: '45%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '80%', md: '100%' }, // Ensures responsive width
                                    aspectRatio: 1.2,
                                    objectFit: 'cover', // Crops top/bottom while maintaining aspect ratio
                                    objectPosition: 'center', // Adjusts cropping (optional)
                                }}
                                src={`${process.env.PUBLIC_URL}/product-features-2.png`}
                            />
                        </Box>
                        <Stack direction='column' sx={{ textAlign: 'left', minWidth: '55%', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: { xs: '0 32px', md: '32px 0' },
                                marginRight: { md: '48px' },
                                marginBottom: { xs: '32px' }
                            }}
                            >
                                <Chip
                                    label='Workbench'
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #0051b6',
                                        color: '#0051b6',
                                        m: '8px 0'
                                    }}
                                />
                                <Typography variant='h4' sx={{ color: '#0051b6' }}>
                                    Kit-of-parts approach tailored to your workflows

                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Workbench is our purpose-built library of AI agents and tools for cracking, analysing and refining your data.
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Constructed specifically for AECO, Workbench delivers speed-to-value you won’t find in general purpose workflow automation engines.
                                </Typography>
                                <button className='btn btn-primary ms-0 mt-3' onClick={() => setShowModal(true)}>
                                    Find out more
                                </button>
                            </Box>
                        </Stack>
                    </Stack>
                </Card>
                <Card sx={{ borderRadius: '16px', marginBottom: '32px' }}>
                    <Stack
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '15vw',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Stack direction='column' sx={{ textAlign: 'left', minWidth: '55%', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: { xs: '0 32px', md: '32px 0' },
                                marginLeft: { md: '48px' },
                                marginBottom: { xs: '32px' }
                            }}
                            >
                                <Chip
                                    label='Web interface'
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #0051b6',
                                        color: '#0051b6',
                                        m: '8px 0'
                                    }}
                                />
                                <Typography variant='h4' sx={{ color: '#0051b6' }}>
                                    Human-in-the-loop AI delivering superior data quality
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Leverage web-based tools for quality assuring analysis results and managing your data connections and information standards.
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Our AI is traceable, transparent and modest. It explains its thinking and flags when human assistance is needed - unlocking the last 10% on quality at no extra effort.
                                </Typography>
                                <button className='btn btn-primary ms-0 mt-3' onClick={() => setShowModal(true)}>
                                    Find out more
                                </button>
                            </Box>
                        </Stack>
                        <Box sx={{ minWidth: '45%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                    position: 'relative', // Enables absolute positioning for the GIF
                                    width: { xs: '80%', md: '100%' },
                                    aspectRatio: 1.2,
                                    overflow: 'hidden', // Prevents GIF from overflowing the container
                                    backgroundImage: `url(${process.env.PUBLIC_URL}/purple-haze-2.png)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                {/* GIF positioned on top */}
                                <Box
                                    component="img"
                                    src={`${process.env.PUBLIC_URL}/hoppa_gif_results.gif`}
                                    sx={{
                                        position: 'absolute', // Places the GIF on top
                                        top: '50%', // Centers vertically
                                        left: '50%', // Centers horizontally
                                        transform: 'translate(-50%, -50%)', // Aligns the center properly
                                        width: '80%', // Adjust as needed
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    }}
                                    alt="Animated overlay"
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Card>
                <Card sx={{ borderRadius: '16px', marginBottom: '32px' }}>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '15vw',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ minWidth: '45%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '80%', md: '100%' }, // Ensures responsive width
                                    aspectRatio: 1.2,
                                    objectFit: 'cover', // Crops top/bottom while maintaining aspect ratio
                                    objectPosition: 'center', // Adjusts cropping (optional)
                                }}
                                src={`${process.env.PUBLIC_URL}/product-features-4.png`}
                            />
                        </Box>
                        <Stack direction='column' sx={{ textAlign: 'left', minWidth: '55%', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: { xs: '0 32px', md: '32px 0' },
                                marginRight: { md: '48px' },
                                marginBottom: { xs: '32px' }
                            }}
                            >
                                <Chip
                                    label='AI Search'
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #0051b6',
                                        color: '#0051b6',
                                        m: '8px 0'
                                    }}
                                />
                                <Typography variant='h4' sx={{ color: '#0051b6' }}>
                                    Break down silos by surfacing buried data
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Harness the natural language processing powers of LLMs to extract named entities or structured data types locked up in your files.
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Whether your handing over data or sifting through incoming documents, a first-pass through Hoppa will surface those needles in the haystack.
                                </Typography>
                                <button className='btn btn-primary ms-0 mt-3' onClick={() => setShowModal(true)}>
                                    Find out more
                                </button>
                            </Box>
                        </Stack>
                    </Stack>
                </Card>
                <Card sx={{ borderRadius: '16px', marginBottom: '32px' }}>
                    <Stack
                        direction={{ xs: 'column-reverse', md: 'row' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '15vw',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Stack direction='column' sx={{ textAlign: 'left', minWidth: '55%', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: { xs: '0 32px', md: '32px 0' },
                                marginLeft: { md: '48px' },
                                marginBottom: { xs: '32px' }
                            }}
                            >
                                <Chip
                                    label='Blueprints'
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #0051b6',
                                        color: '#0051b6',
                                        m: '8px 0'
                                    }}
                                />
                                <Typography variant='h4' sx={{ color: '#0051b6' }}>
                                    Out-of-the-box intelligence, optimised for construction

                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Start ready loaded with information standards like NBS Uniclass and the UK National Annex to ISO 19650. Our team of AECO natives are on-hand to extend the library to suit your specification.
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Try our free Text-to-Uniclass tool today and experience the benefits of interoperable data.
                                </Typography>
                                <button className='btn btn-primary ms-0 mt-3'>
                                <a href="/text-to-uniclass" style={{ textDecoration: 'none', color: 'inherit' }}>Try now for free</a>
                                </button>
                            </Box>
                        </Stack>
                        <Box sx={{ minWidth: '45%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                sx={{
                                    position: 'relative', // Enables absolute positioning for the GIF
                                    width: { xs: '80%', md: '100%' },
                                    aspectRatio: 1.2,
                                    overflow: 'hidden', // Prevents GIF from overflowing the container
                                    backgroundImage: `url(${process.env.PUBLIC_URL}/purple-haze-2.png)`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            >
                                {/* GIF positioned on top */}
                                <Box
                                    component="img"
                                    src={`${process.env.PUBLIC_URL}/uniclass-gif.gif`}
                                    sx={{
                                        position: 'absolute', // Places the GIF on top
                                        top: '50%', // Centers vertically
                                        left: '50%', // Centers horizontally
                                        transform: 'translate(-50%, -50%)', // Aligns the center properly
                                        width: '80%', // Adjust as needed
                                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                    }}
                                    alt="Animated overlay"
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Card>
                <Card sx={{ borderRadius: '16px', marginBottom: '32px' }}>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            minHeight: '15vw',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box sx={{ minWidth: '45%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box
                                component="img"
                                sx={{
                                    width: { xs: '80%', md: '100%' }, // Ensures responsive width
                                    aspectRatio: 1.2,
                                    objectFit: 'cover', // Crops top/bottom while maintaining aspect ratio
                                    objectPosition: 'center', // Adjusts cropping (optional)
                                }}
                                src={`${process.env.PUBLIC_URL}/product-features-6.png`}
                            />
                        </Box>
                        <Stack direction='column' sx={{ textAlign: 'left', minWidth: '55%', justifyContent: 'center' }}>
                            <Box sx={{
                                margin: { xs: '0 32px', md: '32px 0' },
                                marginRight: { md: '48px' },
                                marginBottom: { xs: '32px' }
                            }}
                            >
                                <Chip
                                    label='Agents'
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: '1px solid #0051b6',
                                        color: '#0051b6',
                                        m: '8px 0'
                                    }}
                                />
                                <Typography variant='h4' sx={{ color: '#0051b6' }}>
                                    Scale your team to match the workload


                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Custom agents augment information management teams, allowing one person to do the work of twelve. When cashflow is tight and teams are stretched Hoppa helps you hit your numbers.
                                </Typography>
                                <Typography sx={{ p: '8px 0' }}>
                                    Experiment with our pricing calculator to see the competitive advantage you could gain.
                                </Typography>
                                <button className='btn btn-primary ms-0 mt-3'>
                                <a href="/#benefits" style={{ textDecoration: 'none', color: 'inherit' }}>Try pricing calculator</a>
                                </button>
                            </Box>
                        </Stack>
                    </Stack>
                </Card>
            </Box>
            <Box style={{ backgroundColor: '#6f58ed', margin: '64px 0', minHeight: '150px', display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        maxWidth: '1320px',
                        width: '90%',
                        padding: { xs: '32px 2%', sm: '32px 10%' },
                        margin: '0 auto',
                        minHeight: '100%',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column'
                    }}
                >
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        sx={{ width: '100%', display: 'flex', flexGrow: 1, alignItems: 'center' }}>
                        <Box sx={{ minWidth: '50%', color: 'white' }}>
                            <Typography variant='h6'>
                                Quiz us about AI-led information management, help us understand the dynamics of your project, or get a demo of one of our products 👍

                            </Typography>
                        </Box>
                        <Box sx={{ minWidth: '50%', color: 'white', display: 'flex', justifyContent: 'center' }}>
                            <button className='btn btn-secondary mt-3' onClick={() => setShowModal(true)}>
                                Speak to the team
                            </button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box>
                <Container className='mt-5 mb-5 pb-5'>
                    <MainHeader
                        title='Product Resources'
                    />
                    <Grid
                        container
                        className='mt-1'
                        justifyContent="center" // Align the grid items to the left
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 12, md: 12 }}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                            <ArticleCard
                                header="Product in action"
                                subHeader="Visit our YouTube channel to watch our demo & tutorial videos and discover how easy it is to build the single-source-of-truth for your assets."
                                imageURL={`${process.env.PUBLIC_URL}/youtube-link-integrations.png`}
                                tag='Tutorial'
                                duration='4-5 mins watch'
                                authorName='Hoppa'
                                authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                                publishedDate="Dec 15, '25"
                                linkURL="https://www.youtube.com/@HoppaTechnologies"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <ArticleCard
                                header="Meta-Morphosis: How Metadata Transforms our AI Outlook"
                                subHeader="Why 'data about data' holds the key to successfully harnessing multi-modal artificial intelligence, and a peek at how construction and asset management specialists could be working with AI tomorrow."
                                imageURL="https://media.licdn.com/dms/image/v2/D4E12AQFjqWkuzMAspQ/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1736853420154?e=1744243200&v=beta&t=X_O6sx4gpWENYhBc4Af-mG52xR2ocuYv2RyQs_1wG0g"
                                tag='Article'
                                duration='8-10 mins read'
                                authorName='Tom Goldsmith'
                                authorImageURL={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                                publishedDate="Jan 14, '25"
                                linkURL="https://www.linkedin.com/pulse/meta-morphosis-how-metadata-transforms-our-ai-outlook-tom-goldsmith-b4cee/"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box>
                <Container className='mt-5 mb-5'>
                    <MainHeader
                        title='Frequently Asked Questions'
                    />
                    <Box className='mt-3'>
                        {faqs.map((faq, index) => (
                            <>
                                <Accordion
                                    onChange={handleChange(index)}
                                    expanded={expanded === index}
                                    sx={{
                                        color: '#0051b6',
                                        backgroundColor: 'transparent',
                                        boxShadow: 'none',
                                        fontSize: '16pt',
                                        minHeight: '50px',
                                        margin: '8px 0',
                                        '&::before': {
                                            backgroundColor: '#0051b6',
                                        },
                                        '&.Mui-expanded::before': {
                                            opacity: 1
                                        },
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardOutlined sx={{ color: '#0051b6' }} />}
                                        id={index}
                                    >
                                        {faq.question}
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{
                                            backgroundColor: 'transparent',
                                            color: 'black',
                                            fontSize: '12pt',
                                            // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '16px',
                                            // padding: '32px 24px',
                                        }}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: marked(faq.answer),
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )
                        )}
                    </Box>
                </Container>
            </Box>
            <Popup
                show={showModal}
                onHide={() => setShowModal(false)}
                title="Chat to the team"
                image='labor-7576514_640.png'
                imageAlt='Get started'
                content={
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        <RequestADemo sx={{ display: 'flex', flexGrow: 1 }} />
                    </Box>

                }
            />
            <footer style={{ marginTop: 0 }}>
                <FooterBar />
            </footer>
        </Box>
    )
}

export default Product