// InitiateContexts.jsx
import React, { createContext, useContext, useReducer, useState } from 'react';

const UserStandardContext = createContext();
const WorkspaceStandardContext = createContext();

export const UserStandardProvider = ({ children }) => {
  const initialState = {
    classifiers: [],
    //tableData: {},
    enrichers: [],
    customStandardFlag: Boolean,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_CODE_PARTS':
        //console.log('Reducer: Setting CODE PARTS List with Payload:', action.payload);
        return { ...state, classifiers: action.payload };
      //case 'SET_TABLE_DATA':
        //return { ...state, tableData: action.payload };
      case 'SET_ENRICHERS':
        //console.log('Reducer: Setting Enrichers List with Payload:', action.payload);
        return { ...state, enrichers: action.payload };   
      default:
        return state;
    }
  };

  const [userStandard, dispatch] = useReducer(reducer, initialState);

  return (
    <UserStandardContext.Provider value={{ userStandard, dispatch }}>
      {children}
    </UserStandardContext.Provider>
  );
};

export const useUserStandard = () => {
  const context = useContext(UserStandardContext);
  if (!context) {
    throw new Error('useUserStandard must be used within a UserStandardProvider');
  }
  return context;
};

export const WorkspaceStandardProvider = ({ children }) => {
  const initialState = {
    classifiers: [],
    //tableData: {},
    enrichers: [],
    customStandardFlag: Boolean,
  };

  const [workspaceStandard, setWorkspaceStandard] = useState(
    {
      classifiers: [],
      enrichers: {
        "tags": [], 
        "searchTerms": []
      }
    }
  )

  // const reducer = (state, action) => {
  //   switch (action.type) {
  //     case 'SET_CODE_PARTS':
  //       //console.log('Reducer: Setting CODE PARTS List with Payload:', action.payload);
  //       return { ...state, classifiers: action.payload };
  //     //case 'SET_TABLE_DATA':
  //       //return { ...state, tableData: action.payload };
  //     case 'SET_ENRICHERS':
  //       //console.log('Reducer: Setting Enrichers List with Payload:', action.payload);
  //       return { ...state, enrichers: action.payload };   
  //     default:
  //       return state;
  //   }
  // };

  // const [workspaceStandard, dispatch] = useReducer(reducer, initialState);

  return (
    <WorkspaceStandardContext.Provider value={{ workspaceStandard, setWorkspaceStandard }}>
      {children}
    </WorkspaceStandardContext.Provider>
  );
};

export const useWorkspaceStandard = () => {
  const context = useContext(WorkspaceStandardContext);
  if (!context) {
    throw new Error('useWorkspaceStandard must be used within a WorkspaceStandardProvider');
  }
  return context;
};
