import { Box, Card, CardActionArea, Chip, Fade, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ListGroup, Badge, Collapse } from 'react-bootstrap';

const BlueprintPartPicker = ({ blueprints = [], onBlueprintSelect, selectedBlueprint, setSelectedBlueprint }) => {
  const [selectedClassifier, setSelectedClassifier] = useState(null);
  const [showClassifiers, setShowClassifiers] = useState(false);
  const [showClassifierDescription, setShowClassifierDescription] = useState(false);

  const handleBlueprintClick = (blueprint) => {

    onBlueprintSelect(blueprint)

    // Toggle the panel width based on repeated clicks
    if (selectedBlueprint === blueprint && showClassifiers) {
      // Set first panel to full width, hide second panel
      setShowClassifiers(false);
    } else {
      // Set both panels to half-width, show classifiers
      setSelectedBlueprint(blueprint);
      setSelectedClassifier(null);
      setShowClassifiers(true);
      //onBlueprintSelect(blueprint); // Pass the selected blueprint back to the parent
      // Log the selected blueprint for debugging
    }
  };

  const handleClassifierClick = (e, classifier) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedClassifier(classifier);
    setShowClassifierDescription((prev) => (selectedClassifier === classifier ? !prev : true));
  };

  return (
    <div style={{ display: 'flex', height: '100vh', justifyContent: 'left', flexDirection: 'row' }}>
      {/* First Panel: Blueprint List with Accordion */}
      <div
        style={{
          width: '50%',
          paddingRight: '10px',
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <List disablePadding>
          {blueprints.sort((a, b) => a.client === 'General' ? -1 : b.client === 'General' ? 1 : 0).map((blueprint) => (
            <ListItem
              disablePadding
            >
              <Card key={blueprint.name}
                sx={{
                  backgroundColor: 'white',
                  mb: '8px',
                  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                  width: '100%',
                }}
              >
                <CardActionArea onClick={(e) => handleBlueprintClick(blueprint)}
                  sx={{
                    p: '12px',
                    '&[data-active]': {
                      backgroundColor: '#4a4bd6',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'action.selectedHover',
                      },

                    }
                  }}
                  data-active={selectedBlueprint === blueprint && showClassifiers ? '' : undefined}
                >
                  <Typography>
                    {blueprint.name}
                  </Typography>
                </CardActionArea>
              </Card>

            </ListItem>
          ))}
        </List>
      </div>

      {/* Second Panel: Classifiers List */}
      <Box sx={{ flex: 1 }}>
        <Fade in={showClassifiers && selectedBlueprint.classifiers} orientation="horizontal">
          <Box>
            <div style={{ marginBottom: '15px', color: '#555', backgroundColor: '#fff', borderRadius: '8px', padding: '10px', boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', }}>
              <div>{selectedBlueprint?.description}</div>
              <div style={{ marginTop: '5px' }}>
                <Chip label={selectedBlueprint?.client} style={{ marginRight: '5px', backgroundColor: '#4a4bd6', color: 'white' }} />
                <Chip label={selectedBlueprint?.region} style={{ marginRight: '5px', backgroundColor: '#4a4bd6', color: 'white' }} />
              </div>
            </div>

            <List>
              {Object.keys(selectedBlueprint).length > 0 &&
                Object.values(selectedBlueprint?.classifiers).map((classifier) => (
                  <ListItem disablePadding>
                    <Stack direction='column' sx={{ width: '100%', mb: '4px' }}>
                      <Card
                        sx={{
                          backgroundColor: 'white',
                          mb: '2px',
                          boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                          borderRadius: '8px',
                          width: '100%',
                        }}>
                        <CardActionArea
                          onClick={(e) => handleClassifierClick(e, classifier)}
                          sx={{
                            p: '12px',
                            '&[data-active]': {
                              backgroundColor: '#4a4bd6',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'action.selectedHover',
                              },

                            }
                          }}
                          data-active={selectedClassifier === classifier && showClassifierDescription ? '' : undefined}
                        >
                          <Typography>
                            {classifier.name}
                          </Typography>
                        </CardActionArea>
                      </Card>
                      <Collapse in={selectedClassifier === classifier && showClassifierDescription}>
                        <div style={{ padding: '10px', color: '#555', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '8px' }}>
                          {classifier.prompt}
                        </div>
                      </Collapse>
                    </Stack>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Fade>
      </Box>
    </div>
  );
};

export default BlueprintPartPicker;
