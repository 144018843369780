import { Outlet, useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { SessionProvider } from "../../contexts/SessionContexts";
import { UserManagementProvider } from "../../contexts/UserManagementContexts";
import { UserStandardProvider } from "../../contexts/InitiateContexts";
import { WorkspaceStandardProvider } from "../../contexts/InitiateContexts";
import { ResultsProvider } from "../../contexts/ResultsContexts";

const Auth0ProviderWithHistory = ({ children }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || "/dashboard");
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{ redirect_uri: window.location.origin + '/callback' }}
            onRedirectCallback={onRedirectCallback}
        ><SessionProvider>
                <UserManagementProvider>
                    <UserStandardProvider>
                        <WorkspaceStandardProvider>
                            <ResultsProvider>
                                <Outlet />
                            </ResultsProvider>
                        </WorkspaceStandardProvider>
                    </UserStandardProvider>
                </UserManagementProvider>
            </SessionProvider>

        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
