import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  const location = useLocation();

  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({
      appState: { returnTo: location.pathname },
    });
    return null; // Prevent rendering anything before redirecting
  }

  return element;
};

export default ProtectedRoute;
