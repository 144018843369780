import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Col, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faTrashAlt, faTimesCircle, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Box, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

const CodePart = ({ codePart, moveCodePart, updateCodePart, deleteCodePart, showTableModal, baseStandard }) => {
  const { id, name } = codePart;

  const [{ isDragging }, drag] = useDrag({
    type: 'CODE_PART',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'CODE_PART',
    hover: (draggedItem) => {
      if (draggedItem.id !== id) {
        moveCodePart(draggedItem.id, id);
      }
    },
  });

  const currentBaseStandard = baseStandard?.classifiers.find((classifier) => classifier.id === id);

  return (
    <Box style={{ maxWidth: '250px', flex: 1 }}>
      <div ref={(node) => drag(drop(node))}>
        <Card
          className="pb-5"
          style={{
            cursor: 'grab',
            opacity: isDragging ? 0.5 : 1,
            boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)'
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.cursor = 'grab';
          }}
        >
          <Card.Body>
            <Form.Control
              required
              type="text"
              value={name}
              onChange={(event) => updateCodePart(id, event.target.value, event)}
              placeholder={`Part ${id}`}
            />
            <Form.Control.Feedback type="invalid">
              Cannot be blank.
            </Form.Control.Feedback>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div style={{ cursor: 'grab', fontSize: '1.5rem' }} className="position-absolute start-50 mt-5">
                <FontAwesomeIcon title="Reorder code part" icon={faGripVertical} style={{ opacity: isDragging ? 0 : 1 }} />
              </div>
              <div className="position-absolute top-0 end-0" style={{ transform: 'translate(50%, -50%)', zIndex: 1 }}>
                <IconButton sx={{
                  width: '30px', 
                  height: '30px', 
                  background: 'FireBrick', 
                  '&:hover': {
                    background: 'darkred',  // Change background color on hover for interaction feedback
                  },
                }} onClick={() => deleteCodePart(id)}>
                  <Delete sx={{ color: 'white', fontSize: '1rem' }} />
                </IconButton >
                {/* <div style={{ width: '30px', height: '30px', borderRadius: '50%', background: 'FireBrick', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FontAwesomeIcon title="Remove code part" icon={faTrashAlt} style={{ color: 'white',cursor: 'pointer' }}  />
                </div> */}
              </div>
            </div>
            {currentBaseStandard && (
              <div>
                <strong>{currentBaseStandard.name}</strong>
                <ul>
                  {currentBaseStandard.data.map((item) => (
                    <li key={item.code}>{`${item.code}: ${item.description}`}</li>
                  ))}
                </ul>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </Box>
  );
};

export default CodePart;
