import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DynamicTable from '../shared/DynamicTable';
import Refinery from '../shared/Refinery';
import { Container, Spinner, Alert, Nav, Badge, Row, Col, Modal, Form, Button, Tooltip, OverlayTrigger, TabContainer } from 'react-bootstrap';
import GrainIcon from '@mui/icons-material/Grain';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReportsIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useUserManagement, useCheckPermissions } from '../../../contexts/UserManagementContexts';
import { generateSessionId } from '../../../scripts/generateSessionId';
import { Chip } from '@mui/material';
import ConfirmDeleteModal from '../shared/ConfirmDeleteModal';
import CustomAlert from '../shared/CustomAlert';
import { Backdrop, Box, Tabs, Tab, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Workspace = () => {
    const { user } = useAuth0();
    const { isLoading, isLoadingOrgs } = useUserManagement();
    const { currentOrg, workspace } = useParams();
    const checkPermissions = useCheckPermissions()
    const navigate = useNavigate();
    const [isLoadingSessions, setIsLoadingSessions] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [workspaceSessions, setWorkspaceSessions] = useState([]);
    const [workspaceStandard, setWorkspaceStandard] = useState({});
    const [initialWorkspaceStandard, setInitialWorkspaceStandard] = useState(null);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [sessionName, setSessionName] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [error, setError] = useState(null);

    // Alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed
    const [showSpinner, setShowSpinner] = useState(false);
    const [sessionId, setSessionId] = useState('')

    // Permissions
    const [hasCreatePermission, setHasCreatePermission] = useState(false);
    const [hasUpdatePermission, setHasUpdatePermission] = useState(false);
    const [hasDeletePermission, setHasDeletePermission] = useState(false);
    const [hasRequeuePermission, setHasRequeuePermission] = useState(false);

    let config = {
        headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY
        }
    };

    useEffect(() => {
        if (!isLoading && !isLoadingOrgs && currentOrg) {
            const isAuthorised = checkPermissions(['read:sessions'], currentOrg)
            if (isAuthorised) {
                const createPermission = checkPermissions(['create:sessions'], currentOrg)
                const deletePermission = checkPermissions(['delete:sessions'], currentOrg)
                const requeuePermission = checkPermissions(['requeue:files'], currentOrg)
                const updatePermission = checkPermissions(['update:workspaces'], currentOrg)
                setHasCreatePermission(createPermission)
                setHasDeletePermission(deletePermission)
                setHasRequeuePermission(requeuePermission)
                setHasUpdatePermission(updatePermission)
            } else {
                navigate('/access-denied')
            }
        }
    }, [user, isLoading, isLoadingOrgs, currentOrg])

    // Fetch sessions when the component mounts
    useEffect(() => {
            fetchSessions();
    }, [currentOrg, workspace]);

    const fetchSessions = async () => {
        setIsLoadingSessions(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/api/GetWorkspaceSessions`, {
                params: {
                    orgName: currentOrg,
                    workspaceName: workspace
                },
                ...config
            });
            setWorkspaceSessions(response.data.sessions);
            setWorkspaceStandard(response.data.workspace_standard);
            setInitialWorkspaceStandard(response.data.workspace_standard);
        } catch (error) {
            console.error('Error fetching sessions:', error);
            setError('Failed to fetch sessions.');
        } finally {
            setIsLoadingSessions(false);
        }
    };

    useEffect(() => {
        if (JSON.stringify(workspaceStandard) !== JSON.stringify(initialWorkspaceStandard)) {
            setIsSaveButtonEnabled(true);
        } else {
            setIsSaveButtonEnabled(false);
        }
    }, [workspaceStandard, initialWorkspaceStandard]);

    const handleSaveWorkspaceStandard = async () => {
        console.log('Saving')

        try {
            const response = await axios.post(`${API_BASE_URL}/api/UpdateWorkspaceStandard`,
                {
                    orgName: currentOrg,
                    workspaceName: workspace,
                    workspaceStandard: JSON.stringify(workspaceStandard, null, 4),
                },
                {
                    headers: {
                        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY,
                        'Content-Type': 'application/json' // Ensure the content type is set correctly
                    }
                }
            );

            if (response.status === 200) {
                setInitialWorkspaceStandard(workspaceStandard);
                setIsSaveButtonEnabled(false);
                triggerAlert('Workspace standard saved successfully.', 'success');
            }
        } catch (error) {
            console.error("Error during saving workspace standard:", error); // Log error details
            triggerAlert('Failed to save workspace standard.', 'error');
        }
    };



    const tableHeaders = [
        { label: 'Session Name', key: 'session_name' },
        { label: 'Session Key', key: 'session_id' },
        { label: 'Created By', key: 'created_by' },
        { label: 'Created At', key: 'created_at' },
        { label: 'File Count', key: 'file_count' },
        { label: 'Row Count', key: 'row_count' },
        { label: 'Status', key: 'status' },
    ];

    // Conditionally populate table based on user permissions
    const tableData = (hasCreatePermission ? workspaceSessions : workspaceSessions.filter(session => session.status?.toLowerCase() === 'completed'))
    .map(session => ({
        session_name: session.session_name,
        session_id: session.session_id,
        created_at: new Date(session.created_at).toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }),
        created_by: session.created_by,
        file_count: session.file_count,
        row_count: session.row_count ?? 0,
        status: session.status?.charAt(0).toUpperCase() + session.status?.slice(1).toLowerCase(), // Capitalize first letter
    }));

    const rowActions = (rowData) => {
        const { session_id } = rowData;

        return [
            {
                label: 'Delete session',
                onClick: () => handleDeleteButtonPress(session_id),
                disabled: !hasDeletePermission,
            },
            {
                label: 'Reprocess all docs',
                onClick: () => handleRequeueSession(session_id, true),
                disabled: !hasRequeuePermission,
            },
            {
                label: 'Reprocess uncompleted',
                onClick: () => handleRequeueSession(session_id, false),
                disabled: !hasRequeuePermission,
            },
        ];
    };

    // Navigate to results page on row click
    const handleRowClick = (rowData) => {

        const { session_id } = rowData;

        if (rowData.status === 'Draft') {

            navigate(`/${currentOrg}/${workspace}/createsession?session=${session_id}`)

        }
        else
            navigate(`/${currentOrg}/${workspace}/${session_id}/results`)

    };

    // Handle session creation
    const handleCreateSession = async () => {
        setIsCreating(true);

        const sessionId = generateSessionId()

        try {
            const response = await axios.get(`${API_BASE_URL}/api/NewWorkspaceSession`, {
                params: {
                    orgName: currentOrg,
                    workspaceName: workspace,
                    sessionGuid: sessionId,
                    sessionName: sessionName,
                    createdBy: user.email,
                },
                ...config
            });

            navigate(`/${currentOrg}/${workspace}/createsession?session=${sessionId}`);
            setShowModal(false);
        } catch (error) {
            console.error('Error creating session:', error);
            setError('Failed to create session.');
        } finally {
            setIsCreating(false);
        }
    };

    const handleDeleteButtonPress = (session) => {
        setSessionId(session)
        setShowDeleteModal(true)
    }

    const handleDeleteSession = async () => {
        setShowSpinner(true);

        try {
            const response = await axios.get(`${API_BASE_URL}/api/DeleteSession?containerName=${currentOrg}&virtualFolder=${workspace}/${sessionId}`, config);
            triggerAlert("Delete successful.")
        }
        catch {
            triggerAlert('Delete failed.', 'error')
        }
        finally {
            fetchSessions()
            setShowSpinner(false);
        }

    }

    const handleRequeueSession = async (session_id, queueCompleted) => {

        setShowSpinner(true);

        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/QueueFiles`,
                {
                    organisation: currentOrg,
                    workspace: workspace,
                    sessionId: session_id,
                    userId: user.sub,
                    queueCompleted: queueCompleted
                },
                config
            )

            const fileCount = response.data?.file_count

            if (fileCount > 0) (
                triggerAlert(`${fileCount} items(s) queued.`)
            )
            else {
                triggerAlert(`0 items(s) queued.`, 'warning')
            }
        }
        catch {
            triggerAlert('Submit failed, Please try again.', 'error')
        }
        finally {
            fetchSessions()
            setShowSpinner(false);
        }
    }

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true);
    };

    const [activeTab, setActiveTab] = useState('sessions');

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };


    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto'}}>
                {/* Tab Navigation */}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white' }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="Tab navigation"
                        variant="fullWidth"                       
                    >
                        <Tab
                            icon={<GrainIcon style={{ marginRight: '8px' }} />}
                            iconPosition="start"
                            label="Sessions"
                            value="sessions"
                            sx={{ textTransform: 'none', minHeight: 0, height: '50px'}} // This keeps the label as-is (lowercase)
                        />
                        <Tab
                            icon={<FilterAltIcon style={{ marginRight: '8px' }} />}
                            iconPosition="start"
                            label="Data Refinery"
                            value="refinery"
                            sx={{ textTransform: 'none', minHeight: 0, height: '50px'}} // This keeps the label as-is (lowercase)
                        />
                        <Tab
                            icon={<ReportsIcon style={{ marginRight: '8px' }} />}
                            iconPosition="start"
                            label={
                                <>
                                    Reports
                                    <Chip
                                        label="Coming soon"
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                            color: '#544cfd',
                                            borderColor: '#544cfd',
                                            marginLeft: '5px',
                                        }}
                                    />
                                </>
                            }
                            value="reports"
                            disabled
                            sx={{ textTransform: 'none', minHeight: 0, height: '50px'}} // This keeps the label as-is (lowercase)
                        />
                    </Tabs>
                </Box>
                {/* Tab Content */}
                <Box sx={{ backgroundColor: 'transparent', overflowY: 'auto'}}>
                    {activeTab === 'sessions' && (
                        <>
                            {isLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            ) : error ? (
                                <Alert severity="error">{error}</Alert>
                            ) : (
                                <Box sx={{p: 2, minHeight: '70vh'}}>
                                <DynamicTable
                                    tableData={tableData}
                                    tableHeaders={tableHeaders}
                                    searchPlaceholder="Search sessions..."
                                    onRowClick={handleRowClick}
                                    isLoading={isLoadingSessions}
                                    actionButton={{
                                        label: 'Create New Session',
                                        disabled: !hasCreatePermission,
                                        onClick: () => setShowModal(true),
                                    }}
                                    rowActions={(rowData) => rowActions(rowData)}
                                    isEmptyPrompt='This workspace is empty. Get started by creating a session.'
                                    defaultSortKey='session_name'
                                />
                                </Box>
                            )}
                        </>
                    )}

                    {activeTab === 'refinery' && workspaceStandard && (
                        <Box sx={{margin: '1.5rem'}}>
                            <Refinery
                                standard={workspaceStandard}
                                setStandard={setWorkspaceStandard}
                                handleSaveStandard={handleSaveWorkspaceStandard}
                                isSaveButtonEnabled={isSaveButtonEnabled}
                                hasUpdatePermission={hasUpdatePermission}
                            />
                        </Box>
                    )}

                    {activeTab === 'settings' && (
                        <Typography variant="h6">Settings</Typography>
                    )}
                </Box>
            </Box>
            <div>
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Session</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="workspaceName">
                                <Form.Label>Session Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoFocus
                                    placeholder="Enter session name"
                                    value={sessionName}
                                    onChange={(e) => setSessionName(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleCreateSession();
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleCreateSession}
                            disabled={isCreating || !sessionName}
                        >
                            {isCreating ? 'Creating...' : 'Create session'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <ConfirmDeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    fieldName={sessionId}
                    onDelete={handleDeleteSession}
                />

                <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showSpinner}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
};

export default Workspace;
