import React, { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import * as XLSX from 'xlsx';
import { useWorkspaceStandard } from '../../../../contexts/InitiateContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Col, Container, Row, Tab, Nav, Table, Form, OverlayTrigger, Modal, ToggleSwitch } from 'react-bootstrap';
import CodePart from './CodePart';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, ButtonGroup, Checkbox, Collapse, Divider, FormControlLabel, IconButton, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { Delete, DeleteForever, Download, Save, Upload } from '@mui/icons-material';

const CustomiseStandard = ({ triggerAlert }) => {
    const { workspaceStandard, setWorkspaceStandard } = useWorkspaceStandard();
    const [openEditor, setOpenEditor] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [categoryName, setCategoryName] = useState('');
    const [showPrompt, setShowPrompt] = useState(false);

    useEffect(() => {
        if (workspaceStandard.classifiers.length > 0) {
            setOpenEditor(true)
        } else {
            setOpenEditor(false)
        }
    }, [])

    const addCodePart = (name) => {
        const customPartIndex = workspaceStandard.classifiers.filter((part) => part.id.startsWith('Part-')).length + 1;
        const newCodePart = {
            id: `Part-${customPartIndex}`,
            name: name || '',
            isHierarchy: false,
            data: [{ code: '', description: '' }]
        };

        setWorkspaceStandard((prevState) => ({
            ...prevState,
            classifiers: [...prevState.classifiers, newCodePart],
        }))

        if (customPartIndex === 1) {
            setOpenEditor(true)
        }
    };

    const deleteCodePart = (id) => {
        setWorkspaceStandard((prevState) => {
            // Filter out the classifier with the matching id
            const updatedClassifiers = prevState.classifiers.filter((item) => item.id !== id);

            // Return the updated state with the filtered classifiers list
            return {
                ...prevState,
                classifiers: updatedClassifiers,
            };
        });
    };

    const updateCodePart = (id, newName) => {

        setWorkspaceStandard((prevState) => {
            // Copy the classifiers list to avoid mutation
            const classifiers = [...prevState.classifiers];

            // Find the index of the item with the specified id
            const itemIndex = classifiers.findIndex((item) => item.id === id);

            // If the item is found, update its name
            if (itemIndex !== -1) {
                classifiers[itemIndex].name = newName;
            } else {
                console.warn('Item with the given id not found');
            }

            // Return the updated state
            return {
                ...prevState,
                classifiers,
            };
        });
    };

    const moveCodePart = (sourceId, targetId) => {
        setWorkspaceStandard((prevState) => {
            // Copy the classifiers list to avoid mutation
            const classifiers = [...prevState.classifiers];

            // Find the indices of the source and target items
            const sourceIndex = classifiers.findIndex((item) => item.id === sourceId);
            const targetIndex = classifiers.findIndex((item) => item.id === targetId);

            // If either source or target not found, return the current state
            if (sourceIndex === -1 || targetIndex === -1) {
                console.warn('Invalid IDs provided');
                return prevState;
            }

            // Remove the source item from its current position
            const [itemToMove] = classifiers.splice(sourceIndex, 1);

            // Insert the item at the target position
            classifiers.splice(targetIndex, 0, itemToMove);

            // Return the updated state
            return {
                ...prevState,
                classifiers,
            };
        });
    };

    const addRow = (partId) => {

        setWorkspaceStandard((prevState) => {
            // Find the classifier (codePart) with the matching id
            const updatedClassifiers = prevState.classifiers.map((classifier) => {
                // Find the codePart that matches the partId
                if (classifier.id === partId) {
                    return {
                        ...classifier,
                        // Add an empty item to the data array
                        data: [...classifier.data, { code: '', description: '' }],
                    };
                }
                return classifier;
            });

            // Return the updated state with the modified classifiers
            return {
                ...prevState,
                classifiers: updatedClassifiers,
            };
        });
    };

    const deleteRows = (partId) => {
        setWorkspaceStandard((prevState) => {
            const updatedClassifiers = prevState.classifiers.map((classifier) => {
                // Check if the classifier matches the partId
                if (classifier.id === partId) {
                    // Filter out rows that are selected (based on selectedRows)
                    const updatedData = classifier.data.filter((row, index) => !selectedRows.has(index));

                    // Return the updated classifier with the filtered data
                    return {
                        ...classifier,
                        data: updatedData,
                    };
                }
                return classifier; // Return the classifier as is if not matching partId
            });

            // Return the updated state with modified classifiers
            return {
                ...prevState,
                classifiers: updatedClassifiers,
            };
        });

        setSelectedRows(new Set())
    };

    const updateRow = (codePartIndex, rowIndex, field, value) => {
        setWorkspaceStandard((prevState) => {
            const updatedClassifiers = prevState.classifiers.map((classifier, index) => {
                // Find the codePart based on codePartIndex
                if (index === codePartIndex) {
                    const updatedData = classifier.data.map((row, rowIdx) => {
                        // If the rowIndex matches, update the specific field
                        if (rowIdx === rowIndex) {
                            return {
                                ...row,
                                [field]: value, // Update the specific field with the new value
                            };
                        }
                        return row; // If not the targeted row, return it as is
                    });

                    // Return the updated classifier with the modified data array
                    return {
                        ...classifier,
                        data: updatedData,
                    };
                }
                return classifier; // If not the targeted codePart, return it as is
            });

            // Return the updated state
            return {
                ...prevState,
                classifiers: updatedClassifiers,
            };
        });
    };


    // Export standard to Excel for future use by user
    const handleExportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const fileName = 'My_Standard.xlsx';

        // Iterate over codeParts and add a sheet for each code part
        workspaceStandard.classifiers.forEach((codePart) => {
            const codePartData = codePart.data;

            console.log(codePartData);

            // Ensure codePartData is an array
            const data = Array.isArray(codePartData) ? codePartData : [];

            // console.log(`CodePart: ${codePart.name}, Data: `, data); // Log the data

            const ws = XLSX.utils.json_to_sheet(data);
            const sheetName = codePart.name;
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

        XLSX.writeFile(wb, fileName);
    };

    const handleFileUpload = () => {
            document.getElementById('file-upload').click()
    }

    const FileUpload = (event) => {

        console.log("FileUpload function called");

        console.log("Event object:", event);

        if (!event.target.files || event.target.files.length === 0) {
            // No file uploaded, so return early
            console.log("No file uploaded");
            return;
        }

        const file = event.target.files[0];
        console.log("Selected file:", file);

        if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
            triggerAlert('Invalid file type. Please upload an Excel file.');
            return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
            try {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Assuming each sheet corresponds to a code part
                const codeParts = workbook.SheetNames.map((sheetName, index) => {
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    // Get the headers from the first row of the sheet
                    const headers = sheetData[0];

                    // Check if there's a 'parent' column and determine if hierarchy exists
                    const parentColumnIndex = headers.indexOf('parent');
                    let isHierarchy = false
                    // Check if the 'parent' column exists
                    if (parentColumnIndex !== -1) {
                        // Extract all values from the 'parent' column starting from the second row
                        const parentValues = sheetData.slice(1).map(row => row[parentColumnIndex]);

                        // Check if any value in the 'parent' column is non-empty
                        isHierarchy = parentValues.some(value => value?.length > 0);
                    }

                    // Get other column indexes
                    const codeColumnIndex = headers.indexOf('code');
                    const descriptionColumnIndex = headers.indexOf('description')
                    const promptColumnIndex = headers.indexOf('prompt')

                    // Skip the first row (headers) and process the data
                    const codePartData = sheetData.slice(1).map((row) => {
                        const codePart = {
                            code: row[codeColumnIndex] || '',
                            description: row[descriptionColumnIndex] || '',
                            prompt: row[promptColumnIndex] || '',
                        };

                        // If the 'parent' column exists, add the parent field to the codePart
                        if (isHierarchy) {
                            codePart.parent = row[parentColumnIndex] || '';
                        }

                        return codePart;
                    });

                    // Generate the unique ID
                    const sheetId = `ID${index + 1}-${sheetName}`;

                    console.log("Hierarchy flag of ", sheetName, " is ", isHierarchy)

                    return {
                        id: sheetId,
                        name: sheetName,
                        isHierarchy: isHierarchy, // Add the isHierarchy flag
                        data: codePartData,
                    };
                });

                // Update base standard with the uploaded code parts
                setWorkspaceStandard({ classifiers: codeParts, enrichers: { "tags": [], "searchTerms": [] } });

            } catch (error) {
                triggerAlert('An error occurred. Check contents of file and try again.');
                console.error('Error parsing Excel file:', error);
            } finally {
                reader.onload = null; // Reset the onload event handler
                setOpenEditor(true)
            }
        };


        reader.readAsArrayBuffer(file);
        event.target.value = '' // Reset the value of the file input

    };

    const handleRowSelect = (rowIndex) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);

            if (newSelectedRows.has(rowIndex)) {
                newSelectedRows.delete(rowIndex); // Remove if already selected
            } else {
                newSelectedRows.add(rowIndex); // Add if not selected
            }

            return newSelectedRows; // Return new set to update state
        });
    };

    const handleSelectAll = (event, partIndex) => {

        if (event.target.checked) {
            // Select all
            const allSelected = new Set(workspaceStandard.classifiers[partIndex].data.map((_, index) => index)); // Create a set of all row indexes
            setSelectedRows(allSelected); // Set selected rows state to all selected
        } else {
            // Deselect all
            setSelectedRows(new Set()); // Clear selected rows
        }
    };

    const handleBulkDelete = () => {
        setWorkspaceStandard((prevState) => ({
            ...prevState,
            classifiers: [],
        }))
        setOpenEditor(false)
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addRow(selectedTab); // Trigger adding a new row
        }
    };

    const handlePasteFromExcel = (e, partId) => {
        e.preventDefault()

        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');

        // Parse the pasted data and update the tableData state
        const parsedRows = pastedData.split('\n').map((row) => {
            const [code, description, prompt] = row.split('\t');

            // Clean the description (remove unwanted characters)
            const cleanedDescription = description?.replace(/[\r\n]/g, '');

            const cleanedPrompt = prompt?.replace(/[\r\n]/g, '');


            if (code) {
                return { code: code, description: cleanedDescription || '', prompt: cleanedPrompt || '' }
            } else {
                return
            }

        }).filter(Boolean)

        setWorkspaceStandard((prevState) => {
            // Find the classifier (codePart) with the matching id
            const updatedClassifiers = prevState.classifiers.map((classifier) => {
                // Find the codePart that matches the partId
                if (classifier.id === partId) {
                    return {
                        ...classifier,
                        // Add an empty item to the data array
                        data: parsedRows,
                    };
                }
                return classifier;
            });

            // Return the updated state with the modified classifiers
            return {
                ...prevState,
                classifiers: updatedClassifiers,
            };
        });



    };

    return (
        <div >
            <Box
                sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                {/* Title */}
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4a4bd6', flexGrow: 1 }}>
                    Customise standard
                </Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '82vh', overFlow: 'visible' }}>

                <Stack direction='column' sx={{ width: '100%' }}>

                    <Stack direction='row' sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }} spacing={2}>
                        <Box sx={{ position: 'relative' }}>
                            <div className="excel-icon-overlay">
                                <FontAwesomeIcon icon={faFileExcel} style={{ fontSize: '1em' }} />
                            </div>
                            <ButtonGroup variant='contained'>
                                <Tooltip title="Export template" arrow>
                                    <span>
                                        <IconButton
                                            onClick={handleExportToExcel}
                                            sx={{ color: 'green' }}
                                            disabled={workspaceStandard.classifiers.length === 0}
                                        >
                                            <Save />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title='Upload template' arrow>
                                    <span>
                                        <IconButton
                                            onClick={handleFileUpload}
                                            sx={{ color: 'green' }}
                                        >
                                            <Upload />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </ButtonGroup>

                            <input id="file-upload" type="file" accept=".xls,.xlsx" onChange={FileUpload} style={{ display: 'none' }} />
                        </Box>
                        <ButtonGroup variant='contained'>
                            <Tooltip title="Delete all categories" arrow>
                                <span>
                                    <IconButton
                                        onClick={handleBulkDelete}
                                        sx={{ color: 'FireBrick' }}
                                        disabled={workspaceStandard.classifiers.length === 0}
                                    >
                                        <DeleteForever />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </ButtonGroup>
                        <Stack direction='row' spacing={1} sx={{ display: 'flex', alignItems: 'center', m: '5px' }}>
                            <TextField
                                type="text"
                                placeholder="Add Category..."
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                                style={{ flex: 1, backgroundColor: 'white' }}
                                size="small"
                                error={workspaceStandard.classifiers.some((codePart) => codePart.name === categoryName)}
                                label={
                                    workspaceStandard.classifiers.some((codePart) => codePart.name === categoryName)
                                        ? "Duplicate not permitted"
                                        : ""
                                }
                            />

                            <ButtonGroup variant='contained'>
                                <Tooltip title='Add category'>
                                    <span>
                                        <IconButton
                                            onClick={() => {
                                                if (categoryName) {
                                                    addCodePart(categoryName); // Pass the name to addCodePart
                                                    setCategoryName(''); // Clear input after adding
                                                }
                                            }}
                                            disabled={
                                                !categoryName ||
                                                workspaceStandard.classifiers.some((codePart) => codePart.name === categoryName) // Check if categoryName already exists
                                            }
                                        >

                                            <PlaylistAddIcon
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </ButtonGroup>
                        </Stack>
                        <FormControlLabel
                            control={<Switch checked={openEditor} onChange={() => setOpenEditor(!openEditor)} sx={{ color: '#4a4bd6' }} />}
                            label={openEditor ? 'Close editor' : 'Open editor'}
                            labelPlacement="end"
                            disabled={workspaceStandard.classifiers.length === 0}
                        />
                    </Stack>

                    <Collapse in={openEditor} unmountOnExit> {/* Apply smooth collapse transition */}
                        <Box sx={{ m: '16px 0', pb: '24px' }} >
                            <DndProvider backend={HTML5Backend}>
                                <Stack direction='row' spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    {workspaceStandard.classifiers.map((codePart) => (
                                        <CodePart
                                            key={codePart.id}
                                            codePart={codePart}
                                            moveCodePart={moveCodePart}
                                            updateCodePart={updateCodePart}
                                            deleteCodePart={deleteCodePart}
                                        />
                                    ))}
                                </Stack>
                            </DndProvider>
                        </Box>
                    </Collapse>


                    <Stack direction='row' spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flex: 1, height: openEditor ? `calc(100% - 315px)` : `calc(100% - 150px)`, mb: '64px' }}>
                        <Stack sx={{ width: '25%', justifyContent: 'flex-start', height: '100%', overflowY: 'auto' }} direction='column'>
                            <Nav style={{ borderRadius: '5px !important' }} variant="pills" className="flex-column">
                                {workspaceStandard.classifiers && workspaceStandard.classifiers.length === 0 ? (
                                    <Typography>Enter a category to get started</Typography>
                                ) : (
                                    <Stack direction='column' spacing={0.5}>
                                        {workspaceStandard.classifiers.map((codePart, index) => (
                                            <Nav.Item key={index}>
                                                <Nav.Link
                                                    eventKey={`codePart-${index}`}
                                                    className={selectedTab === index ? 'active custom-nav-link' : 'custom-nav-link'}
                                                    onClick={() => {
                                                        setSelectedTab(index)
                                                        setSelectedRows(new Set())
                                                        setSearchTerm('')
                                                    }}
                                                >
                                                    {codePart.name}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Stack>
                                )
                                }
                            </Nav>
                        </Stack>
                        <Divider sx={{ border: '1px solid silver', height: '100%' }} />
                        <Stack sx={{ display: 'flex', flexGrow: 1, height: '100%' }} direction='column'>


                            <Tab.Container activeKey={`codePart-${selectedTab}`} >
                                <Tab.Content style={{ height: '100%' }}>
                                    {workspaceStandard.classifiers.map((codePart, index) => (
                                        <Tab.Pane className="tab-content" key={index} eventKey={`codePart-${index}`} style={{ height: '100%' }}>
                                            <Box sx={{ height: '100%', overflowY: 'auto' }}>
                                                <Stack direction='row' spacing={1}>
                                                    <TextField
                                                        type="text"
                                                        placeholder="Search..."
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        style={{ flex: 1, maxWidth: '400px', backgroundColor: 'white' }}
                                                        size='small'

                                                    />
                                                    <Tooltip title='Delete selected'>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => deleteRows(codePart.id)}
                                                                disabled={selectedRows.size === 0}
                                                                sx={{ color: 'FireBrick' }}
                                                            >
                                                                <Delete
                                                                />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title='Add code'>
                                                        <span>
                                                            <IconButton
                                                                onClick={() => addRow(codePart.id)}
                                                                sx={{ color: 'green' }}
                                                            >
                                                                <AddCircleIcon
                                                                />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <FormControlLabel
                                                        control={<Switch checked={showPrompt} onChange={() => setShowPrompt(!showPrompt)} sx={{ color: '#4a4bd6' }} />}
                                                        label={showPrompt ? 'Hide advanced' : 'Show advanced'}
                                                        labelPlacement="end"
                                                        disabled={workspaceStandard.classifiers.length === 0}
                                                    />
                                                </Stack>
                                                <Table responsive onPaste={(e) => handlePasteFromExcel(e, codePart.id)}>
                                                    <thead>
                                                        <tr style={{ height: '60px' }}>
                                                            <th style={{ backgroundColor: "transparent" }}>
                                                                <div style={{ display: 'flex', alignItems: 'left' }}>
                                                                    <Checkbox
                                                                        checked={selectedRows.size === codePart.data.length} // Check if all are selected
                                                                        indeterminate={selectedRows.size > 0 && selectedRows.size < codePart.data.length} // Show indeterminate state if some are selected
                                                                        onChange={(e) => handleSelectAll(e, index)} // Handle the change to select or deselect all
                                                                    />

                                                                </div>
                                                            </th>
                                                            <th style={{ width: '140px', backgroundColor: "transparent", verticalAlign: 'middle' }}>
                                                                Code
                                                            </th>
                                                            <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>
                                                                <div className="d-flex">
                                                                    Description
                                                                </div>
                                                            </th>
                                                            {showPrompt && <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>Prompt</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {codePart.data.filter(row =>
                                                            row.code?.toLowerCase().includes(searchTerm.toLowerCase()) || row.description?.toLowerCase().includes(searchTerm.toLowerCase()) || row.prompt?.toLowerCase().includes(searchTerm.toLowerCase()))
                                                            .map((row, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    <td style={{ width: '50px', textAlign: 'left', verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                        <Checkbox
                                                                            onChange={() => handleRowSelect(rowIndex)}
                                                                            checked={selectedRows.has(rowIndex)}
                                                                        />
                                                                    </td>
                                                                    <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id={`${rowIndex}`}
                                                                            value={row.code}
                                                                            onChange={(e) => updateRow(index, rowIndex, 'code', e.target.value)}
                                                                            style={{ width: '130px' }} // Reduce width of the code input
                                                                            onKeyDown={handleKeyDown}
                                                                        />
                                                                    </td>
                                                                    <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                        <Form.Control
                                                                            as={showPrompt ? "textarea" : "input"} // Toggle between textarea and input
                                                                            rows={showPrompt ? 3 : 1} // Change rows based on the toggle
                                                                            value={row.description}
                                                                            onChange={(e) => updateRow(index, rowIndex, 'description', e.target.value)}
                                                                            style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                            onKeyDown={handleKeyDown}
                                                                        />
                                                                    </td>
                                                                    {showPrompt && (
                                                                        <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                            <Form.Control
                                                                                as="textarea" // Always a textarea for prompt
                                                                                rows={3} // Set number of visible rows for prompt
                                                                                value={row.prompt}
                                                                                onChange={(e) => updateRow(index, rowIndex, 'prompt', e.target.value)}
                                                                                style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                                onKeyDown={handleKeyDown}
                                                                            />
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </Table>
                                            </Box>
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Tab.Container>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>

            <Container className="mt-3 position-relative">

            </Container>

        </div>
    );
};

export default CustomiseStandard;