import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import RequestADemo from './RequestADemo';
import NavBar from './NavBar'
import FooterBar from '../subcomponents/shared/FooterBar';
import Popup from '../subcomponents/shared/Popup';
import ArticleCard from '../subcomponents/shared/ArticleCard';
import { Box, Card, Chip, Grid, Stack, Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import MainHeader from './MainHeader';
import { ArrowDownwardOutlined, ExpandMore } from '@mui/icons-material';

const Contact = () => {
    const [showModal, setShowModal] = useState(false)
    const [expanded, setExpanded] = useState('');


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box style={{
            backgroundColor: '#f0f8ff',
            minHeight: '100vh',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0
        }}
        >
            <NavBar variant='blue' fixed={true} />
            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    minHeight: '600px',
                    height: '100%',
                    maxHeight: '100vw',
                    zIndex: -1,
                    backgroundImage: `url(${process.env.PUBLIC_URL}/purple-haze.png)`,
                    backgroundSize: { xs: '0', md: '200vw' }, // Ensures the image covers the entire area
                    backgroundPosition: { xs: 'top 300% left 50%', md: 'top 90% right -125vw' },
                    backgroundRepeat: 'no-repeat', // Prevents tiling of the image
                }}
            ></Box>
            <Box sx={{
                maxWidth: '1320px',
                width: '90%',
                padding: '0 2%',
                paddingTop: { xs: '100px', sm: '120px' },
                paddingBottom: '72px',
                display: 'flex',         // Enables flexbox
                flexDirection: 'column', // Ensures the content stacks vertically
                alignItems: 'center',    // Centers horizontally
                margin: '0 auto',        // Horizontally center the box
            }}>
                <Stack
                    spacing={4}
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ flex: 1 }}>
                        <Chip
                            label='Contact'
                            sx={{
                                backgroundColor: 'transparent',
                                border: '1px solid #0051b6',
                                color: '#0051b6',
                                m: '8px 0'
                            }}
                        />
                        <p className='blue-font xlarge-font bold-statement'>
                            Speak to the team
                        </p>
                        <Typography sx={{ width: { xs: '100%', sm: '70%' } }}>
                            Send us a message using the form or email us direct at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>. 
                        </Typography>
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'right' }}>
                    <div className='request-demo-card' style={{ maxWidth: '500px', justifyItems: 'center' }}>
                  <p className='large-font black-font'>Contact Form</p>
                  <p>Quiz us about AI-led information management, help us understand the dynamics of your project, or get a demo of one of our products.</p>
                  <RequestADemo />
                </div>
                    </Box>
                </Stack>
            </Box>
            <footer style={{ marginTop: 0 }}>
                <FooterBar />
            </footer>
        </Box>
    )
}

export default Contact