import React from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Col, Row } from 'react-bootstrap'; // Bootstrap grid components
import HandshakeIcon from '@mui/icons-material/Handshake'; // Handshake icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Checkmark icon
import CloudIcon from '@mui/icons-material/Cloud'; // Cloud icon
import BuildIcon from '@mui/icons-material/Build'; // Pickaxe (Workbench) icon
import { motion } from 'framer-motion'; // For scroll-triggered animations

const deploymentOptions = [
  {
    title: 'Fully Managed',
    description: 'For teams with complex challenges who just need the answer, we work alongside you to get the job done.',
    features: [
      'Augment your team with our expertise',
      'Tailored workflows to suit your needs',
      'Outcome-oriented service model',
      'Flexible, dependable, expandable',
      'Tips, tricks, and training along the way',
    ],
    icon: <HandshakeIcon sx={{ color: '#e26ffc', fontSize: '40px' }} />,
  },
  {
    title: 'Workspace',
    description: 'Collaborate online with your team, flexibly configure to suit your needs and pay only for what you use.',
    features: [
      'Configure standards and pipelines',
      'Connect your CDEs and data repos',
      'Scale up or down on-demand',
      'Collaborate in a secure web platform',
      'Ongoing updates and support',
    ],
    icon: <CloudIcon sx={{ color: '#5953ff', fontSize: '40px' }} />,
  },
  {
    title: 'Workbench',
    description: 'Licensed deployment on your infrastructure, build our capability into your stack for unparalleled control.',
    features: [
      'Easy to use & extend Python libraries',
      'Deployment & build support available',
      'Seamless integration with your tech stack',
      'Constantly evolving with new tools',
      'Retain control over data processing',
    ],
    icon: <BuildIcon sx={{ color: '#ffaf4d', fontSize: '40px' }} />,
  },
];

const Deployment = () => {
  return (
    <Row
      className="g-4" // Bootstrap gap between columns
      style={{ marginTop: '20px', display: 'flex', alignItems: 'stretch' }} // Add Flexbox styles here
    >
      {deploymentOptions.map((option, index) => (
        <Col xs={12} lg={4} key={index} style={{ display: 'flex' }}>
          {/* Apply the motion component for slide-in animation */}
          <motion.div
            initial={{ opacity: 0, y: 50 }} // Start invisible and below
            animate={{ opacity: 1, y: 0 }} // Fade and slide up when in view
            transition={{ duration: 0.6 }}
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }} // Ensure flex behavior inside Col
          >
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 3,
                borderRadius: '25px', // Set border radius to 25px
                boxShadow: 3,
                transition: '0.3s',
                '&:hover': { boxShadow: 6, transform: 'scale(1.02)' },
                backgroundColor: '#fff', // White background for each card
                border: '1px solid #ddd', // Optional: Border for better separation
                height: '100%', // Set all cards to the same height (flexbox ensures they stretch equally)
              }}
            >
              <Typography variant="h3" component="div" mb={1}>
                {option.icon}
              </Typography>
              <Typography variant="h5" fontWeight="bold" mb={2}>
                {option.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                mb={2}
                sx={{ textAlign: 'center' }} // Center the description text
              >
                {option.description}
              </Typography>
              <List sx={{ width: '100%', textAlign: 'left' }}>
                {option.features.map((feature, i) => (
                  <ListItem key={i} disableGutters>
                    <ListItemIcon>
                      <CheckCircleIcon color="success" />
                    </ListItemIcon>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </motion.div>
        </Col>
      ))}
    </Row>
  );
};

export default Deployment;
