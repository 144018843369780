import React, { useState, useEffect } from "react";
import { Box, Divider, CircularProgress, Tooltip, IconButton, Drawer, Button } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import ReactCodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import ModifyStandard from "./ModifyStandard";
import DefineSearch from "./DefineSearch";
import DefineTags from "./DefineTags";
import CustomAlert from "./CustomAlert";
import '@n8n_io/n8n-demo-component';  // Import the Web Component's script
import '../../../App.scss';  // Import your custom CSS

const workflow = {
    "createdAt": "2025-02-10T23:18:53.197Z",
    "updatedAt": "2025-02-14T10:46:32.811Z",
    "id": "sAgRd4aQQQ0DnZa0",
    "name": "My workflow",
    "active": true,
    "nodes": [
      {
        "parameters": {},
        "id": "fb4d36eb-17ac-460b-a369-c6d91feefe4e",
        "name": "Window Buffer Memory",
        "type": "@n8n/n8n-nodes-langchain.memoryBufferWindow",
        "typeVersion": 1.1,
        "position": [
          400,
          300
        ]
      },
      {
        "parameters": {
          "name": "dont_know_tool",
          "description": "Use this tool if you don't know the answer to the user's question, or if you're not very confident about your answer.",
          "workflowId": "={{ $workflow.id}}",
          "fields": {
            "values": [
              {
                "name": "chatInput",
                "stringValue": "={{ $('Chat Trigger').item.json.chatInput }}"
              }
            ]
          }
        },
        "id": "68e841e8-cd1f-43c9-885c-9ae472c9493c",
        "name": "Not sure?",
        "type": "@n8n/n8n-nodes-langchain.toolWorkflow",
        "typeVersion": 1,
        "position": [
          620,
          300
        ]
      },
      {
        "parameters": {},
        "id": "afa039ba-5762-414d-a86c-c69517ea6c16",
        "name": "Execute Workflow Trigger",
        "type": "n8n-nodes-base.executeWorkflowTrigger",
        "typeVersion": 1,
        "position": [
          40,
          900
        ]
      },
      {
        "parameters": {
          "content": "### Sub-workflow: Custom tool\nThe agent above can call this workflow. It checks if the user has supplied an email address. If they haven't it prompts them to provide one. If they have, it messages a customer support channel for help.",
          "height": 775.3931210698682,
          "width": 1118.3459011229047,
          "color": 7
        },
        "id": "e35571c9-a631-46cf-b8b8-08b34c77f72a",
        "name": "Sticky Note1",
        "type": "n8n-nodes-base.stickyNote",
        "position": [
          0,
          560
        ],
        "typeVersion": 1
      },
      {
        "parameters": {
          "content": "### Main workflow: AI agent using custom tool",
          "height": 486.5625,
          "width": 927.5,
          "color": 7
        },
        "id": "a9e3c2b7-3b54-4128-abf5-753ea47ac1ac",
        "name": "Sticky Note2",
        "type": "n8n-nodes-base.stickyNote",
        "position": [
          0,
          0
        ],
        "typeVersion": 1
      },
      {
        "parameters": {
          "content": "**This tool calls the sub-workflow below**",
          "height": 179.21380662202682,
          "width": 197.45572294791873,
          "color": 5
        },
        "id": "da5515c4-2dbe-4631-ab7e-323b417bb807",
        "name": "Sticky Note",
        "type": "n8n-nodes-base.stickyNote",
        "position": [
          560,
          240
        ],
        "typeVersion": 1
      },
      {
        "parameters": {
          "content": "**Set your credentials**",
          "height": 213.44323866265472,
          "width": 150,
          "color": 2
        },
        "id": "81d95e12-ceed-47f4-b0fe-bcd03c42cf2b",
        "name": "Sticky Note5",
        "type": "n8n-nodes-base.stickyNote",
        "typeVersion": 1,
        "position": [
          200,
          220
        ]
      },
      {
        "parameters": {
          "content": "**Set your credentials and Slack details**",
          "height": 250.57252651663197,
          "width": 178.0499248677781,
          "color": 2
        },
        "id": "90a528d1-8595-441a-a7f9-47252454183e",
        "name": "Sticky Note4",
        "type": "n8n-nodes-base.stickyNote",
        "typeVersion": 1,
        "position": [
          520,
          720
        ]
      },
      {
        "parameters": {
          "content": "## Try it out\n\nSelect **Chat** at the bottom and enter:\n\n_Hi! Please respond to this as if you don't know the answer to my query._",
          "height": 214.8397420554627,
          "width": 185.9375,
          "color": 4
        },
        "id": "02c264a5-f107-4a2b-80b2-1b3c16dda83c",
        "name": "Sticky Note3",
        "type": "n8n-nodes-base.stickyNote",
        "position": [
          -160,
          60
        ],
        "typeVersion": 1
      },
      {
        "parameters": {
          "content": "## Next steps\n\nLearn more about [Advanced AI in n8n](https://docs.n8n.io/advanced-ai/)",
          "height": 144.50520156238127
        },
        "id": "346f6f66-6a1a-49b3-850f-605cd9721022",
        "name": "Sticky Note6",
        "type": "n8n-nodes-base.stickyNote",
        "typeVersion": 1,
        "position": [
          1080,
          1140
        ]
      },
      {
        "parameters": {},
        "id": "95ef409c-8b5e-4b11-8d87-deb04d74f08c",
        "name": "Chat Trigger",
        "type": "@n8n/n8n-nodes-langchain.chatTrigger",
        "typeVersion": 1,
        "position": [
          100,
          60
        ],
        "webhookId": "785e0c0c-12e5-4249-9abe-47bb131975cb"
      },
      {
        "parameters": {
          "jsCode": "response = {\"response\":\"I'm sorry I don't know the answer. Please repeat your question and include your email address so I can request help.\"};\nreturn response;"
        },
        "id": "ceb712f8-7661-4c08-b30f-84e0744dda0c",
        "name": "Prompt the user to provide an email",
        "type": "n8n-nodes-base.code",
        "typeVersion": 2,
        "position": [
          560,
          1060
        ]
      },
      {
        "parameters": {
          "jsCode": "response = {\"response\": \"Thank you for getting in touch. I've messaged a human to help.\"}\nreturn response;"
        },
        "id": "424fc6e4-04f0-4a37-8871-edc57ccfccd4",
        "name": "Confirm that we've messaged a human",
        "type": "n8n-nodes-base.code",
        "typeVersion": 2,
        "position": [
          800,
          800
        ]
      },
      {
        "parameters": {
          "options": {
            "systemMessage": "Try to answer the user's question. When you can't answer, or you're not confident of the answer, use the appropriate tool. When you use the dont_know_tool, respond with the message from the tool."
          }
        },
        "id": "8cbd61cb-988e-4972-9685-edf24911e9f7",
        "name": "AI Agent",
        "type": "@n8n/n8n-nodes-langchain.agent",
        "typeVersion": 1.2,
        "position": [
          320,
          60
        ]
      },
      {
        "parameters": {
          "conditions": {
            "options": {
              "caseSensitive": true,
              "leftValue": "",
              "typeValidation": "strict"
            },
            "conditions": [
              {
                "id": "5e21e7c5-db60-4111-bb17-c289ae0fc159",
                "leftValue": "={{ $('Execute Workflow Trigger').item.json.chatInput }}",
                "rightValue": "/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)/gi",
                "operator": {
                  "type": "string",
                  "operation": "regex"
                }
              }
            ],
            "combinator": "and"
          },
          "options": {}
        },
        "id": "3f305cc0-f411-4eb2-be26-d51b5e4db6b1",
        "name": "Check if user has provided email",
        "type": "n8n-nodes-base.if",
        "typeVersion": 2,
        "position": [
          260,
          900
        ]
      },
      {
        "parameters": {
          "select": "channel",
          "channelId": {
            "__rl": true,
            "value": "",
            "mode": "name"
          },
          "text": "={{ \"A user had a question the bot couldn't answer. Here's their message: \" + $('Execute Workflow Trigger').item.json.chatInput }}",
          "otherOptions": {}
        },
        "id": "74c6d96b-74b2-4d5e-8401-7a8ae41923c6",
        "name": "Message Slack for help",
        "type": "n8n-nodes-base.slack",
        "typeVersion": 2.1,
        "position": [
          560,
          800
        ],
        "webhookId": "7be08b7f-55ec-4753-85ef-8cb4c98446d5"
      },
      {
        "parameters": {
          "model": "gpt-4",
          "options": {
            "temperature": 0.2
          }
        },
        "id": "2d7e4e3b-c8ce-45e2-a0e9-7e65289de0b6",
        "name": "GPT4",
        "type": "@n8n/n8n-nodes-langchain.lmChatOpenAi",
        "typeVersion": 1,
        "position": [
          240,
          280
        ]
      }
    ],
    "connections": {
      "Window Buffer Memory": {
        "ai_memory": [
          [
            {
              "node": "AI Agent",
              "type": "ai_memory",
              "index": 0
            }
          ]
        ]
      },
      "Not sure?": {
        "ai_tool": [
          [
            {
              "node": "AI Agent",
              "type": "ai_tool",
              "index": 0
            }
          ]
        ]
      },
      "Execute Workflow Trigger": {
        "main": [
          [
            {
              "node": "Check if user has provided email",
              "type": "main",
              "index": 0
            }
          ]
        ]
      },
      "Chat Trigger": {
        "main": [
          [
            {
              "node": "AI Agent",
              "type": "main",
              "index": 0
            }
          ]
        ]
      },
      "Check if user has provided email": {
        "main": [
          [
            {
              "node": "Message Slack for help",
              "type": "main",
              "index": 0
            }
          ],
          [
            {
              "node": "Prompt the user to provide an email",
              "type": "main",
              "index": 0
            }
          ]
        ]
      },
      "Message Slack for help": {
        "main": [
          [
            {
              "node": "Confirm that we've messaged a human",
              "type": "main",
              "index": 0
            }
          ]
        ]
      },
      "GPT4": {
        "ai_languageModel": [
          [
            {
              "node": "AI Agent",
              "type": "ai_languageModel",
              "index": 0
            }
          ]
        ]
      }
    },
    "settings": {
      "executionOrder": "v1"
    },
    "staticData": null,
    "meta": null,
    "pinData": {},
    "versionId": "a8ecd083-dd80-40ca-8551-da33eb0e1c3d",
    "triggerCount": 2,
    "shared": [
      {
        "createdAt": "2025-02-10T23:18:53.197Z",
        "updatedAt": "2025-02-10T23:18:53.197Z",
        "role": "workflow:owner",
        "workflowId": "sAgRd4aQQQ0DnZa0",
        "projectId": "CCwj9TELZIljHy4Z",
        "project": {
          "createdAt": "2025-02-10T19:09:30.114Z",
          "updatedAt": "2025-02-10T22:09:25.430Z",
          "id": "CCwj9TELZIljHy4Z",
          "name": "Tom Goldsmith <tom@hoppa.ai>",
          "type": "personal",
          "icon": null,
          "projectRelations": [
            {
              "createdAt": "2025-02-10T19:09:30.114Z",
              "updatedAt": "2025-02-10T19:09:30.114Z",
              "role": "project:personalOwner",
              "userId": "90c1695e-40b5-4be2-a221-e7cd3c5d9af6",
              "projectId": "CCwj9TELZIljHy4Z",
              "user": {
                "createdAt": "2025-02-10T19:09:28.710Z",
                "updatedAt": "2025-02-10T23:18:53.516Z",
                "id": "90c1695e-40b5-4be2-a221-e7cd3c5d9af6",
                "email": "tom@hoppa.ai",
                "firstName": "Tom",
                "lastName": "Goldsmith",
                "personalizationAnswers": {
                  "version": "v4",
                  "personalization_survey_submitted_at": "2025-02-10T22:10:34.674Z",
                  "personalization_survey_n8n_version": "1.77.3",
                  "companySize": "<20",
                  "companyType": "systems-integrator",
                  "role": "business-owner",
                  "reportedSource": "linkedin"
                },
                "settings": {
                  "userActivated": false,
                  "easyAIWorkflowOnboarded": true
                },
                "role": "global:owner",
                "disabled": false,
                "mfaEnabled": true,
                "isPending": false,
                "isOwner": true
              }
            }
          ]
        }
      }
    ],
    "tags": []
  }

const Refinery = ({ standard, setStandard, handleSaveStandard, isSaveButtonEnabled, hasUpdatePermission }) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [isAdvancedModeOpen, setIsAdvancedModeOpen] = useState(false);
    const [jsonValue, setJsonValue] = useState(JSON.stringify(standard, null, 2)); // Store the JSON value locally
    const [isJsonChanged, setIsJsonChanged] = useState(false); // Track if changes are made in JSON editor

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed


    // Toggle between Classifiers and Enrichers tabs
    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    // Toggle the advanced editor (JSON editor)
    const toggleAdvancedMode = () => {
        setIsAdvancedModeOpen(!isAdvancedModeOpen);
    };

    // Handle changes in the JSON editor
    const handleJsonChange = (value) => {
        setJsonValue(value); // Update the local state with the current value
        setIsJsonChanged(true); // Enable save button when changes are made
        try {
            const parsedJson = JSON.parse(value);
            setStandard(parsedJson);
        } catch (e) {
            console.error("Invalid JSON"); // Handle invalid JSON gracefully
        }
    };

    // Save JSON from the editor
    const handleJsonSave = () => {
        setIsJsonChanged(false); // Reset the change flag
        setJsonValue(JSON.stringify(standard, null, 2)); // Set the saved value back to the editor
        handleSaveStandard(); // Call the save function
    };

    // Cancel changes made in the JSON editor
    const handleJsonCancel = () => {
        setJsonValue(JSON.stringify(standard, null, 2)); // Revert to the original value
        setIsJsonChanged(false); // Reset the change flag
    };

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true); // This will trigger the alert to show
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Placeholder for Title if needed */}
            </div>

            {Object.keys(standard).length > 0 ? (
                <div style={{ width: '100%' }}>
                    <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
                        <Row className="align-items-center">
                            <Col>
                                <Nav className="nav nav-pills">
                                    <Nav.Item style={{width: '100px', textAlign: 'center'}}>
                                        <Nav.Link eventKey={1}>Classify</Nav.Link>
                                    </Nav.Item >
                                    <Divider style={{ margin: '0 2px' }} />
                                    <Nav.Item style={{width: '100px', textAlign: 'center'}}>
                                        <Nav.Link eventKey={2}>Search</Nav.Link>
                                    </Nav.Item>
                                    <Divider style={{ margin: '0 2px' }} />
                                    <Nav.Item style={{width: '100px', textAlign: 'center'}}>
                                        <Nav.Link eventKey={3}>Tag</Nav.Link>
                                    </Nav.Item>
                                    <Divider style={{ margin: '0 2px' }} />
                                    <Nav.Item style={{width: '100px', textAlign: 'center'}}>
                                        <Nav.Link eventKey={4}>Workflow</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xs="auto" className="text-end">
                                <Tooltip
                                    title='Advanced Workspace Standard Editor'
                                            
                                >
                                    <span> {/* Wrap the IconButton in a <span> to prevent it from being clickable when disabled */}
                                        <IconButton
                                            onClick={toggleAdvancedMode}
                                            size="small"
                                            sx={{ marginRight: 2 }}
                                            disabled={!hasUpdatePermission} // Disable the button if permission is false
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>

                                {/* Save Button */}
                                <Tooltip title="Save Workspace Standard">
                                    <span>
                                        <IconButton
                                            onClick={handleSaveStandard}
                                            size="small"
                                            disabled={!isSaveButtonEnabled}
                                            sx={{
                                                fontSize: '50px',
                                            }}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Col>
                        </Row>

                        <Divider style={{ border: '1px solid grey', margin: '8px 0' }} />
                        <Tab.Content>
                            <Tab.Pane eventKey={1}>
                                <ModifyStandard standard={standard} setStandard={setStandard} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={2}>
                                <DefineSearch standard={standard} setStandard={setStandard} triggerAlert={triggerAlert} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={3}>
                                <DefineTags standard={standard} setStandard={setStandard} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={4}>
                                <n8n-demo workflow={JSON.stringify(workflow)}></n8n-demo>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            ) : (
                <CircularProgress />
            )}

            {/* Advanced Mode Panel (JSON Editor) */}
            <Drawer
                anchor="right"
                open={isAdvancedModeOpen}
                onClose={toggleAdvancedMode}
                sx={{ '& .MuiDrawer-paper': { width: '50%' } }}
            >
                <Box sx={{ padding: '1rem', height: '100%', overflowY: 'auto' }}>
                    {/* JSON Editor Buttons */}
                    <Box sx={{ display: 'flex', justifyContent: 'left', marginBottom: '1rem' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleJsonSave}
                            disabled={!isJsonChanged}
                            style={{ marginRight: "10px" }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleJsonCancel}
                            disabled={!isJsonChanged} // Disable Cancel if no changes are made
                        >
                            Cancel
                        </Button>
                    </Box>

                    {/* CodeMirror JSON Editor */}
                    <ReactCodeMirror
                        value={jsonValue}
                        extensions={[json()]}
                        height="100%"
                        theme="dark"
                        onChange={(value) => handleJsonChange(value)}
                        basicSetup={{
                            lineNumbers: true,
                            foldGutter: true,
                            highlightActiveLine: true,
                        }}
                    />
                </Box>
            </Drawer>
            <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
        </Box>
    );
};

export default Refinery;
