import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';

const steps = [
  {
    title: 'Your Data, Everywhere',
    description: 'Mountains of data—drawings, reports, invoices, models, handwritten notes, site photos and more—scattered across different systems.',
    image: `${process.env.PUBLIC_URL}/Your_Data_Everywhere.svg`,
  },
  {
    title: 'Hoppa, Your Data Refinery',
    description: 'Our kit-of-parts solutions slot into your existing workflows, connecting to your data sources or deploying directly to your IT.',
    image: `${process.env.PUBLIC_URL}/Feed_The_Hoppa.gif`,
  },
  {
    title: 'Intelligence, Unlocked',
    description: 'Pass documents through Hoppa to structure, extract insights, and instate control for 85% less effort than traditional document control.',
    image: `${process.env.PUBLIC_URL}/Hoppa_Intelligence.png`,
  },
];

const ThreeStepProcess = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 5, textAlign: 'center', position: 'relative' }}>
       <Box
                sx={{
                    position: 'absolute',
                    width: '140%',
                    height: '140%',
                    zIndex: -1,
                    background: 'radial-gradient(ellipse, rgba(110, 88, 237, 0.473) 0%, rgba(111,88,237,0) 50%)',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -47%)',
                    overflow: 'hidden'
                }}
            ></Box>
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {steps.map((step, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper
              sx={{
                p: 4,
                borderRadius: 3,
                boxShadow: 0,
                transition: '0.3s',
                '&:hover': { boxShadow: 6, transform: 'scale(1.03)' },
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                position: index === 1 ? 'relative' : 'static',
                zIndex: index === 1 ? 1 : 'auto'
              }}
            >
              {step.image ? (
                <Box
                  component="img"
                  src={step.image}
                  alt={step.title}
                  sx={{ width: '100%', height: 'auto', maxHeight: 250, objectFit: 'contain', mb: 2 }}
                />
              ) : (
                <Typography variant="h3" component="div" mb={2}>
                  {step.emoji}
                </Typography>
              )}
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {step.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ThreeStepProcess;
