import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import tagSuggestions from '../../../baseStandards/tagSuggestions.json';
import { useUserManagement, useCheckPermissions } from '../../../contexts/UserManagementContexts';
import { Box, Collapse, Fade, Stack } from '@mui/material';

const DefineTags = ({ standard, setStandard }) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [tagInput, setTagInput] = useState('');

  const { currentOrg, isLoadingOrgs } = useUserManagement();
  const checkPermissions = useCheckPermissions();

  const [hasUpdatePermission, setHasUpdatePermission] = useState(false);

  useEffect(() => {
    setAvailableTags(tagSuggestions);
  }, []);

  useEffect(() => {
    if (!isLoadingOrgs) {
      const updatePermission = checkPermissions(['update:workspaces'], currentOrg.name);
      setHasUpdatePermission(updatePermission);
    }
  }, [isLoadingOrgs, currentOrg]);

  const handleTagSelect = (tag) => {
    const updatedTags = standard.enrichers.tags.includes(tag)
      ? standard.enrichers.tags.filter((t) => t !== tag)
      : [...standard.enrichers.tags, tag];

    setStandard((prevStandard) => ({
      ...prevStandard,
      enrichers: {
        ...prevStandard.enrichers,
        tags: updatedTags,
      },
    }));
  };

  const handleTagInputChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    setTagInput(event.target.value);
  };

  const handleAddTag = (tag) => {
    if (tag.trim() !== '') {
      const updatedTags = [...standard.enrichers.tags, tag.trim()];

      setStandard((prevStandard) => ({
        ...prevStandard,
        enrichers: {
          ...prevStandard.enrichers,
          tags: updatedTags,
        },
      }));

      setTagInput('');
    }
  };

  const isTagSelected = (tag) => {
    return standard.enrichers.tags.includes(tag);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddTag(tagInput);
    }
  };

  return (
      <Row>
        {/* Right Column for Tags */}
        <Col md={12}>
          <div style={{ marginTop: '5px', width: '100%' }}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                disabled={!hasUpdatePermission}
                placeholder="Type new tag and press enter to create"
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyDown={handleKeyPress}
              />
            </Form.Group>
            <Box sx={{width: '100%'}}>
            <Stack direction='column'>
            <Collapse in={standard.enrichers.tags.length > 0}>
              <Stack sx={{display: 'flex', flexGrow: 1, width: '100%'}} direction='column'>
                <h5>Selected tags</h5>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {standard.enrichers.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      className="tag-selected mb-3 me-1 p-1"
                      onDelete={hasUpdatePermission ? () => handleTagSelect(tag) : null}
                    />
                  ))}
                </div>
              </Stack>
              </Collapse>
              <Stack sx={{minWidth: '50%'}} direction='column'>
                <h5>Suggested tags</h5>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {availableTags
                    .filter((tag) => !standard.enrichers.tags.includes(tag)) // Exclude selected tags
                    .map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        className={isTagSelected(tag) ? 'tag-selected mb-1 me-1 p-1' : 'filledChip mb-1 me-1 p-1'}
                        onClick={hasUpdatePermission ? () => handleTagSelect(tag) : null}
                      />
                    ))}
                </div>
              </Stack>
            </Stack>
            </Box>
          </div>
        </Col>
      </Row>
  );
};

export default DefineTags;
