import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderBar from './subcomponents/shared/HeaderBar';
import TopUpModal from './subcomponents/legacy/TopUpModal';
import { useSession } from '../contexts/SessionContexts';
import { Container, Row, Col, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Skeleton } from '@mui/material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const WelcomeUser = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { sessionGuid } = useSession();
  const [sessions, setSessions] = useState([]);  // State to store user sessions
  const [otherSessions, setOtherSessions] = useState([]); // State to store other sessions
  const [APIError, setAPIError] = useState(null);  // For error handling
  const [credits, setCredits] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();  // React Router's navigation hook

  let firstName
  firstName = user?.nickname || user?.name;
  firstName = String(firstName).charAt(0).toUpperCase() + String(firstName).slice(1);

  const sanitizeUserId = (userId) => {
    if (!userId) return '';  // Return an empty string if userId is undefined or falsy
    return userId.split('|')[1];  // Split by '|' and return the second part
  };

  const userId = sanitizeUserId(user?.sub);

  // Get Auth0 permissions object (custom action) and fallback to blank object if not there (i.e. for new users who haven't yet received permissions)
  const auth0AppMetadata = user?.['https://hoppa.ai/app_metadata'] || {};  // Default to an empty object
  const permissions = auth0AppMetadata.permissions || {};  // Default to an empty object for permissions
  const createSessionPermission = permissions?.initiate_new_session ?? false;  // Default to false if not found
  const subscription = permissions?.subscription; // Extract subscription value

  // Determine the credits based on permissions
  useEffect(() => {
    if (permissions?.subscription === 'admin') {
      setCredits(Infinity);  // Set to infinite for admin
    } else {
      setCredits(0);  // Set to 0 for all other users
    }
  }, [permissions]);

  // Extract 'Other Sessions' from app_metadata
  useEffect(() => {
    if (auth0AppMetadata?.permissions?.session_access) {
      const sessionAccessArray = auth0AppMetadata.permissions.session_access;

      // Ensure session_access is an array before processing
      if (Array.isArray(sessionAccessArray)) {
        // Map over each session string in the array
        const sessionsArray = sessionAccessArray.map((sessionString) => {
          const [container, sessionId] = sessionString.split('/');
          return { container, sessionId }; // Return an object for each session
        });

        // Set the sessions state
        setOtherSessions(sessionsArray);
      } else {
        console.error("session_access is not an array:", sessionAccessArray);
      }
    }
  }, [auth0AppMetadata]);

  // Function to handle creating a new session
  const handleCreateSession = () => {
    try {
      let userId = user.sub;  // user.sub holds the Auth0 user_id (unique identifier)
      userId = sanitizeUserId(userId);

      // Use axios to call the backend API
      axios.post(`${API_BASE_URL}/api/NewSessionTrigger`, null, {
        params: { userId: userId },
        headers: {
          'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
        }
      })
        .then((response) => {
          console.log("New session container created successfully:", response.data);

          // Navigate to the session initiation route using user_id
          navigate(`/${userId}/${sessionGuid}/initiate`);
        })
        .catch((error) => {
          setAPIError('Failed to create a new session.');
          console.error('Error creating session:', error);
        });

    } catch (error) {
      setAPIError('Failed to create a new session.');
      console.error('Error creating session:', error);
    }
  };

  // Function to fetch user sessions from the backend
  const handleGetUserSessions = () => {
    axios.post(`${API_BASE_URL}/api/GetUserSessions`, null, {
      params: { userId: userId },
      headers: {
        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
      }
    })
      .then((response) => {
        console.log("User sessions fetched successfully:", response.data);
        setSessions(response.data);  // Store the sessions in state
      })
      .catch((error) => {
        console.error('Error fetching user sessions:', error);
        setAPIError("It looks like you're new here! Create a session to get started.");
      });
  };

  // Fetch sessions when the component mounts or userId changes
  useEffect(() => {
    if (userId) {
      handleGetUserSessions();
    }
  }, [userId]);

  // Function to handle navigation to the results page of a session
  const handleSessionClick = (sessionId, container) => {
    // If the session comes from 'Other Sessions', use the provided container
    const sessionContainer = container || userId;
    navigate(`/${sessionContainer}/${sessionId}/results`);
  };

  const showEmptyMessage = sessions.length === 0 && otherSessions.length === 0;

  const handleTopUpClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePayment = (totalCredits, totalPrice) => {
    // Logic to handle payment
    console.log(`Credits: ${totalCredits}, Total Price: £${totalPrice}`);
    setShowModal(false);

    // Proceed with payment using the Checkout component logic
  };

  return (
    <div className="secondary-background">
      <HeaderBar
        homeLink="/"
        homeText="hoppa"
        userInfo={user}
      />
      <div className="full-width-lt-blue-section">
        <Container style={{ padding: '0px', height: '100px', alignContent: 'center' }}>

          {
            user ?
              (<h2>Welcome, {firstName} 👋</h2>)
              : 
              (
                <Skeleton variant='rectangular' width={300} height={35}/>
              )
          }

        </Container>
        {showEmptyMessage ? (
          <Container className='rounded-box' style={{ padding: '20px', background: 'white' }}>
            <h5 className="mb-4">🌟 It looks like you're new here! 🌟</h5>
            <p>Your account will need to be verified before you can start using our services &nbsp;✅</p>
            <p>If your organisation is already registered with us, you'll be notified as soon as access to your company's resources have been granted &nbsp;🚀</p>
            <p>If your organisation is new to hoppa, we'd be delighted to offer you a free trial and will be in touch with the details &nbsp;👌</p>
            <p>In the meantime, if you have specific questions you can always reach us as at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a> &nbsp;💬</p>
          </Container>
        ) : (
          <>
            <Container className='mt-2 rounded-box' style={{ padding: '20px', background: 'white', overflowX: 'auto' }}>
              <h5>Owned Sessions:</h5>
              {APIError && <div className="alert alert-danger">{APIError}</div>}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Session ID</th>
                    <th>Created On</th>
                    <th>File Count</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.map(session => (
                    <tr key={session.sessionId}>
                      <td>{session.sessionId}</td>
                      <td>{session.createdOn}</td>
                      <td>{session.blobCount}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSessionClick(session.sessionId)}
                        >
                          View Results
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>

            <Container className='mt-2 rounded-box' style={{ padding: '20px', background: 'white', overflowX: 'auto' }}>
              <h5>Shared With You:</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Session ID</th>
                    <th>Container</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {otherSessions.map(session => (
                    <tr key={session.sessionId}>
                      <td>{session.sessionId}</td>
                      <td>{session.container}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleSessionClick(session.sessionId, session.container)}
                        >
                          View Results
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
          </>
        )}
      </div>
    </div>
  );
};

export default WelcomeUser;
