import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, InputGroup } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Card, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";

function formatNumber(value) {
  if (value >= 1_000_000_000) {
    return (value / 1_000_000_000).toFixed(2) + 'B';
  } else if (value >= 1_000_000) {
    return (value / 1_000_000).toFixed(2) + 'M';
  } else if (value >= 1_000) {
    return (value / 1_000).toFixed(1) + 'K';
  } else {
    return value.toFixed(0);
  }
}

const currencies = [
  { symbol: '£', label: 'GBP' },
  { symbol: '$', label: 'USD' },
  { symbol: '€', label: 'EUR' },
  { symbol: 'د.إ', label: 'AED' },
  { symbol: '₹', label: 'INR' },
  { symbol: '$', label: 'CAD' },
  { symbol: '$', label: 'AUD' },
  { symbol: '$', label: 'SGD' },
  { symbol: '$', label: 'HKD' },
  { symbol: '¥', label: 'JPY' }
];

const RoiCalculator = () => {
  const [numFiles, setNumFiles] = useState(10000);
  const [timePerFile, setTimePerFile] = useState(15);
  const [hourlyRate, setHourlyRate] = useState(50);
  const [manualCost, setManualCost] = useState(0);
  // Adjust price per file here
  const [pricePerDeliverable, setPricePerDeliverable] = useState(2.5);
  const [totalCostSaving, setTotalCostSaving] = useState(0);
  const [totalTimeSaving, setTotalTimeSaving] = useState(0);
  const [roi, setROI] = useState(0);
  const [currency, setCurrency] = useState(currencies[0]);

  useEffect(() => {
    // Calculate time to perform manually
    const calculatedTotalTimeSaving = Math.max(numFiles * (timePerFile / 60), 0)
    setTotalTimeSaving(calculatedTotalTimeSaving);

    // Calculate the cost of doing manually
    const calculatedManualCost = Math.max(calculatedTotalTimeSaving * hourlyRate, 0);
    setManualCost(calculatedManualCost);

    const ourPrice = (pricePerDeliverable * numFiles);

    // Calculate the total cost saving and ROI
    const calculatedTotalCostSaving = calculatedManualCost - ourPrice;
    setTotalCostSaving(calculatedTotalCostSaving);



    const calculatedROI = (calculatedManualCost !== 0) ? (calculatedTotalCostSaving / ourPrice) * 100 : 0;
    setROI(calculatedROI);

  }, [numFiles, timePerFile, hourlyRate, pricePerDeliverable]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div>
      <Row>
        <Col md={12} lg={6} className='mb-4'>
          <Row>
            {/*<Col xs={6}>*/}
            <p className='small-font grey-font mt-2 mb-1'>Number of data objects for processing (documents, drawings, rows of data etc.)</p>
            <div className='input-number_box'> {/*style={{ width:"200px" }}*/}
              <IconButton className='ps-0' onClick={() => setNumFiles(numFiles - 50)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Form.Control
                className='input-number'
                value={numFiles}
                onChange={(e) => setNumFiles(e.target.value)}
              />
              <IconButton onClick={() => setNumFiles(parseInt(numFiles) + 50)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
            {/* </Col>*/}
            {/*<Col xs={6} className="text-end">
          <p className='small-font grey-font mt-2 mb-0'>Our Price</p>
          <p style= {{fontSize:"35px", paddingTop:"2px"}} className="mt-1 green-font">
              <b>
                £{numberWithCommas(numFiles > 399 ? (pricePerDeliverable * numFiles).toFixed(0) : (pricePerDeliverable * numFiles).toFixed(2))}
              </b>
            </p>
          </Col>*/}
          </Row>
          <input
            type='range'
            id='files'
            value={numFiles}
            onChange={(e) => setNumFiles(e.target.value)}
            min={50}
            max={100000}
            step={50}
            className='win10-thumb'
          />
          <Row>

            <p className='small-font grey-font mt-2 mb-1'>Manual time to process each object (find, interpret, classify, query, relabel etc.)</p>
            <div className='input-number_box'>
              <IconButton className='ps-0' onClick={() => setTimePerFile(timePerFile - 1)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Control
                  className='input-number'
                  value={timePerFile}
                  onChange={(e) => setTimePerFile(parseInt(e.target.value, 10))}
                />
                <InputGroup.Text className='input-number_units'>mins</InputGroup.Text>
              </InputGroup>
              <IconButton onClick={() => setTimePerFile(parseInt(timePerFile) + 1)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>


          </Row>

          <input
            type='range'
            id='files'
            value={timePerFile}
            onChange={(e) => setTimePerFile(e.target.value)}
            min={1}
            max={60}
            step={1}
            className='win10-thumb'
          />
          <Row>
            {/*<Col xs={6}>*/}
            <p className='small-font grey-font mt-2 mb-1'>Hourly rate for personnel conducting manual processing</p>
            <div className='input-number_box'> {/*style={{ width:"200px" }}*/}
              <IconButton className='ps-0' onClick={() => setHourlyRate(hourlyRate - 5)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                {/*<InputGroup.Text className='input-number_units'>£</InputGroup.Text>*/}
                <Form.Select className='input-number_units' onChange={(e) => setCurrency(currencies.find(c => c.label === e.target.value))}>
                  {currencies.map((c) => (
                    <option key={c.label} value={c.label}>{c.label} ({c.symbol})</option>
                  ))}
                </Form.Select>
                <Form.Control
                  className='input-number'
                  value={hourlyRate}
                  onChange={(e) => setHourlyRate(parseInt(e.target.value, 10))}
                />
              </InputGroup>
              <IconButton onClick={() => setHourlyRate(parseInt(hourlyRate) + 5)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
            {/*</Col>*/}
            {/*<Col xs={6}>
            {/*<div className="text-end">
              <p className='small-font grey-font mt-2 mb-0'>Manual cost</p>
              <p style={{ fontSize: "30px", paddingTop:"2px" }} className="mt-2 red-font">
                <b>
                  £{numberWithCommas(manualCost.toFixed(0))}
                </b>
              </p>
            </div>*/}
            {/*</Col>*/}
          </Row>
        </Col>
        <Col xs={12} lg={6}>
          <Card
            className="calculated-price mt-3"
            style={{
              borderRadius: '25px',
              border: '0px solid transparent',
              backgroundClip: 'padding-box, border-box',

            }}
          >
            {/* Header with Gradient Background & White Text */}
            <div className="btn-primary"
              style={{
                //backgroundColor: '', 
                textAlign: 'center',
                color: 'white',
                padding: '10px',
                borderTopLeftRadius: '25px',
                borderTopRightRadius: '25px'
              }}
            >
              <h3>Hoppa could help you avoid...</h3>
            </div>

            {/* Card Body */}
            <Card.Body style={{ textAlign: "center", borderRadius: '25px' }}>
              <h3 className="mt-3">Manual Effort</h3>
              <h3 className='headline-figure purple-gradient-font'>{formatNumber(totalTimeSaving)} hrs</h3>
              <h3 className="mt-4">Wasted Spend</h3>
              <h3 className='headline-figure purple-gradient-font'>{currency.symbol}{formatNumber(manualCost)}</h3>

              {/* Button */}
              <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                <button className="btn btn-secondary">
                  <a href="/solutions" style={{ textDecoration: 'none', color: 'inherit', padding: '0 16px' }}>
                    Find out more
                  </a>
                </button>
              </div>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </div>
  );
};

export default RoiCalculator;
