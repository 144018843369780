import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Box, FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { Delete } from '@mui/icons-material';
import { useUserManagement, useCheckPermissions } from "../../../contexts/UserManagementContexts";
import { useNavigate, useParams } from "react-router-dom";

const DefineSearch = ({ standard, setStandard, triggerAlert }) => {
  const [validationErrors, setValidationErrors] = useState({})
  const [isCreatingSearchTerm, setIsCreatingSearchTerm] = useState(false)

  const searchTypes = ['General', 'Number', 'Currency', 'Date Range', 'Date']

  const { currentOrg, workspace } = useParams();
  const { user, organizations, setOrganizations, isLoading, isLoadingOrgs, fetchingWorkspaces, setFetchingWorkspaces } = useUserManagement();
  const checkPermissions = useCheckPermissions();
  const navigate = useNavigate();
  const [hasEditPermission, setHasEditPermission] = useState(false); // If user is able to create new workspaces

  // Permission check
  useEffect(() => {
    if (!isLoading && !isLoadingOrgs && currentOrg) {
      const isAuthorised = checkPermissions(['read:workspaces'], currentOrg)
      if (isAuthorised) {
        const editPermission = checkPermissions(['update:workspaces'], currentOrg)
        setHasEditPermission(editPermission)
      } else {
        navigate('/access-denied')
      }
    }
  }, [user, isLoading, isLoadingOrgs, currentOrg]);


  const handleCheckboxChange = (name) => {
    // Find the matching search term
    const searchTerm = standard.enrichers.searchTerms.find(
      (searchTerm) => searchTerm.name === name
    );

    if (!searchTerm) {
      console.error('Search term not found');
      return;
    }

    // Create a new search term with updated 'active' status
    const updatedSearchTerm = { ...searchTerm, active: !searchTerm.active };

    // Update the search terms array
    const updatedSearchTerms = standard.enrichers.searchTerms.map((term) =>
      term.name === name ? updatedSearchTerm : term
    );

    // Update the state immutably
    setStandard((prevStandard) => ({
      ...prevStandard,
      enrichers: {
        ...prevStandard.enrichers,
        searchTerms: updatedSearchTerms, // Use the updated list
      },
    }));
  };


  const validateRequired = (value) => !!value.length;

  function validateSearchTerm(value) {
    return {
      name: !validateRequired(value.name)
        ? 'Name is required'
        : '',
      prompt: !validateRequired(value.body) ? 'Prompt is Required' : '',
    };
  }

  //CREATE action
  const handleCreateSearchTerm = async ({ values, table }) => {

    // console.log(values)

    setIsCreatingSearchTerm(true)

    await createSearchTerm(values);
    table.setCreatingRow(null); //exit creating mode

    setIsCreatingSearchTerm(false)
  };

  const createSearchTerm = (newSearchTerm) => {
    // Ensure the "type" key is set
    const searchTermWithType = {
      ...newSearchTerm,
      type: newSearchTerm.type || searchTypes[0], // Add a default value if 'type' is missing
      active: true,
    };

    // Update state with the modified search term
    setStandard((prevStandard) => ({
      ...prevStandard,
      enrichers: {
        ...prevStandard.enrichers,
        searchTerms: [
          ...prevStandard.enrichers.searchTerms,
          searchTermWithType,
        ],
      },
    }));
  };


  const openDeleteConfirmModal = (row) => {
    if (window.confirm('Are you sure you want to delete this search query?')) {
      deleteSearchTerm(row.original.name);
    }
  };

  const deleteSearchTerm = (name) => {
    const updatedSearchTerms = standard.enrichers.searchTerms.filter((searchTerm) => searchTerm.name !== name);

    setStandard((prevStandard) => ({
      ...prevStandard,
      enrichers: {
        ...prevStandard.enrichers,
        searchTerms: updatedSearchTerms,
      },
    }));
  };

  const columns = useMemo(() => {
    const dynamicColumns = [
      {
        accessorKey: 'name',
        header: 'Title',
        size: 50,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          required: 'true',
          multiline: 'true',
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? 'Required'
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });
            // Update the state of standard
            setStandard((prevStandard) => ({
              ...prevStandard,
              enrichers: {
                ...prevStandard.enrichers,
                searchTerms: prevStandard.enrichers.searchTerms.map((searchTerm) =>
                  searchTerm.name === row.original.name
                    ? { ...searchTerm, name: event.currentTarget.value } // Update the corresponding searchTerm
                    : searchTerm // Keep the rest unchanged
                ),
              },
            }));
          }
        })
      },
      {
        accessorKey: 'body',
        header: 'Prompt',
        grow: true,
        minSize: 40,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          required: true,
          multiline: 'true',
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? 'Required'
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });
            // Update the state of standard
            setStandard((prevStandard) => ({
              ...prevStandard,
              enrichers: {
                ...prevStandard.enrichers,
                searchTerms: prevStandard.enrichers.searchTerms.map((searchTerm) =>
                  searchTerm.name === row.original.name
                    ? { ...searchTerm, body: event.currentTarget.value } // Update the corresponding searchTerm
                    : searchTerm // Keep the rest unchanged
                ),
              },
            }));
          }
        })
      },
      {
        accessorKey: 'type',
        header: 'Type',
        editVariant: 'select',
        size: 50,
        defaultValue: searchTypes[0],
        editSelectOptions: searchTypes,
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          error: !!validationErrors?.state,
          helperText: validationErrors?.state,
          onChange: (event) =>
            setStandard((prevStandard) => ({
              ...prevStandard,
              enrichers: {
                ...prevStandard.enrichers,
                searchTerms: prevStandard.enrichers.searchTerms.map((searchTerm) =>
                  searchTerm.name === row.original.name
                    ? { ...searchTerm, type: event.target.value } // Update the corresponding searchTerm
                    : searchTerm // Keep the rest unchanged
                ),
              },
            }))
        }),
      },


    ]

    return dynamicColumns
  }, [standard, validationErrors])

  const table = useMaterialReactTable({
    columns,
    data: standard.enrichers.searchTerms,
    createDisplayMode: 'row',
    editDisplayMode: 'table',
    layoutMode: 'grid',
    enableEditing: hasEditPermission,
    enableColumnActions: false,
    enableColumnPinning: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: true,
    getRowId: (row) => row.id,
    muiEditTextFieldProps: (cell) => ({
      placeholder: cell.column.id === 'name'
        ? 'Enter title (e.g. Published Date)'  // Placeholder for the 'name' column
        : cell.column.id === 'body'
          ? "Enter body (e.g. 'find the date when the document was last published')"
          : 'Enter value'
    }),

    muiToolbarAlertBannerProps: Object.keys(standard).length === 0
      ? {
        color: 'error',
        children: 'Error loading data',
      }
      : undefined,

    onCreatingRowCancel: () => setValidationErrors({}),
    enableRowSave: false,
    onCreatingRowSave: (obj) => {

      const newValidationErrors = validateSearchTerm(obj.values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        triggerAlert('Fix errors before creating', 'error')
        return
      }
      else {
        handleCreateSearchTerm(obj)
      }
    },
    positionActionsColumn: 'last',
    muiCreateRowProps: ({ row }) => ({
      // Disable save button if there are validation errors
      saveButtonProps: {
        disabled: Object.values(validationErrors).some((error) => !!error), // Disable if the row has errors
      },
    }),
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '', //change header text
        size: 10, //make actions column wider
      },
    },
    muiTablePaperProps: {
      sx: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible'
      }
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: 'transparent',
        p: 0,
        '.MuiBox-root': { p: 0 }
      }
    },
    muiTableContainerProps: {
      sx: {
        minHeight: '200px',
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
      }
    },
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: 0 }}>
        <Tooltip title="Delete">
          <span>
            <IconButton color="error" onClick={() => openDeleteConfirmModal(row)} disabled={!hasEditPermission}>
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={row.original.active ? 'Deactivate' : 'Activate'}>
          <span>
            <FormControlLabel
              control={<Switch checked={row.original.active} onChange={() => handleCheckboxChange(row.original.name)} sx={{ color: '#4a4bd6', }} />}
              sx={{ m: 0 }}
              disabled={!hasEditPermission}
            />
          </span>
        </Tooltip>
      </Box>
    ),
    renderBottomToolbarCustomActions: () => (
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        {Object.values(validationErrors).some((error) => !!error) && (
          <Typography color="error">Fix errors before saving</Typography>
        )}
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        className='btn btn-secondary'
        onClick={() => {
          table.setCreatingRow(true); //simplest way to open the create row modal with no default values
          //or you can pass in a row object to set default values with the `createRow` helper function
          // table.setCreatingRow(
          //   createRow(table, {
          //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
          //   }),
          // );
        }}
        disabled={table.creatingRow || !hasEditPermission}
      >
        Create new search query
      </Button>
    ),
    state: {
      isSaving: isCreatingSearchTerm,
    },
  })


  return (
    <>
      <Box>
        <MaterialReactTable table={table} />
      </Box>
    </>
  )
};

export default DefineSearch;
