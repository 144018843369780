import React, { useState, useEffect } from "react";
import { Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import RerunFilesModal from "./RerunFilesModal";  // Import the modal
import { useResults } from '../../../contexts/ResultsContexts';
import { AspectRatio, Check, Edit } from "@mui/icons-material";


const ResultsStatusBar = ({
  setFileNamesFiltered,
  fileCount,
  baseResults
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBlobs, setSelectedBlobs] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");  // To track the currently active filter
  const { mergedResults } = useResults()
  const [counts, setCounts] = useState({
    low: 0,
    medium: 0,
    high: 0,
    edited: 0,
    total: 0,
  })

  const [statuses, setStatuses] = useState({
    all: [],
    low: [],
    medium: [],
    high: [],
    edited: []
  })

  useEffect(() => {

    const counts = {
      low: 0,
      medium: 0,
      high: 0,
      edited: 0,
      total: 0,
    };

    const statuses = {
      all: new Set(),
      low: new Set(),
      medium: new Set(),
      high: new Set(),
      edited: new Set()
    };

    mergedResults.forEach((item) => {
      const baseItem = baseResults.find(baseItem => baseItem.name === item.name);
        
      // Loop through each property in the object
      Object.entries(item).forEach(([key, property]) => {
        if (typeof property === "object" && property !== null) {
          // Check if the object has a 'code' property
          if (property.hasOwnProperty("code")) {
            counts.total++; // Increment code count
            statuses.all.add(item.name);
    
            // Get the corresponding property from baseItem
            const baseProperty = baseItem?.[key];
    
            // Determine which certainty value to use
            const certaintyToCheck = baseProperty?.code === property.code 
              ? baseProperty?.certainty 
              : property.certainty;
    
            // Check the certainty property
            if (certaintyToCheck?.toLowerCase() === "low") {
              counts.low++;
              statuses.low.add(item.name);
            } else if (certaintyToCheck?.toLowerCase() === "medium") {
              counts.medium++;
              statuses.medium.add(item.name);
            } else if (certaintyToCheck?.toLowerCase() === "high") {
              counts.high++;
              statuses.high.add(item.name);
            } else {
              // If 'certainty' is missing, classify as 'edited'
              counts.edited++;
              statuses.edited.add(item.name);
            }
          }
        }
      });
    });

    if (statuses[activeFilter] && statuses[activeFilter].size === 0) {
      setFileNamesFiltered([])
    }

    // Convert sets to arrays to store in state and remove duplicates
    setStatuses({
      all: Array.from(statuses.all),
      low: Array.from(statuses.low),
      medium: Array.from(statuses.medium),
      high: Array.from(statuses.high),
      edited: Array.from(statuses.edited),
    });

    // Update counts
    setCounts(counts);
  }, [mergedResults]);


  // Handle clicking on a progress bar
  const handleProgressBarClick = (status) => {
    if (activeFilter === status) {
      setFileNamesFiltered(statuses['all']);  // Reset to full list (clear the filter)
      setActiveFilter("all");  // Reset active filter
    } else {
      setFileNamesFiltered(statuses[status]);  // Apply the new filter
      setActiveFilter(status);  // Mark this status as active
    }
  }


  return (
    <>
      {/* Bootstrap Progress Bar */}
      <Stack direction='column' sx={{ width: '100%' }} spacing={1}>

        <div className="progress" style={{ height: "24px", borderRadius: '50px', width: '100%' }}>
        <Tooltip title='Click to filter'>
            {/* Edited Files */}
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${100 * counts.edited / counts.total}%`, cursor: "pointer", backgroundColor: "#4a4bd6" }}
              aria-valuemin="0"
              aria-valuemax="100"
              onClick={() => handleProgressBarClick("edited")}
            >
            </div>
          </Tooltip>
          {/* Perfect Files */}
          <Tooltip title='Click to filter'>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${100 * counts.high / counts.total}%`, cursor: "pointer", backgroundColor: "SeaGreen" }}
              aria-valuemin="0"
              aria-valuemax="100"
              onClick={() => handleProgressBarClick("high")}
            >
            </div>
          </Tooltip>
          <Tooltip title='Click to filter'>
            {/* Good Files */}
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${100 * counts.medium / counts.total}%`, cursor: "pointer", backgroundColor: "Gold" }}
              aria-valuemin="0"
              aria-valuemax="100"
              onClick={() => handleProgressBarClick("medium")}
            >
            </div>
          </Tooltip>
          <Tooltip title='Click to filter'>
            {/* Warning Files */}
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${100 * counts.low / counts.total}%`, cursor: "pointer", backgroundColor: "DarkOrange" }}
              aria-valuemin="0"
              aria-valuemax="100"
              onClick={() => handleProgressBarClick("low")}
            >
            </div>
          </Tooltip>
          <Tooltip title='Click to filter'>
            {/* Awaiting Processing Files */}
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              style={{ width: `${100 * (counts.total - counts.low - counts.medium - counts.high - counts.edited) / counts.total}%`, cursor: "pointer" }}
              aria-valuemin="0"
              aria-valuemax="100"
              onClick={() => setModalOpen(true)}
            >
            </div>
          </Tooltip>
        </div>

        <Box>
          <Stack direction='row' spacing={2} sx={{ maxWidth: '700px', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
              <Stack direction='column'>
                <Box>
                  <Button
                    onClick={() => handleProgressBarClick('edited')}
                    sx={{
                      fontWeight: 'bold',
                      color: activeFilter === 'edited' ? '#4a4bd6' : 'black',
                      textTransform: 'none', // Removes default uppercase transformation
                      '&:hover': {
                        color: '#4a4bd6',
                      },
                      textAlign: 'left',
                      p:0
                    }}
                  >
                    User Edited
                  </Button>               </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Edit sx={{ width: '20px', color: '#4a4bd6', marginRight: '4px' }} />
                  {counts.edited} ({Math.round(1000 * counts.edited / counts.total) / 10}%)
                </Box>
              </Stack>
            </Box>
            
            <Box>
              <Stack direction='column'>
                <Box>
                <Button
                    onClick={() => handleProgressBarClick('high')}
                    sx={{
                      fontWeight: 'bold',
                      color: activeFilter === 'high' ? '#4a4bd6' : 'black',
                      textTransform: 'none', // Removes default uppercase transformation
                      '&:hover': {
                        color: '#4a4bd6',
                      },
                      textAlign: 'left',
                      p:0
                    }}
                  >
                    High Certainty
                  </Button> 
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Check sx={{width: '24px', height: '24px', color: 'SeaGreen'}}/>
                  {counts.high} ({Math.round(1000 * counts.high / counts.total) / 10}%)
                </Box>
              </Stack>
            </Box>

            <Box>
              <Stack direction='column'>
                <Box>
                <Button
                    onClick={() => handleProgressBarClick('medium')}
                    sx={{
                      fontWeight: 'bold',
                      color: activeFilter === 'medium' ? '#4a4bd6' : 'black',
                      textTransform: 'none', // Removes default uppercase transformation
                      '&:hover': {
                        color: '#4a4bd6',
                      },
                      textAlign: 'left',
                      p:0
                    }}
                  >
                    Medium Certainty
                  </Button> 
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '16px', height: '16px', borderRadius: '16px', backgroundColor: 'Gold', marginRight: '8px' }} />
                  {counts.medium} ({Math.round(1000 * counts.medium / counts.total) / 10}%)
                </Box>
              </Stack>
            </Box>


            <Box>
              <Stack direction='column'>
                <Box>
                <Button
                    onClick={() => handleProgressBarClick('low')}
                    sx={{
                      fontWeight: 'bold',
                      color: activeFilter === 'low' ? '#4a4bd6' : 'black',
                      textTransform: 'none', // Removes default uppercase transformation
                      '&:hover': {
                        color: '#4a4bd6',
                      },
                      textAlign: 'left',
                      p:0
                    }}
                  >
                    Low Certainty
                  </Button> 
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '16px', height: '16px', borderRadius: '16px', marginRight: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>⚠️ </Box>
                  {counts.low} ({Math.round(1000 * counts.low / counts.total) / 10}%)
                </Box>
              </Stack>
            </Box>

            <Divider sx={{border: '1px solid black'}}/>

            <Box>
            <Stack direction='column'>
                <Box>
                  <Typography sx={{fontWeight: 'bold',}}>
                    Status
                  </Typography>               
                  </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {mergedResults.length} of {fileCount.total} analysed
                </Box>
              </Stack>
            </Box>

          </Stack>
        </Box>

      </Stack>


      {/* RerunFilesModal Component
    <RerunFilesModal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      blobs={categorizedBlobs.AwaitingProcessing}
      selectedBlobs={selectedBlobs}
      setSelectedBlobs={setSelectedBlobs}
    /> */}
    </>
  );
};

export default ResultsStatusBar;
