import React, { createContext, useContext, useState, useEffect } from 'react';

const ResultsContext = createContext();

export const ResultsProvider = ({ children }) => {
  const [mergedResults, setMergedResults] = useState([]);
  const [isUnsavedEdits, setIsUnsavedEdits] = useState(false);
  const [savedEdits, setSavedEdits] = useState([]);
  const [allEdits, setAllEdits] = useState({});
  const [customColumns, setCustomColumns] = useState([])

  // Reset function to reset all state to initial values
  const resetState = () => {
    setMergedResults([])
    setIsUnsavedEdits(false)
    setSavedEdits([])
    setAllEdits({})
    setCustomColumns([])
  };

  return (
    <ResultsContext.Provider value={{
      mergedResults,
      setMergedResults,
      isUnsavedEdits,
      setIsUnsavedEdits,
      savedEdits,
      setSavedEdits,
      allEdits,
      setAllEdits,
      customColumns,
      setCustomColumns,
      resetState
    }}>
      {children}
    </ResultsContext.Provider>
  );
}

export const useResults = () => {
  return useContext(ResultsContext);
}
