import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { useUserManagement, useCheckPermissions } from '../contexts/UserManagementContexts';
import { useResults } from '../contexts/ResultsContexts';
import HeaderBar from './subcomponents/shared/HeaderBar';
import { Box, Divider, List, ListItemButton, ListItem, ListItemText, Typography, ListItemIcon, CircularProgress, Collapse, Chip, ListItemSecondaryAction, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Button, Modal, Form } from 'react-bootstrap';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { Add, Architecture, Bolt, ConstructionOutlined, Expand, ExpandLess, ExpandMore, Inbox, MoreHoriz, PlusOne, Refresh } from '@mui/icons-material';
import GettingStarted from './subcomponents/dashboard/GettingStarted';
import CategoryHub from './subcomponents/dashboard/Blueprints';
import Workspace from './subcomponents/dashboard/Workspace';
import InitiateWorkspace from './subcomponents/dashboard/InitiateWorkspace';
import CustomAlert from './subcomponents/shared/CustomAlert';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

let config = {
    headers: {
        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
    }
}

const Dashboard = () => {
    const { currentOrg, workspace } = useParams();
    const navigate = useNavigate();
    const { user, organizations, setOrganizations, isLoading, isLoadingOrgs, fetchingWorkspaces, setFetchingWorkspaces } = useUserManagement();
    const [sizes, setSizes] = useState([100, '30%', 'auto']);
    const [selectedItem, setSelectedItem] = useState('default')
    const [selectedPage, setSelectedPage] = useState('default')
    const [dropdownLogos, setDropdownLogos] = useState({}); // State to hold logos for dropdown items

    // Permission Check
    const checkPermissions = useCheckPermissions();
    const [hasCreatePermission, setHasCreatePermission] = useState(false); // If user is able to create new workspaces
    const [hasEditPermission, setHasEditPermission] = useState(false); // If user is able to edit workspaces
    const [hasDeletePermission, setHasDeletePermission] = useState(false); // If user is able to delete workspaces

    const [blueprints, setBlueprints] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [orgName, setOrgName] = useState('');
    const [workspaceName, setWorkspaceName] = useState('');
    const [isCreating, setIsCreating] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed

    const [anchorElContext, setAnchorElContext] = useState(null);

    const { resetState } = useResults()

    // Reset the state when the component mounts
    useEffect(() => {
        resetState();
    }, []);


    const layoutCSS = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px'
    };

    useEffect(() => {
        document.title = "Organisation Dashboard | Hoppa"; // Set the browser tab title here
    }, []);


    // On page load
    useEffect(() => {
        if (currentOrg && workspace && !isLoadingOrgs) {
            organizations.forEach(org => {
                if (org.name === currentOrg && !org.isExpanded) {
                    handleTabExpand(currentOrg);
                }

                if (org.name === currentOrg) {
                    org.workspaces?.forEach(ws => {
                        if (ws.workspace_name === workspace && ws.active === 'Config' || ws.revised_workspace_name === workspace && ws.active === 'Config') {
                            handleMenuSelection(`${currentOrg}/${workspace}`, 'initiate-workspace');
                        } else if (ws.workspace_name === workspace || ws.revised_workspace_name === workspace) {
                            handleMenuSelection(`${currentOrg}/${workspace}`, 'workspace');
                        }
                    });
                }
            });
        }
    }, [isLoadingOrgs, currentOrg, organizations]);

    // Permission check
    useEffect(() => {
        if (!isLoading && !isLoadingOrgs && currentOrg) {
            const isAuthorised = checkPermissions(['read:workspaces'], currentOrg)
            if (isAuthorised) {
                const createPermission = checkPermissions(['create:workspaces'], currentOrg)
                const editPermission = checkPermissions(['update:workspaces'], currentOrg)
                const deletePermission = checkPermissions(['delete:workspaces'], currentOrg)
                setHasCreatePermission(createPermission)
                setHasEditPermission(editPermission)
                setHasDeletePermission(deletePermission)
            } else {
                navigate('/access-denied')
            }
        }
    }, [user, isLoading, isLoadingOrgs, currentOrg]);

    useEffect(() => {
        const fetchBlueprints = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/GetBlueprintsTrigger`, config);
                setBlueprints(response.data);
            } catch (err) {
                console.error("Error fetching blueprints:", err);
                setBlueprints(err);
            } finally {
            }
        };
        fetchBlueprints();
    }, []);

    // Fetch dropdown logos
    useEffect(() => {
        const fetchDropdownLogos = async () => {
            const logos = {};

            for (const org of organizations) {
                if (org.branding?.logo_url) {
                    try {
                        const response = await fetch(org.branding.logo_url);
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        logos[org.id] = url; // Store logo URL by organization ID
                    } catch (error) {
                        console.error("Error fetching dropdown organization image:", error);
                    }
                } else {
                    console.warn(`No logo URL found for organization ${org.name}`);
                }
            }

            setDropdownLogos(logos); // Update state with all loaded logos
        };

        fetchDropdownLogos();
    }, [organizations]);

    const fetchWorkspaces = async (orgName) => {
        // Add orgName to the fetchingWorkspaces array if it's not already in there
        setFetchingWorkspaces((prevFetchingWorkspaces) => {
            if (!prevFetchingWorkspaces.includes(orgName)) {
                return [...prevFetchingWorkspaces, orgName]; // Add orgName to the array
            }
            return prevFetchingWorkspaces; // Return the original array if orgName is already present
        });

        // Check if user access restricted to certain workspaces
        const auth0AppMetadata = user['https://hoppa.ai/app_metadata'] || {};
        const resources = auth0AppMetadata.resources || {};
        const grantedWorkspaces = resources[orgName] || {}; // List of workspaces the user has been granted access to. 

        let workspaces;

        try {
            const response = await axios.get(
                `${API_BASE_URL}/api/GetOrganizationWorkspaces?orgName=${orgName}`,
                config
            );
            const workspacesList = response.data;

            // If grantedWorkspaces list is empty then no access restrictions applied, user can access all workspaces in the org.
            if (grantedWorkspaces.length > 0) {
                // Filter workspacesList based on the user's access
                workspaces = workspacesList.filter(workspace => {
                    // Use revised_workspace_name if it’s populated, otherwise fall back to workspace_name
                    const nameToCheck = workspace.revised_workspace_name || workspace.workspace_name;

                    // Check if the nameToCheck is in grantedWorkspaces
                    return grantedWorkspaces.includes(nameToCheck);
                });
            } else {
                workspaces = workspacesList;
            }

        } catch (error) {
            console.error('Error fetching workspaces:', error);
            workspaces = 'No workspaces found'
        } finally {
            // Update the state with the new organizations array
            setOrganizations(prevOrganizations => {
                return prevOrganizations.map(org => {
                    if (org.name === orgName) {
                        // If the organization matches, return the updated object with workspaces
                        return { ...org, workspaces }; // Properly return the updated organization
                    }
                    return org; // Return the unchanged organization if the name doesn't match
                });
            });
            // Remove orgName from fetchingWorkspaces when the fetching is done (either success or failure)
            setFetchingWorkspaces((prevFetchingWorkspaces) =>
                prevFetchingWorkspaces.filter(org => org !== orgName)
            );

        }
    };

    const handleCreateWorkspace = async () => {

        const trimmedWorkspaceName = workspaceName.trim();

        setIsCreating(true);
        try {
            if (!orgName) {
                console.error('Organization name is required.');
                alert('Organization name is required', 'error');
                return;
            }

            if (!trimmedWorkspaceName) {
                console.error('Workspace name is required.');
                alert('Please enter a workspace name.', 'error');
                return;
            }

            const existingNames = organizations.find(org => org.name === currentOrg)?.workspaces || []

            // Check if the new workspace name is already in use
            const isNameInUse = existingNames.some(
                workspace => workspace.workspace_name.trim().toLowerCase() === trimmedWorkspaceName.trim().toLowerCase()
            );

            if (isNameInUse) {
                alert('This name cannot be picked as it is already in use. Please choose a different name.');
                return;
            }

            const response = await axios.post(`${API_BASE_URL}/api/NewWorkspaceTrigger`,
                {
                    orgName: orgName,
                    workspaceName: trimmedWorkspaceName,
                    createdBy: user.email
                },
                config);

            if (response.data) {
                console.log("Container found and virtual folder created successfully.");
                // Navigate to the initiation page for the new workspace automatically
                handleMenuSelection(`${orgName}/${trimmedWorkspaceName}`, 'initiate-workspace')
            } else {
                console.error("Failed to create virtual folder:", response.data);
                triggerAlert('Workspace creation unsuccessful', 'error')
            }

            setShowModal(false)
            triggerAlert('Workspace creation successful')
            fetchWorkspaces(orgName)
            setWorkspaceName('')
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            triggerAlert(error.response ? error.response.data : error.message, 'error')
        } finally {
            setIsCreating(false)
        }
    };

    // Handle workspace renaming
    const handleRename = async (workspaceName) => {

        // Prompt user for the new workspace name
        const newWorkspaceName = prompt("Enter a new name for the workspace:");

        // Validate the new name
        if (!newWorkspaceName || newWorkspaceName.trim() === "") {
            alert("Invalid name. Please enter a valid new name.");
            return;
        }

        const existingNames = organizations.find(org => org.name === currentOrg)?.workspaces || []

        /// Check if the new workspace name is already in use
        const isNameInUse = existingNames.some(
            workspace => workspace.workspace_name.trim().toLowerCase() === newWorkspaceName.trim().toLowerCase() && !workspace.revised_workspace_name
        );

        if (isNameInUse) {
            alert('This name cannot be picked as it is already in use. Please choose a different name.');
            return;
        }

        try {
            // Call the RenameWorkspaceTrigger API to rename the workspace
            const response = await axios.post(
                `${API_BASE_URL}/api/RenameWorkspaceTrigger?orgName=${currentOrg}&workspaceName=${workspaceName}&newWorkspaceName=${newWorkspaceName}`,
                {}, // Empty payload for rename request
                config
            );

            if (response.status !== 200) {
                throw new Error("Failed to rename the workspace.");
            }

            triggerAlert('Workspace renamed')

            // Refresh workspaces after renaming or provide success feedback
            await fetchWorkspaces(currentOrg);
        } catch (error) {
            console.error("Error renaming workspace:", error);
            alert("An error occurred while renaming the workspace.");
            triggerAlert('Workspace rename unsuccessful', 'error')
        }
    };

    const handleDuplicate = async (workspaceName) => {

        // Prompt user for the new workspace name for duplication
        const newWorkspaceName = prompt("Enter a new name for the duplicated workspace:");

        // Validate the new name
        if (!newWorkspaceName || newWorkspaceName.trim() === "") {
            alert("Invalid name. Please enter a valid new name.");
            return;
        }

        const existingNames = organizations.find(org => org.name === currentOrg)?.workspaces || []

        // Check if the new workspace name is already in use
        const isNameInUse = existingNames.some(
            workspace => workspace.workspace_name.trim().toLowerCase() === newWorkspaceName.trim().toLowerCase()
        );

        if (isNameInUse) {
            alert('This name cannot be picked as it is already in use. Please choose a different name.');
            return;
        }

        try {
            // Call the DuplicateWorkspaceTrigger API to duplicate the workspace
            const response = await axios.post(
                `${API_BASE_URL}/api/DuplicateWorkspaceTrigger?orgName=${currentOrg}&workspaceName=${workspaceName}&newWorkspaceName=${newWorkspaceName}`,
                {}, // Empty payload for duplicate request
                config
            );

            if (response.status !== 200) {
                throw new Error("Failed to duplicate the workspace.");
            }

            triggerAlert('Workspace duplicated')

            // Refresh workspaces after duplication or provide success feedback
            await fetchWorkspaces(currentOrg);
        } catch (error) {
            console.error("Error duplicating workspace:", error);
            alert("An error occurred while duplicating the workspace.");
            triggerAlert('Workspace duplication unsuccessful', 'error')
            handleMenuSelection(`${currentOrg}/${newWorkspaceName}`)
        }
    };

    // Handle workspace deletion
    const handleDelete = async (workspaceName) => {

        // Prompt the user for confirmation before deletion
        const userConfirmed = window.confirm(
            "Are you sure you want to delete this workspace? This action cannot be undone, and all workspace session data will be permanently deleted. Are you sure you want to proceed?"
        );

        if (!userConfirmed) return;

        try {
            // Call the DeleteWorkspaceTrigger API to delete the workspace
            const response = await axios.post(
                `${API_BASE_URL}/api/DeleteSession?containerName=${currentOrg}&virtualFolder=${workspaceName}`,
                {}, // Empty payload for delete request
                config
            );

            if (response.status !== 200) {
                throw new Error("Failed to delete the workspace.");
            }

            triggerAlert('Workspace deleted')
            handleMenuSelection('default')

            // Refresh workspaces after deletion or provide success feedback
            await fetchWorkspaces(currentOrg);
        } catch (error) {
            console.error("Error deleting workspace:", error);
            alert("An error occurred while deleting the workspace.");
            triggerAlert('Workspace deletion unsuccessful', 'error')
        }
    };


    const handleMenuSelection = (item, page) => {
        setSelectedItem(item)
        if (page) {
            setSelectedPage(page)
        } else {
            setSelectedPage(item)
        }

        if (page === 'workspace' || page === 'initiate-workspace') {
            navigate(`/dashboard/uk/${item}`)
        } else {
            navigate('/dashboard')
        }
    }

    const handleTabRender = () => {

        if (selectedPage === 'default') {
            return <GettingStarted />
        }
        if (selectedPage === 'blueprints') {
            return <CategoryHub blueprints={blueprints} />
        }
        if (selectedPage === 'workspace') {
            return <Workspace />
        }
        if (selectedPage === 'initiate-workspace') {
            return <InitiateWorkspace handleMenuSelection={handleMenuSelection} fetchWorkspaces={fetchWorkspaces} blueprints={blueprints} triggerAlert={triggerAlert} />
        }
    }

    const handleTabExpand = (orgName) => {
        // Update the state with the new organizations array
        setOrganizations(prevOrganizations => {
            // Map through the previous organizations and update the matching one
            return prevOrganizations.map(org => {
                if (org.name === orgName) {
                    // Toggle the `isExpanded` property
                    const isExpanded = !org.isExpanded;

                    // If workspaces are not loaded and the tab is expanding, fetch workspaces
                    if (!org.workspaces && isExpanded) {
                        fetchWorkspaces(orgName);  // Fetch workspaces when expanding
                    }

                    // Return the updated organization object
                    return { ...org, isExpanded };
                }
                return org;  // Return the non-matching organizations unchanged
            });
        });
    };

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true); // This will trigger the alert to show
    };

    const handleOpenContextMenu = (event) => {
        setAnchorElContext(event.currentTarget);
    };

    const handleCloseContextMenu = () => {
        setAnchorElContext(null);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'ghostwhite', position: 'relative' }}>
            <HeaderBar homeLink="/" wide userInfo={user} />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, }}>
                <SplitPane
                    split='vertical'
                    sizes={sizes}
                    onChange={setSizes}
                >
                    <Pane minSize={220} maxSize='50%'>
                        <div style={{ ...layoutCSS, background: 'white', borderRight: '1px solid lightgray', overflowY: 'auto' }}>
                            <List>
                                <ListItem sx={{ p: 0 }} key='default'>
                                    <ListItemButton sx={{ p: '4px' }} selected={selectedItem === 'default'} onClick={() => handleMenuSelection('default')}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}>
                                            <Bolt />
                                        </ListItemIcon>
                                        <ListItemText sx={{ p: 0 }}>
                                            Getting Started
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem sx={{ p: 0, mb: '8px' }} key='blueprints'>
                                    <ListItemButton sx={{ p: '4px' }} selected={selectedItem === 'blueprints'} onClick={() => handleMenuSelection('blueprints')}>
                                        <ListItemIcon sx={{ minWidth: '32px' }}>
                                            <Architecture />
                                        </ListItemIcon>
                                        <ListItemText sx={{ p: 0 }}>
                                            Explore Blueprints
                                        </ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider sx={{ border: '1px solid grey' }} />
                            <Box sx={{ m: '16px 8px' }}>
                                <Typography variant='h6' sx={{ lineHeight: 0.8 }}>
                                    Select a workspace
                                </Typography>
                                <Typography variant='caption'>
                                    within an organization
                                </Typography>
                            </Box>
                            <List>
                                {!isLoadingOrgs ?
                                    (
                                        organizations.length > 0 ?
                                            organizations.sort((a, b) => a.name.localeCompare(b.name)).map((org, index) => (
                                                <React.Fragment key={org.name}>
                                                    <ListItem
                                                        sx={{ p: 0, mb: '8px' }}
                                                        key={`${org.name}-accordionheader`}
                                                        secondaryAction={
                                                            <>
                                                                <Tooltip title='Add workspace'>
                                                                    <IconButton edge="end" onClick={() => {
                                                                        setOrgName(org.name);
                                                                        setShowModal(true);
                                                                    }}
                                                                        disabled={!checkPermissions(['create:workspaces'], org.name) || fetchingWorkspaces.includes(org.name)}
                                                                    >
                                                                        <Add />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title='Refresh workspace'>
                                                                    <IconButton edge="end" onClick={() => {
                                                                        fetchWorkspaces(org.name);
                                                                        if (!org.isExpanded) {
                                                                            handleTabExpand(org.name);
                                                                        }
                                                                    }}
                                                                    >
                                                                        <Refresh />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    >
                                                        <ListItemButton sx={{ p: '4px' }} onClick={() => handleTabExpand(org.name)}>
                                                            {org.isExpanded ? <ExpandMore /> : <ExpandLess />}
                                                            <img
                                                                src={dropdownLogos[org.id]} // Use the loaded logo URL for dropdown items
                                                                alt={`${org.name} Logo`}
                                                                style={{
                                                                    borderRadius: '50%',
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    margin: '0 6px',
                                                                }}
                                                            />
                                                            <ListItemText primary={org.display_name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Collapse key={`${org.name}-accordionbody`} in={org.isExpanded} timeout="auto" unmountOnExit>
                                                        {
                                                            !org.workspaces || fetchingWorkspaces.includes(org.name) ?

                                                                (
                                                                    <Box sx={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <CircularProgress size={30} />
                                                                    </Box>
                                                                )
                                                                :
                                                                (
                                                                    Array.isArray(org.workspaces) ?
                                                                        (
                                                                            org.workspaces
                                                                                .sort((a, b) => {
                                                                                    const nameA = a.revised_workspace_name || a.workspace_name;
                                                                                    const nameB = b.revised_workspace_name || b.workspace_name;
                                                                                    return nameA.localeCompare(nameB);
                                                                                })
                                                                                .map((ws, index) => {
                                                                                    const ws_name = ws.revised_workspace_name || ws.workspace_name
                                                                                    return (
                                                                                        <React.Fragment key={`${org.name}/${ws_name}-parent`}>
                                                                                            <ListItem
                                                                                                sx={{ p: 0, mb: '8px' }}
                                                                                                key={`${org.name}/${ws_name}`}
                                                                                                secondaryAction={selectedItem === `${org.name}/${ws.workspace_name}` ?
                                                                                                    <IconButton edge="end" onClick={handleOpenContextMenu}>
                                                                                                        <MoreHoriz />
                                                                                                    </IconButton>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            >
                                                                                                <ListItemButton sx={{ p: '0 8px' }} onClick={() => handleMenuSelection(`${org.name}/${ws.workspace_name}`, ws.active === 'Config' ? 'initiate-workspace' : 'workspace')} selected={selectedItem === `${org.name}/${ws.workspace_name}`}>
                                                                                                    <ListItemText primary={ws_name} sx={{ color: 'gray' }} />
                                                                                                    {ws.active === 'Config' && (
                                                                                                        <Box
                                                                                                            sx={{
                                                                                                                display: 'inline-block',
                                                                                                                width: '8px',
                                                                                                                height: '8px',
                                                                                                                backgroundColor: 'blue',
                                                                                                                borderRadius: '50%',
                                                                                                                marginLeft: '8px',
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </ListItemButton>
                                                                                            </ListItem>
                                                                                            <Menu

                                                                                                id="menu-appbar"
                                                                                                anchorEl={anchorElContext}
                                                                                                keepMounted
                                                                                                open={Boolean(anchorElContext) && ws.workspace_name === workspace}
                                                                                                onClose={handleCloseContextMenu}
                                                                                            >
                                                                                                <MenuItem sx={{ m: '8px 0' }} disabled={!hasEditPermission} onClick={() => {
                                                                                                    handleCloseContextMenu()
                                                                                                    handleRename(ws.workspace_name)
                                                                                                }}>
                                                                                                    <ListItemText
                                                                                                        sx={{ color: 'gray' }}
                                                                                                    >
                                                                                                        Rename
                                                                                                    </ListItemText>
                                                                                                </MenuItem>
                                                                                                <MenuItem sx={{ m: '8px 0' }} disabled={!hasCreatePermission} onClick={() => {
                                                                                                    handleCloseContextMenu()
                                                                                                    handleDuplicate(ws.workspace_name)
                                                                                                }}>
                                                                                                    <ListItemText
                                                                                                        sx={{ color: 'gray' }}
                                                                                                    >
                                                                                                        Duplicate
                                                                                                    </ListItemText>
                                                                                                </MenuItem>
                                                                                                <MenuItem sx={{ m: '8px 0' }} disabled={!hasDeletePermission} onClick={() => {
                                                                                                    handleCloseContextMenu()
                                                                                                    handleDelete(ws.workspace_name)
                                                                                                }}>
                                                                                                    <ListItemText
                                                                                                        sx={{ color: 'gray' }}
                                                                                                    >
                                                                                                        Delete
                                                                                                    </ListItemText>
                                                                                                </MenuItem>
                                                                                            </Menu>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                }
                                                                                )
                                                                        )
                                                                        :
                                                                        (
                                                                            <Box sx={{ width: '100%', height: '50px', display: 'flex', alignItems: 'top', justifyContent: 'center' }}>
                                                                                <Typography variant='caption'>
                                                                                    {org.workspaces}
                                                                                </Typography>
                                                                            </Box>
                                                                        )
                                                                )
                                                        }
                                                    </Collapse>
                                                </React.Fragment>
                                            ))
                                            :
                                            <Box sx={{ m: '16px 8px' }}>
                                                <Typography variant='body2'>
                                                    You are not a member of any organizations.
                                                </Typography>
                                            </Box>

                                    )
                                    :
                                    (<Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularProgress />
                                    </Box>)
                                }
                            </List>
                        </div>
                    </Pane>
                    <div style={{ ...layoutCSS }}>
                        {handleTabRender()}
                    </div>
                </SplitPane>
            </Box>
            {/* Modal for entering new workspace name */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New Workspace</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="workspaceName">
                            <Form.Label>Workspace Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter workspace name"
                                autoFocus
                                value={workspaceName}
                                onChange={(e) => setWorkspaceName(e.target.value)}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleCreateWorkspace();
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowModal(false)
                        setWorkspaceName('')
                    }}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleCreateWorkspace()
                        }
                        }
                        disabled={isCreating || !workspaceName.trim()} // Disable button if no name or during creation
                    >
                        {isCreating ? 'Creating...' : 'Create Workspace'}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />

        </Box>
    )
}

export default Dashboard