import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap'
import HeaderBar from '../subcomponents/shared/HeaderBar';
import FooterBar from '../subcomponents/shared/FooterBar';
import NavBar from './NavBar';

const BackupPolicy = () => {

    useEffect(() => {
        document.title = "Backup Policy | Hoppa"; // Set the browser tab title here
    }, []); // Empty dependency array ensures this runs only on component mount

    const htmlContent = `
<article>
    <style>
        [data-custom-class='body'], [data-custom-class='body'] * {
            background: transparent !important;
        }
        [data-custom-class='title'], [data-custom-class='title'] * {
            font - family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
        }
        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            font - family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
        }
        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font - family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
        }
        [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
            font - family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
        }
        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        }
        [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
        }
    </style>
    <div data-custom-class="body">
        <div>
            <strong><span style="font-size: 26px;">
                <span data-custom-class="title"><bdt class="block-component"></bdt>
                    <bdt class="question">BACKUP POLICY</bdt>
                </span>
            </span>
            </strong>
            <div><br></div>
            <strong>
                <span style="font-size: 15px;">
                    <span data-custom-class="subtitle">Last updated <bdt class="question">September 01, 2024</bdt>
                    </span>
                </span>
            </strong>
        </div>
        <div><br></div>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        This Backup Policy for Hoppa Technologies Limited (doing business as hoppa) ('we', 'us', or 'our') is to ensure that a consistent process is adopted for the backup of data, libraries and critical devices. This is to ensure:
                    </span>
                </span>
                <div><br></div>
            </span>
            <span style="font-size: 15px;"><span style="color: rgb(127, 127, 127)">
                <span data-custom-class="body_text">
                    <span style="color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                        </span></span>
                </span></span>
            </span>
        </div>
        <ul>
            <li data-custom-class="body_text" style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                            Backups are in place to facilitate system recovery to cater for the eventuality of a major disaster or hardware failure
                        </span>
                    </span>
                </span>
            </li>
            <div><br></div>
            <li data-custom-class="body_text" style="line-height: 1.5;">
                <span style="font-size: 15px; color: rgb(89, 89, 89);">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span data-custom-class="body_text">
                            Backups are in place to facilitate application recovery to cater for the eventuality of application investigation and the ability to re-generate documents for meeting local statutory requirements and business requirements.
                        </span>
                    </span>
                </span>
            </li>
        </ul>
        <div><br></div>
        <div id="scope" style="line-height: 1.5;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            SCOPE
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                This document is applicable to hoppa and covers application customer data including database items, file storage, and generated document metadata.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div><br></div>
        <div id="backup-policy" style="line-height: 1.5;">
            <span style="color: rgb(0, 0, 0);">
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <span style="font-size: 15px; color: rgb(0, 0, 0);">
                        <span style="font-size: 15px; color: rgb(0, 0, 0)">
                            <span id="control" style="color: rgb(0, 0, 0);">
                                <strong>
                                    <span data-custom-class="heading_1">
                                        1 BACKUP POLICY
                                    </span>
                                </strong>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.1 </b>Backups must be made on a regular basis that will ensure the continuity of processing and service in the event of an interruption.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.2 </b>All backups must be recorded, uniquely identified, stored securely, and subject to secure disposal procedures.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.3 </b>Copies of backup files must be kept in a different region (off-site) for cloud services in a secure location at all times. Backup copies must be transferred to the alternate region regularly, preferably at least once daily.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.3 </b>Copies of backup files must be kept in a different region (off-site) for cloud services in a secure location at all times. Backup copies must be transferred to the alternate region regularly, preferably at least once daily.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.4 </b>Security of backup storage must be maintained in compliance with the Physical Security/Environmental Controls Standards.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.5 </b>There should be periodic testing of backup media at both on- and off-site locations (at least once a year) to ensure that backups are in useable condition for recovery and that their contents are as documented. Backup media found to be unreadable or otherwise unavailable must be reported to the Co-founders.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.6 </b>All movements of backup files must be monitored and logged.  Only authorised staff may move backups to anywhere other than the primary and off-site locations.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.7 </b>Copies of backup files moved to or from off-site storage locations must be provided with defined and agreed levels of security and encryption during transportation and storage.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.8 </b>The retention period of backup must be sufficient to restore data in line with our SLAs concerning service recovery.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.9 </b>When service architecture and/or providers are changed, consideration should be given to the backup media and data formats to ensure that they can still be restored.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>1.10 </b>Access to backup media must be capable of being retrieved within a time scale documented in the computer disaster recovery plan
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div><br></div>
        <div id="restoration" style="line-height: 1.5;">
            <span style="color: rgb(0, 0, 0);">
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <span style="font-size: 15px; color: rgb(0, 0, 0);">
                        <span style="font-size: 15px; color: rgb(0, 0, 0)">
                            <span id="control" style="color: rgb(0, 0, 0);">
                                <strong>
                                    <span data-custom-class="heading_1">
                                        2 RESTORATION
                                    </span>
                                </strong>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>2.1 </b>Authorisation to restore data from backup media that would overwrite existing production data must be obtained from the Data Owners.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>2.2 </b>In the event of system failure, an escalation procedure must be in place and made aware to all relevant parties.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>2.3 </b>Recovery and restart procedures must be established and easily accessible to relevant parties.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>2.4 </b>Source documents, reports and backup media for the reconstruction of a system must be identified and documented.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>2.5 </b>Restoration of a previous configuration to any points in time within either statutory requirements or company requirements whichever is the greater should be established and documented.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>2.6 </b>Restoration of the current configuration must be within agreed recovery timescales.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div><br></div>
        <div id="enforcement" style="line-height: 1.5;">
            <span style="color: rgb(0, 0, 0);">
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <span style="font-size: 15px; color: rgb(0, 0, 0);">
                        <span style="font-size: 15px; color: rgb(0, 0, 0)">
                            <span id="control" style="color: rgb(0, 0, 0);">
                                <strong>
                                    <span data-custom-class="heading_1">
                                        3 ENFORCEMENT
                                    </span>
                                </strong>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>3.1 </b>All staff are required to comply with this security policy and associated procedures. Disciplinary actions including possible termination may be taken against any staff members who fail to comply with hoppa’s security policies or circumvent/violate any security systems and/or protection mechanisms.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>3.2 </b>Staff having knowledge of personal misuse or malpractice of IT Systems must report immediately to management and IT Security.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>3.3 </b>hoppa staff must ensure that our contractors and others parties authorised by hoppa to use its internal computer systems, comply with this policy.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
            <div><br></div>
            <div>
                <span data-custom-class="body_text">
                    <span style="font-size: 15px; color: rgb(89, 89, 89);">
                        <span style="font-size: 15px; color: rgb(89, 89, 89);">
                            <span data-custom-class="body_text">
                                <b>3.4 </b>Where the role of the service provider is outsourced to a vendor, the outsourced vendor should ensure compliance with this policy.
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
    </div>
</article>
`

    return (
        <div >
            <NavBar variant='blue'/>
            <div className="full-width-primary-section">
                <Container>
                    <Container className='rounded-box' style={{ marginTop: '9%', padding: '4vw' }}>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Container>
                </Container>
                <footer>
                    <FooterBar />
                </footer>
            </div>
        </div>
    )
}

export default BackupPolicy;
