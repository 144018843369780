import React, { useState, useEffect, useCallback } from "react";
import { Typography, Box, Divider, CircularProgress } from '@mui/material';
import { Nav, Tab } from 'react-bootstrap';
import CustomiseSessionStandard from "./CustomiseSessionStandard";
import CustomiseSessionEnrichers from "./CustomiseSessionEnrichers";
import Refinery from "../shared/Refinery";

const RefineryTab = ({ sessionStandard, setSessionStandard, saveStandard }) => {

    const [selectedTab, setSelectedTab] = useState(1);

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    return (

        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    {/* Title */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Data Refinery
                    </Typography>

                </Box>
            </div>
            {Object.keys(sessionStandard).length > 0 ? (
                <Refinery
                    standard={sessionStandard}
                    setStandard={setSessionStandard}
                    handleSaveStandard={saveStandard}
                    isSaveButtonEnabled={true}
                />
            )
                :
                <CircularProgress />
            }
        </Box>
    )
}

export default RefineryTab;