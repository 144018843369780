import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Row, Col, Badge, Card, Form, Alert, Modal, Button } from 'react-bootstrap';
import TypingEffect from './TypingEffect';
import Strengths from './Strengths';
import ThreeStepProcess from './ThreeStepProcess';
import Deployment from './Deployment';
import Capabilities from './Capabilities';
import Features from './Features';
import RoiCalculator from './RoiCalculator';
import Subscribe from './Subscribe';
import RequestADemo from './RequestADemo';
import NavBar from './NavBar'
import FooterBar from '../subcomponents/shared/FooterBar';
import Popup from '../subcomponents/shared/Popup';
import Overlay from '../subcomponents/shared/Overlay';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
import Backdrop from '@mui/material/Backdrop';
import { useSession } from '../../contexts/SessionContexts';
import '../../App.scss';
import ReactGA from 'react-ga';
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, CardActionArea, Grid, Stack, Typography } from '@mui/material';
import MainHeader from './MainHeader';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Home = (props) => {
  const navigate = useNavigate(); // for handling page routing within a function. 
  const location = useLocation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { setSession } = useSession();
  const { sessionGuid } = useSession();
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showStartupModal, setShowStartupModal] = useState(false);
  const [resultGuid, setResultGuid] = useState('');
  const inputRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [APIError, setAPIError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [showSubscribe, setShowSubscribe] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showSpeakModal, setShowSpeakModal] = useState(false)
  const [imageSrc, setImageSrc] = useState('');

  // Subscription modal popup on page scroll
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscribeModalShown, setSubscribeModalShown] = useState(false);

  const benefits = [
    {
      statement: "Get to the facts",
      assertion: "Extract insights buried deep in your documents",
      icon: "📊"
    },
    {
      statement: "Flag risks",
      assertion: "Audit your data to signpost gaps or inconsistencies",
      icon: "⚠️"
    },
    {
      statement: "Tackle new regulation",
      assertion: "Evidence compliance with IM standards",
      icon: "📜"
    },
    {
      statement: "Respond to change",
      assertion: "Re-classify legacy data to meet changing requirements",
      icon: "🔄"
    },
    {
      statement: "Get organised",
      assertion: "Connect your document repositories to sort data at source",
      icon: "🗂️"
    }
  ]

  const lastHash = useRef('');

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);


  // Google analytics ping on component mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // For conditionally styling components based on window width. 
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Auto-ping login button when user returning from email validation on sign-up
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return {
      message: queryParams.get('message'),
      success: queryParams.get('success'),
      // Add more parameters as needed
    };
  };

  const { message, success } = getQueryParams();

  useEffect(() => {
    if (success === 'true' && !isAuthenticated) {
      // Auto-trigger login with redirect if success parameter is true and user is not authenticated
      loginWithRedirect();
    }
  }, [success, isAuthenticated, loginWithRedirect]);

  const handleShowSessionModal = () => setShowSessionModal(true);
  const handleHideSessionModal = () => {
    setShowSessionModal(false);
    setResultGuid('');
    handleFormReset('EnterSessionKey')
  }

  const handleShowStartupModal = () => setShowStartupModal(true);
  const handleHideStartupModal = () => {
    setShowStartupModal(false);
    handleFormReset('EnterSessionKey')
  }


  const handleGetResultsButtonClick = (event) => {

    const fetchUserStandard = async () => {
      try {

        const config = {
          headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          }
        }

        const response = await axios.get(`${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${resultGuid}&folderName=${userId}`, config);
        setShowSpinner(false);
        setSession(resultGuid, userId);
        navigate(`${userId}/${resultGuid}/results`)
        // if (props.onGetResultsButtonClick) {
        //   props.onGetResultsButtonClick();
        // }
      } catch (error) {
        setShowSpinner(false)
        console.error("Error fetching userStandard:", error);
        setAPIError('Session key not found');
      }
    };

    event.preventDefault();
    event.stopPropagation();

    // Trigger form validation. 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormError('Cannot be blank')
      setValidated(true);
      return;
    }

    setShowSpinner(true);

    fetchUserStandard();

  };

  const handleAuthenticate = (event) => {

    event.preventDefault();
    event.stopPropagation();

    // Trigger form validation. 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormError('Cannot be blank')
      setValidated(true);
      return;
    }

    const passwordSetting = process.env[`REACT_APP_USER_${userId}`];

    if (passwordSetting && password === passwordSetting) {

      setAPIError(null)
      navigate(`${userId}/${sessionGuid}/initiate`)

    }
    else {
      setAPIError('Invalid userId or password')
    }
  }

  const handleAlertClose = () => {
    setAPIError(null);
  };

  const handleRegisterInterest = () => {
    if (showSubscribe) {
      setShowSubscribe(false)
    } else {
      setShowSubscribe(true); // Show the Subscribe component
    }

  };

  const handleFormReset = (formId) => {
    const form = document.getElementById(formId);
    if (form) {
      form.reset();
    } else {
      console.error(`Form with ID '${formId}' not found.`);
    }
    setAPIError(null);
    setFormError(null);
    setValidated(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showSessionModal) {
      inputRef.current.focus();
    }
  }, [showSessionModal]);


  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleImageClick = (event, src) => {
    event.preventDefault(); // Prevent the default behavior (scrolling to the top)
    setImageSrc(src);
    setShowModal(true);
    event.stopPropagation(); // Prevent the event from bubbling up to the <a> tag
  };

  useEffect(() => {
    // Check if subscribeModalShown is stored in local storage
    const storedSubscribeModalShown = localStorage.getItem('subscribeModalShown');

    // Initialize subscribeModalShown state based on local storage value
    if (storedSubscribeModalShown) {
      setSubscribeModalShown(true);
    }
  }, []);


  // Subscribe modal effects and event listener
  useEffect(() => {
    const handleScroll = () => {
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        const rect = featuresSection.getBoundingClientRect();
        // If the modal has not been shown yet
        if (!subscribeModalShown) {
          // Open the modal when scrolling to the features section
          if (rect.top <= window.innerHeight) {
            setShowSubscribeModal(true);
            setSubscribeModalShown(true);
            // Store subscribeModalShown in local storage
            localStorage.setItem('subscribeModalShown', 'true');
          }
        }
      }
    };

    // Debounce scroll events to avoid rapid state updates
    let timeoutId;
    const debouncedHandleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, 100);
    };

    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [subscribeModalShown]);


  const handleCloseSubscribeModal = () => {
    setShowSubscribeModal(false);
  };

  const handleManualSubscribeModalRecall = () => {
    setShowSubscribeModal(true);
    setSubscribeModalShown(true);
    localStorage.setItem('subscribeModalShown', 'true');
  };

  return (
    <div className='page-wrapper'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar variant='white' banner={true} />
      <main className="main-wrapper">
        <div className="home-hero_section">
          <div className="background-placeholder">
            <video className="background-video" autoPlay loop width='100%' height='100%' muted playsInline>
              <source src={`${process.env.PUBLIC_URL}/home_background.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <Container className="home-strapline">
            <Row style={{ paddingTop: '13vh' }}>
              <Col xxl={6} lg={6} md={8} sm={12}>
                <div className="hidden-on-mobile">
                  <TypingEffect />
                </div>
                <h1 className="highlight-text">
                  Harmonising data, powering a better built world.
                </h1>
                <Col xxl={8} md={12}>
                  <div className='mt-4'>
                    <p className='m-0 fs-5'>World Builders, we get it. Your data is complex, fragmented and ever-changing. Hoppa helps you translate it into actionable knowledge, fast.</p><br></br>
                    {/*<p>Transform unstructured data into insights, enhance productivity, and unlock the hidden value of your industry data.</p>*/}
                  </div>
                  <div className="mt-2 d-flex">
                    <button className="btn btn-secondary ms-0">
                      <ScrollLink spy to="why-hoppa">
                        Learn more
                      </ScrollLink>
                    </button>
                    <button className="btn btn-primary" onClick={() => setShowSpeakModal(true)}>
                      Speak to the team
                    </button>
                  </div>
                </Col>

              </Col>
            </Row>
          </Container>
        </div>




        <Container id='why-hoppa' style={{ paddingTop: '100px' }}>
          <div style={{ position: 'relative' }}>
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/red-cross.png`}
              sx={{
                width: { xs: '85px', md: '100px' }, // Fixed width for the image
                position: 'absolute',
                bottom: '8px',
                left: { xs: '46%', sm: '77%', md: '65%', lg: '61%', xl: '61%' }, // Responsive positioning
                transform: 'translateX(-50%)', // Center the image on its `left` position
              }}
            />
            <MainHeader
              title='Turn your data into knowledge...'
            />
            <MainHeader
              title='...in minutes, not months.'
            />
          </div>

          {/* <Chip label="Why hoppa" variant="outlined" className='ps-2 pe-2 mb-2 tag-selected' />*/}
          <Row style={{ paddingTop: '20px' }}>
            <ThreeStepProcess />
          </Row>
        </Container>

        <div style={{
          width: "100%",
          //height: "300px",
          background: "linear-gradient(to bottom, transparent 0%, ghostwhite 60%)"
        }}>

          <Container id="features" className='home-detail_section' style={{ paddingTop: '20px' }}>
            <div>
              <Col xs={12} lg={12}>
                <Capabilities />
              </Col>
            </div>
          </Container>



          <Container id='features' style={{ paddingTop: "30px", paddingBottom: '60px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Features />
            </div>
          </Container>
        </div>
        
        <div style={{ backgroundColor: 'ghostwhite', padding: '32px 0'}}>
        <Box style={{ backgroundColor: '#6f58ed', minHeight: '150px', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              maxWidth: '1320px',
              width: '90%',
              padding: { xs: '32px 2%', sm: '32px 10%' },
              margin: '0 auto',
              minHeight: '100%',
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column'
            }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%', display: 'flex', flexGrow: 1, alignItems: 'center' }}>
              <Box sx={{ minWidth: '60%', color: 'white' }}>
                <Typography variant='h6'>
                  Quiz us about AI-led information management, help us understand the dynamics of your project, or get a demo of one of our products 👍

                </Typography>
              </Box>
              <Box sx={{ minWidth: '50%', color: 'white', display: 'flex', justifyContent: 'center' }}>
                <button className='btn btn-secondary mt-3' onClick={() => setShowSpeakModal(true)}>
                  Speak to the team
                </button>
              </Box>
            </Stack>
          </Box>
        </Box>
        </div>

        <div style={{ backgroundColor: 'ghostwhite' }}>
          <Container className='home-detail_section' style={{ paddingTop: '100px' }}>
            <Row className='d-flex justify-content-center'>
              <MainHeader
                title="Where we excel"
                subtitle="We're built environment professionals who care deeply about our customers' challenges. We relish every opportunity to solve real-world problems in new & refreshingly effective ways."
              />
            </Row>
            <div className='padding-medium'>
              <Strengths />
            </div>
          </Container>

          <Container id="benefits" className='home-detail_section' style={{ paddingTop: '100px' }}>
            <Row className='d-flex justify-content-center'>
              <MainHeader
                style={{ paddingBottom: '20px' }}
                title="Eliminate your data headaches, today"
                subtitle="Whether it's bringing transformation initiatives within reach, wrestling unruly projects back under control, or transforming creaking spreadsheets into analytical gold, Hoppa is the enabler."
              />
            </Row>
            <div className='padding-medium'>
              <RoiCalculator />
            </div>
          </Container>

          <Container id="about" className="home-detail_section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '120px', paddingBottom: '8px' }}>
            <MainHeader
              title="Service options to suit your needs"
              subtitle="The built environment is rarely one-size-fits-all and making sense of data often requires a human touch. Our kit-of-parts approach can be tailored and deployed to suit a wide range of scenarios."
            />
            <div style={{ width: '100%', paddingTop: '50px' }}>
              <Deployment />
            </div>
          </Container>



          <div>
            <Container id="contact" className='home-detail_section'>
              <div className='padding-xhuge' style={{ justifyItems: 'center', paddingTop: '120px' }}>
                <MainHeader title='Got a question?' />
                <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', maxWidth: '700px' }}>
                  <p>Want to learn more about our solutions and approach? Reach out for a chat! Our team is passionate about exploring new ideas and sharing real-world insights on what works - and what doesn’t - when applying AI and other technologies in mission-critical business settings.</p>
                  <div style={{ justifyItems: 'center' }}>
                    <button className='btn btn-secondary mt-3' onClick={() => setShowSpeakModal(true)}>
                      Speak to the team
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </main>
      <div style={{ backgroundColor: 'ghostwhite', paddingTop: '1px' }}>
        <Popup
          show={showSpeakModal}
          onHide={() => setShowSpeakModal(false)}
          title="Chat to the team"
          image='labor-7576514_640.png'
          imageAlt='Get started'
          content={
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <RequestADemo sx={{ display: 'flex', flexGrow: 1 }} />
            </Box>

          }
        />
        <footer>
          <FooterBar />
        </footer>
      </div>

      <Overlay
        subscribeModalShown={subscribeModalShown}
        content={
          <>
            <h2>Subscribe to our mailing list</h2>
            <p>Receive regular updates from Hoppa HQ.</p>
            <Subscribe />
          </>
        } />
      <Popup
        show={showSubscribeModal}
        onHide={handleCloseSubscribeModal}
        image='labor-7576514_640.png'
        title='Join our mailing list'
        aria-labelledby="mailing-list-modal-title"
        aria-describedby="mailing-list-modal-description"
        content={
          <div >
            <p id="mailing-list-modal-description">Receive regular updates from Hoppa HQ.</p>
            <Subscribe />
          </div>
        }
        showCloseButton={false}
      >
      </Popup>
    </div >
  );
};
export default Home;
