import React, { useState } from 'react';
import { Link as ScrollLink } from "react-scroll";
import { Container } from 'react-bootstrap';
import FooterBar from '../subcomponents/shared/FooterBar';
import NavBar from './NavBar';
import RequestADemo from './RequestADemo';
import Popup from '../subcomponents/shared/Popup';
import Services from './Services';
import Persona from './Persona';
import { Chip, Avatar, Box, CardActionArea, Grid, Stack, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import MainHeader from './MainHeader';
import ArticleCard from '../subcomponents/shared/ArticleCard';
import { marked } from 'marked';
import { ArrowDownwardOutlined } from '@mui/icons-material';

const WhyHoppa = () => {

  const [showModal, setShowModal] = useState(false)
  const [expanded, setExpanded] = useState('');


  const differentiators = [
    {
      title: 'Outlook',
      body: "We bring a new mindset, encouraging our customers to think differently about their data and how different teams and functions can collaborate.",
      icon: "🧫"
    },
    {
      title: 'Capabilities',
      body: "Our cross-skilled team of industry practitioners and data scientists can deliver early results whilst training your teams to scale the solution.",
      icon: "💣"
    },
    {
      title: 'Tools',
      body: "Customers can license our kit-of-parts technology in ways that suit their needs, from self-hosted and SaaS through to custom build.",
      icon: "🎛️"
    }

  ]

  const faqs = [
    {
      question: "What is Hoppa?",
      answer: `Hoppa is the first-of-its-kind data refinery for the built environment, allowing you to cleanse, restructure and extract intelligence from whole-life asset data in a few clicks. 

Built using state-of-the-art AI, Hoppa’s technology augments your existing workflows as you manage your asset portfolio, gear up for construction handover, or ready for new regulation (e.g. ISO 19650). `
    },
    {
      question: "Who is Hoppa for?",
      answer: `Hoppa is for teams and individuals exchanging, receiving or using semi-structured data - like drawings, spreadsheets and PDFs - and wanting to do more.

Our customers come in all shapes and sizes, but have typically hit a ceiling with what they can achieve via traditional means. Hoppa unlocks the business case for knowledge management by being price and time competitive at a scale previously unattainable. Or, if you need to quickly estabish data governance (e.g. after a project or business takeover) then our trained team can deliver quick results whilst building your own capabilities alongside.`
    },
    {
      question: "Why choose Hoppa?",
      answer: `Our services enable you to tackle data challenges at scales you'd previously only dreamt of. 

We tailor our offering to suit your needs - anywhere from licensing our technology through to data enrichment services led by our team - freeing you up to focus on the steps that add most value to your business.`
    },
    {
      question: "Is Hoppa right for me?",
      answer: `Hoppa is ideal for customers with more data than they can reasonably manage themselves. if you're looking for a partner willing and able to share the responsibility for solving those challenges then we'd love to work together.`
    },
  ]

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box style={{
        backgroundColor: '#f0f8ff',
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 0
      }}
      >
        <NavBar variant='blue' fixed={true} />
        <Container>
          <Box sx={{ paddingTop: { xs: '50px', md: '120px' }, paddingBottom: '0px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <p className='blue-font xlarge-font bold-statement'>Your cheat code to better information management</p>
              <p style={{ maxWidth: '600px' }}>One core product, many applications, for a whole-lifecycle approach to knowledge management in the built environment.  </p>
              <Container>
                <Services />
              </Container>
              <Box className='pt-5' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className='blue-font large-font bold-statement'>Front-line teams love working with Hoppa</p>
                <p style={{ maxWidth: '600px' }}>Quality data + less admin = better decisions</p>
                <Persona />
              </Box>
              <Box className='pt-3' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <p className='blue-font large-font bold-statement'>Supporting you every step of the way</p>
                <p style={{ maxWidth: '600px' }}>We know that technology alone won’t deliver you the outcomes you need. That’s why we work alongside our customers to engineer solutions to their specific challenges.</p>

                <Box
                  sx={{
                    maxWidth: '1100px',
                    width: '100%',
                    padding: { xs: '0px 2%', sm: '0px 10%' },
                    margin: '0 auto',
                    minHeight: '100%',
                    flexDirection: 'column',
                    textAlign: 'center',
                    position: 'relative',
                    padding: '16px 0',
                    paddingBottom: '56px',
                    display: 'flex',         // Enables flexbox
                    flexDirection: 'column', // Ensures the content stacks vertically
                    alignItems: 'center',    // Centers horizontally
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      width: '140%',
                      height: '200%',
                      zIndex: -1,
                      background: 'radial-gradient(ellipse, rgba(110, 88, 237, 0.328) 0%, rgba(111,88,237,0) 50%)',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -47%)',
                      overflow: 'hidden'
                    }}
                  />
                  <Stack direction={{ xs: 'column', md: 'row', justifyContent: 'space-between', }}>
                    {differentiators.map((differentiator) => (
                      <Stack direction='column' sx={{ justifyContent: 'center', px: 3, width: '350px' }}>
                        <Typography variant='h3' display='block' className='mb-2 blue-font'>
                          {differentiator.icon}
                        </Typography>
                        <Typography variant='h5' display='block' className='mb-1 blue-font'>
                          {differentiator.title}
                        </Typography>
                        <Typography variant="body" display='block' className='mb-3'>
                          {differentiator.body}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              </Box>
            </div>
          </Box>
        </Container>
        <Box style={{ backgroundColor: '#6f58ed', margin: '64px 0', minHeight: '150px', display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              maxWidth: '1320px',
              width: '90%',
              padding: { xs: '32px 2%', sm: '32px 10%' },
              margin: '0 auto',
              minHeight: '100%',
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column'
            }}
          >
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ width: '100%', display: 'flex', flexGrow: 1, alignItems: 'center' }}>
              <Box sx={{ minWidth: '50%', color: 'white' }}>
                <Typography variant='h6'>
                  Quiz us about AI-led information management, help us understand the dynamics of your project, or get a demo of one of our products 👍

                </Typography>
              </Box>
              <Box sx={{ minWidth: '50%', color: 'white', display: 'flex', justifyContent: 'center' }}>
                <button className='btn btn-secondary mt-3' onClick={() => setShowModal(true)}>
                  Speak to the team
                </button>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box>
          <Container className='mt-5 mb-5 pb-5'>
            <MainHeader
              title='Solutions Resources'
            />
            <Grid
              container
              className='mt-1'
              justifyContent="center" // Align the grid items to the left
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Text to Uniclass Classifier"
                  subHeader="Need to tag your construction schedules, drawings and more to Uniclass? Input a snippet of your data and discover the matching code now for free!"
                  imageURL={`${process.env.PUBLIC_URL}/text-to-uniclass.png`}
                  tag='Free Tool'
                  capability='Data classification'
                  authorName='Hoppa'
                  authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                  publishedDate="Dec 05, '24"
                  linkURL="/text-to-uniclass"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Illustrative Use Case Pack"
                  subHeader="Discover the ways Hoppa supercharges teams day-to-day, and how much your business stands to save."
                  imageURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.png`}
                  tag='Use Cases'
                  duration='10-15 mins read'
                  authorName='Hoppa'
                  authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                  publishedDate="Sep 05, '24"
                  linkURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.pdf`}

                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box>
          <Container className='mt-5 mb-5'>
            <MainHeader
              title='Frequently Asked Questions'
            />
            <Box className='mt-3'>
              {faqs.map((faq, index) => (
                <>
                  <Accordion
                    onChange={handleChange(index)}
                    expanded={expanded === index}
                    sx={{
                      color: '#0051b6',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      fontSize: '16pt',
                      minHeight: '50px',
                      margin: '8px 0',
                      '&::before': {
                        backgroundColor: '#0051b6',
                      },
                      '&.Mui-expanded::before': {
                        opacity: 1
                      },
                    }}>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardOutlined sx={{ color: '#0051b6' }} />}
                      id={index}
                    >
                      {faq.question}
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        backgroundColor: 'transparent',
                        color: 'black',
                        fontSize: '12pt',
                        // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        borderRadius: '16px',
                        // padding: '32px 24px',
                      }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: marked(faq.answer),
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </>
              )
              )}
            </Box>
          </Container>
        </Box>
      </Box >
      <Popup
        show={showModal}
        onHide={() => setShowModal(false)}
        title="Chat to the team"
        image='labor-7576514_640.png'
        imageAlt='Get started'
        content={
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <RequestADemo sx={{ display: 'flex', flexGrow: 1 }} />
          </Box>

        }
      />


      <footer style={{ marginTop: 0 }}>
        <FooterBar />
      </footer>
    </>
  );
};

export default WhyHoppa;
