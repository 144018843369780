import React, { useState, useEffect } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import '../../../App.scss';

const Overlay = ({ subscribeModalShown }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isHidden, setIsHidden] = useState(true);
  
    useEffect(() => {
      const handleScroll = () => {
        const solutionsSection = document.getElementById('solutions');
        const footerSection = document.getElementById('contact');
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.body.scrollHeight;
  
        if (solutionsSection) {
          const rect = solutionsSection.getBoundingClientRect();
          const footerRect = footerSection ? footerSection.getBoundingClientRect() : null;
  
          if (
            rect.top <= windowHeight &&
            subscribeModalShown &&
            !(scrollPosition === 0 || (footerRect && footerRect.top <= windowHeight))
          ) {
            setIsHidden(false);
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [subscribeModalShown]);
  
    const handleClose = (e) => {
      e.stopPropagation();
      setIsVisible(false);
      setTimeout(() => setIsHidden(true), 300); // Match the duration of the slide-out animation
    };
  
    const handleScrollToSignUp = () => {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
      setIsVisible(false);
      setTimeout(() => setIsHidden(true), 300); // Match the duration of the slide-out animation
    };
  
    if (isHidden) {
      return null;
    }
  
    return (
      <div className={`overlay-container ${isVisible ? 'slide-in' : 'slide-out'}`} onClick={handleScrollToSignUp}>
        <div className="overlay-content">
          {/*<button className="overlay-close-button" onClick={handleClose}>&times;</button>*/}
          <FaEnvelope className="overlay-mail-icon" />
          <p>Stay informed</p>
        </div>
      </div>
    );
  };
  
  export default Overlay;