import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Chip from '@mui/material/Chip';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Strengths = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const customersData = [
    {
      id: 'translate',
      chipLabel: 'When information changes hands',
      title: 'Translate & Curate',
      description: "Making sense of data produced by someone else can feel like learning a new language. Discover how we smooth knowledge sharing between teams by acting as a translator.",
      linkUrl: 'https://example.com/case-study-1',
      image: `${process.env.PUBLIC_URL}/Facade_1.jpg`,
      gradient: 'linear-gradient(135deg, #4f5ff3c7, #fb91ffe0 100%)',
      pills: ['Handover', 'Mobilisation', 'Novation', 'Dispute Resolution'],
    },
    {
      id: 'governance',
      chipLabel: 'If data governance gets out of hand',
      title: 'Turn Back the Clock',
      description: "When information governance spirals out of control it can feel like there's no way to reinstate order. Learn how we turn back the clock for even the most complex projects.",
      linkUrl: 'https://example.com/case-study-2',
      image: `${process.env.PUBLIC_URL}/Facade_2.jpg`,
      gradient: 'linear-gradient(135deg, #9d6efbd9, #ffb88bd5)',
      pills: ['Reestablish Single Source of Truth', 'Realign Teams', 'Unify Multiple CDEs / Repos'],
    },
    {
      id: 'flex',
      chipLabel: 'As needs change over time',
      title: 'Flex to Suit New Needs',
      description: "We can't predict the future, but we can help when the unforeseen presents itself. See how we breathe life into legacy data as new needs arise.",
      linkUrl: 'https://example.com/case-study-3',
      image: `${process.env.PUBLIC_URL}/Facade_3.jpg`,
      gradient: 'linear-gradient(135deg, #7371fcd8 40%, #62ffd2c1)',
      pills: ['Enrichment', 'Normalisation', 'Dataset Joining', 'Integration'],
    }
  ];

  const [selectedTab, setSelectedTab] = useState(customersData[0].id);

  return (
    <div>
      {/* Navbar with Responsive Font & Wrapping */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        background: '#e6e6e689',
        padding: '2px 2px',
        borderRadius: '25px',
        width: 'fit-content',
        margin: '0 auto',
        flexWrap: 'wrap', // Allows wrapping on small screens
      }}>
        {customersData.map((item) => (
          <Chip
            key={item.id}
            label={item.chipLabel}
            onClick={() => setSelectedTab(item.id)}
            style={{
              backgroundColor: selectedTab === item.id ? '#ffffff' : 'transparent',
              color: selectedTab === item.id ? '#000000' : '#606060',
              cursor: 'pointer',
              margin: '6px', // Adjusted margin for better spacing
              padding: '8px 16px', // Slightly reduced padding
              fontSize: '16px', // Smaller text for smaller screens
              borderRadius: '20px',
              boxShadow: selectedTab === item.id ? '0 4px 6px rgba(0, 0, 0, 0.2)' : 'none',
              transition: 'box-shadow 0.3s ease',
              //width: '100%', // Ensures buttons take full width on small screens
              flexGrow: 1,
            }}
          />
        ))}
      </div>

      {customersData.map((customer) => (
        selectedTab === customer.id && (
          <Row key={customer.id} style={{ margin: '20px 0' }}>
            {/* Left Motion Div for Image */}
            <Col xs={12} md={4} style={{ padding: '0 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                style={{
                  width: '100%',
                  height: '100%',
                  display: isMobile ? 'none' : 'block', // Hide image on mobile
                }}
              >
                <img 
                  src={customer.image} 
                  alt="Customer" 
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    marginTop: '20px',
                    borderRadius: '25px'
                  }} 
                />
              </motion.div>
            </Col>

            {/* Right Motion Div for Text Content */}
            <Col xs={12} md={8} style={{ padding: '0 10px' }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
                style={{
                  background: customer.gradient,
                  color: '#ffffff',
                  padding: '20px',
                  borderRadius: '25px',
                  marginTop: '20px',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start', // Default to left alignment for large screens
                  textAlign: 'center',
                  '@media (maxWidth: 768px)': {
                    marginTop: '20px',  // Ensures spacing when stacked on medium/small screens
                    alignItems: 'center', // Centers everything on smaller screens
                  }
                }}
              >
                {/* Title Chip */}
                <div style={{
                  textAlign: 'left', 
                  width: '100%', 
                  marginBottom: '20px',
                  '@media (maxWidth: 768px)': {
                    textAlign: 'center', // Center chip text on small screens
                  }
                }}>
                  <Chip
                    label={customer.title}
                    style={{
                      backgroundColor: 'transparent',
                      color: 'white',
                      border: '1.5px solid white',
                      fontSize: '16px',
                      padding: '16px 16px',
                      borderRadius: '16px',
                    }}
                  />
                </div>

                {/* Description (Vertically Centered) */}
                <div style={{
                  flexGrow: 1, 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  width: '100%',
                  '@media (maxWidth: 768px)': {
                    textAlign: 'center', // Center text on small screens
                  }
                }}>
                  <p style={{ maxWidth: '700px', fontSize: '25px', margin: '0' }}>
                    {customer.description}
                  </p>
                </div>

                {/* Explore Button (Stuck to Bottom-Right, Centered on Small Screens) */}
                <div style={{
                  display: 'flex', 
                  justifyContent: 'flex-end', 
                  width: '100%', 
                  marginTop: '20px',
                  '@media (maxWidth: 768px)': {
                    justifyContent: 'center', // Center the button on smaller screens
                  }
                }}>
                   <button className='btn btn-secondary ms-0 mt-3'>
                    <a href="/solutions"  style={{ textDecoration: 'none', color: 'inherit' }}>Explore use cases</a>
                  </button>
                </div>
              </motion.div>
            </Col>
          </Row>
        )
      ))}
    </div>
  );
};

export default Strengths;
