import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';

const RenameFiles = ({ onClose, onDownload }) => {
  const [advancedMode, setAdvancedMode] = useState(false);
  const [codeSettings, setCodeSettings] = useState({
    documentSeparator: '-',
    numberingSystem: {
      digits: 6,
    },
    advancedMode: "",
  });

  const handleDownload = () => {
    onDownload(codeSettings);
    onClose();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setCodeSettings((prevSettings) => {
      if (name === 'numberingSystem') {
        return {
          ...prevSettings,
          [name]: {
            ...prevSettings[name],
            digits: type === 'button' ? (checked ? prevSettings[name].digits + 1 : prevSettings[name].digits - 1) : value,
          },
        };
      } else {
        return {
          ...prevSettings,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
    });
  };

  const toggleAdvancedMode = () => {
    setAdvancedMode(!advancedMode);
    setCodeSettings((prevSettings) => ({
      ...prevSettings,
      advancedMode: null, // Reset advancedMode value if toggled off
    }));
  };

  return (
    <Modal show={true} onHide={onClose} centered className="scrollable-modal">
      <Modal.Header closeButton>
        <Modal.Title>Document Renaming Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body className='scrollable-modal-body'>
        <Form>
          <Form.Group controlId="advancedMode">
            <Form.Check
              reverse
              type="switch"
              id="custom-switch"
              label="Advanced Mode"
              onChange={toggleAdvancedMode}
              checked={advancedMode}
            />
          </Form.Group>
          
          <Form.Group controlId="documentSeparator">
            <Form.Label>Document Code Separator</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter separator"
              name="documentSeparator"
              value={codeSettings.documentSeparator}
              onChange={handleInputChange}
              disabled={advancedMode}
            />
          </Form.Group>

          <Form.Group controlId="numberingSystem">
            <Form.Label>Sequential Number Digit Length</Form.Label>
            <InputGroup>
              <FormControl
                type="text"
                placeholder="Enter number of digits"
                name="numberingSystem"
                value={codeSettings.numberingSystem.digits}
                readOnly
              />
              <Button
                variant="outline-secondary"
                name="numberingSystem"
                onClick={() => handleInputChange({ target: { name: 'numberingSystem', type: 'button', checked: false } })}
              >
                -
              </Button>
              <Button
                variant="outline-secondary"
                name="numberingSystem"
                onClick={() => handleInputChange({ target: { name: 'numberingSystem', type: 'button', checked: true } })}
              >
                +
              </Button>
            </InputGroup>
          </Form.Group>

          {advancedMode && (
            <Form.Group controlId="namingSyntax">
              <Form.Label>Naming Syntax</Form.Label>
              {/* Tooltip icon for help next to the label */}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    Code fields should be contained in curly braces (`&#123;`, `&#125;`) e.g.
                    &#123;Discipline&#125;-&#123;Functional Breakdown&#125;-&#123;Location Part 1&#125;_&#123;Location Part 2&#125;-&#123;Role&#125;.
                  </Tooltip>
                }
              >
                {/* Ensure the icon is wrapped in a single element */}
                <span style={{ marginLeft: '8px', cursor: 'pointer' }}>ℹ️</span>
              </OverlayTrigger>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter syntax"
                name="advancedMode"
                value={codeSettings.advancedMode}
                onChange={handleInputChange}
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleDownload}>
          Rename
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RenameFiles;
