import React, { useState } from 'react';
import { Box, Typography, Chip, Grid } from '@mui/material';

const Capabilities = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    "Lift the hood on your data",
    "Structure to suit any standard",
    "Retrieve knowledge at scale",
    "Connect your apps & repos",
  ];

  const videos = [
    "/Get_Under_Hood_Data_HiRes.mp4",
    "/Structure_Suit_Any_Standard_HiRes.mp4",
    "/Retrieve_Knowledge_Scale_HiRes.mp4",
    "/Connect_Apps_Repos_HiRes.mp4",
  ];

  const descriptions = [
    "Bulk analyse, classify, tag, query and more - all without ever having to open a single file.",
    "From simple naming conventions to complex ontologies, a method that extends as needs evolve.",
    "From no-code data processing to bespoke pipelines that integrate with your existing workflows.",
    "Connect directly to your data repositories to process files, folders and even entire libraries."
  ];

  return (
    <Box sx={{ width: '100%', typography: 'body1', mt: 4, textAlign: 'center' }}>
      {/* Navbar */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '2px 2px',
          borderRadius: '25px',
          width: 'fit-content',
          margin: '0 auto',
          marginBottom: '30px',
          flexWrap: 'wrap',
          background: '#e6e6e689',
          paddingRight: '12px',
        }}
      >
        <Grid container spacing={1} justifyContent="center" sx={{ width: '100%' }}>
          {tabs.map((tab, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <Chip
                label={tab}
                onClick={() => setSelectedTab(index)}
                sx={{
                  backgroundColor: selectedTab === index ? '#ffffff' : 'transparent',
                  color: selectedTab === index ? '#000000' : '#606060',
                  cursor: 'pointer',
                  margin: '6px',
                  padding: '8px 16px',
                  minWidth: '270px',
                  fontSize: { xs: '16px', sm: '16px', md: '16px' },
                  borderRadius: '20px',
                  boxShadow: selectedTab === index ? '0 4px 6px rgba(0, 0, 0, 0.2)' : 'none',
                  transition: 'box-shadow 0.3s ease',
                  width: '100%',
                  flexGrow: 1,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Description */}
      <Typography 
        variant="h6" 
        sx={{ 
          maxWidth: '1000px', 
          margin: '0 auto 20px auto', 
          padding: '0 20px', 
          fontWeight: 500 
        }}
      >
        {descriptions[selectedTab]}
      </Typography>

      {/* Video Content */}
      <Box>

        <Typography component="div">
          <video
            key={selectedTab} // Forces React to re-render video on tab change
            width="100%"
            autoPlay
            loop
            muted
            playsInline
            disableRemotePlayback
            style={{ borderRadius: '28px', border: '10px solid #c1c1c141' }}
          >
            <source src={videos[selectedTab]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Typography>
      </Box>
    </Box>
  );
};

export default Capabilities;
