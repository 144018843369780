import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faSliders } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Stack, Typography, Box } from '@mui/material';
import { useParams } from "react-router-dom";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

function ImportFromCSV({ sessionId, triggerAlert, handleSetFileCount, allFiles, handleSetSourceFiles, handleUpdateDataSource, rowCount, setRowCount, previewRows, setPreviewRows }) {
    const { currentOrg, workspace } = useParams(); // get organisation and workspace from URL
    const [isUploading, setIsUploading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);

    useEffect(() => {
        console.log(previewRows)
        if (previewRows && previewRows.length > 0) {
            setTableHeaders(Object.keys(previewRows[0]))
        }
    }, [previewRows]);

    const handleDeleteAllRows = () => {

        console.log('Attemping to delete all uploaded files to session')

        setIsUploading(true)

        // Else continue with post to Fuction app. 
        const headers = {
            'Content-Type': 'application/json', // Set the Content-Type header to JSON
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
            // Add any other headers as needed
        };

        // Send POST request to backend API for file deletion
        axios.post(`${API_BASE_URL}/api/DeleteAllFilesTrigger`, {
            organisation: currentOrg, workspace: workspace, sessionId: `${sessionId}/csv`
        }, { headers }
        )
            .then((response) => {
                console.log("Delete successful. Server response:", response.data);
                handleUpdateDataSource('Import table from CSV', 'Import table from CSV', [])
                setPreviewRows([])
                setRowCount(0)
                setTableHeaders([])
                setIsUploading(false)
                triggerAlert('Row delete successful.')
            }
            )
            .catch((error) => {
                console.error("Error during delete. Server response:", error);
                triggerAlert('Error during delete. Please refresh the page', 'error');
            })
    }

    const fileUpload = async (file) => {
        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('organisation', currentOrg);
        formData.append('workspace', workspace);
        formData.append('sessionId', sessionId);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY,
            }
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/api/CSVSourceRead`, formData, config);
            console.log("Upload successful. Server response:", response.data);

            setPreviewRows(response.data.rows)
            setTableHeaders(Object.keys(response.data.rows[0]))
            setRowCount(response.data.count)

            console.log(file)

            const fileEntry = {
                name: file.name,
                lastModifiedDate: file.lastModifiedDate,
                size: file.size,
                status: 'new'
            }

            handleUpdateDataSource('Import table from CSV', 'Import table from CSV', [fileEntry])

            triggerAlert('Content extracted from CSV')

        } catch (error) {
            console.error("Error during upload:", error);
            triggerAlert('Content extraction failed', 'error')
        } finally {
            setIsUploading(false)
        }
    };
    const traverseFileTree = async (item, updatedFiles, parentFolderName = '') => {
        if (item.isFile) {
            const file = await new Promise((resolve) => item.file(resolve));
            updatedFiles.push({ file, folder: parentFolderName });
        } else if (item.isDirectory) {
            const directoryReader = item.createReader();
            const folderName = parentFolderName ? `${parentFolderName}/${item.name}` : item.name;

            const entries = await new Promise((resolve) => directoryReader.readEntries(resolve));

            for (let j = 0; j < entries.length; j++) {
                await traverseFileTree(entries[j], updatedFiles, folderName);
            }
        }
    };

    const handleFileChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0]; // Get the first file selected
        fileUpload(file);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const droppedItems = e.dataTransfer.items;
        const updatedFiles = [];

        for (let i = 0; i < droppedItems.length; i++) {
            const item = droppedItems[i].webkitGetAsEntry();

            if (item) {
                await traverseFileTree(item, updatedFiles);
            }
        }
        fileUpload(updatedFiles);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const columns = tableHeaders?.map((header) => ({
        accessorKey: header,  // Set the column's data field
        header: header,       // The header text for the column
        Cell: ({ cell }) => (
            <span
                style={{
                    display: 'inline-block',
                    maxWidth: '100%', // Ensures the cell content respects cell width
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'top',
                    padding: 0,
                    margin: 0
                }}
            >
                {cell.getValue()}
            </span>
        ),
    }));


    return (
        <>
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    {/* Title */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Configure table import
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{
                        alignItems: "center",
                    }}>
                        <button className='btn btn-secondary' disabled={rowCount === 0} onClick={() => handleDeleteAllRows()}>
                            Clear all
                        </button>
                    </Stack>
                </Box>
            </div>
            <div style={{ display: 'flex', flexGrow: 1, width: '100%', marginBottom: '60px' }}>
                {previewRows?.length === 0 || columns?.length === 0 ?
                    <div
                        className="dashed-box mt-0"
                        style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    >
                        {isUploading ?
                            (
                                <div style={{ textAlign: 'center' }}>
                                    <CircularProgress />
                                    <p>Uploading file</p>
                                </div>
                            )
                            : (
                                <div className="file-upload-drop-area" style={{ width: '100%' }}>
                                    <FontAwesomeIcon icon={faCloudArrowUp} className="fa-3x mb-2" style={{ color: 'silver' }} />
                                    <p>
                                        Drag and drop a file here or{' '}
                                        <label htmlFor="file-upload-input" className="file-upload-label">
                                            <u>choose files</u>
                                        </label>
                                    </p>
                                    <input
                                        key='csv-uploader' // Reset the key whenever uploadedFiles change
                                        type="file"
                                        id="file-upload-input"
                                        className="file-upload-input hidden"
                                        onChange={handleFileChange}
                                        accept=".csv"
                                    />
                                </div>
                            )}

                    </div>
                    :
                    <div style={{ width: '100%', overflowX: 'auto' }}>
                        <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px', marginBottom: '8px' }}>
                            Preview
                        </Typography>
                        <MaterialReactTable
              columns={columns}
              data={previewRows} // Pass the rows directly
              initialState={{ density: 'compact' }}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              enableRowActions={false}
              enableSorting={false}
              layoutMode="grid-no-grow"
              muiTableBodyRowProps={{ hover: false }}
              muiTableBodyCellProps={{
                style: { padding: '0.6rem 0.5rem' }
              }}
            />
                        <div style={{ marginTop: '8px' }}>
                            {rowCount > 3 ? `...and ${rowCount - 3} more row(s)` : ''}
                            
                        </div>
                    </div>
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isUploading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    )
}

export default ImportFromCSV;