import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { Chip, Stack, Box, Typography, Container, Grid, Fade, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowDownwardOutlined, Check, ExpandMore } from '@mui/icons-material';


const services = [
  {
    title: 'Data Discovery',
    description: 'Cleanse and catalogue document repositories to find answers in a flash.',
    details: [
      "Connect directly to your document repos or upload direct.",
      "Set classification standards or define your own.",
      "Ask natural language questions for smart document insights.",
      "Transparent & trustful AI that shows you its thinking."
    ],
    example: (
      <>
        <Box
        >
          <Typography variant="h6" fontWeight="bold" display='block' className='mb-2'>
            Example: Large Asset Owner
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            A national infrastructure company holds 30 years of asset data across multiple formats and locations. Critical facts are buried in outdated file structures, making it impossible to retrieve essential information efficiently.
          </Typography>
          <Typography variant="body" ddisplay='block' className='mb-2'>
            By using Hoppa's Data Discovery services, they automatically classify their entire archive, apply industry standards (e.g., ISO19650), and enable instant natural language search—allowing engineers, maintenance teams and facility managers to find the right information in minutes, not months.
          </Typography>
        </Box>
      </>
    ),
    color: '#4A4BD6',
    tags: ['📄 Document Cracking', '🔍 Search & Query', '💡 LLM Generated Insights'],
    textColor: '#fff',
    pillTextColor: '#fff', // White text for blue card pills
  },
  {
    title: 'Data Integration',
    description: 'Join disparate datasets to create new value.',
    details: [
      "Auto-generate index keys for structured data.",
      "Link fragmented datasets to slice data in new ways.",
      "Reduce manual data wrangling & accelerate time-to-value."
    ],
    example: (
      <>
        <Box
        >
          <Typography variant="h6" fontWeight="bold" display='block' className='mb-2'>
            Example: Design Consultancy & Carbon Calculations
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            A design consultancy is working on a major infrastructure project and needs to connect a multi-million row materials database with a carbon factors database to enable automated carbon calculations.
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            Without automation, this would require hundreds of hours of manual data wrangling.
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            Using Hoppa's Data Indexing & Joining services, they seamlessly match materials to carbon factors, creating a structured dataset that allows for instant emissions analysis - saving time and ensuring accuracy.
          </Typography>
        </Box>
      </>
    ),
    color: '#FF8962',
    tags: ['🔗 Data Join', '🛠 Tools', '📊 Analytics'],
    textColor: '#000000', // Black text for orange card
    pillTextColor: '#000', // Black text for orange card pills
  },
  {
    title: 'Data Migration',
    description: 'Smooth data transfer between CDEs, IT systems & organisations.',
    details: [
      "Enable smooth & accurate data migration between firms.",
      "Automatically translate and reclassify data as needed.",
      "Minimise system incompatibility and boost collaboration.",
      "Augment document control and streamline governance."
    ],
    example: (
      <>
        <Box
        >
          <Typography variant="h6" fontWeight="bold" display='block' className='mb-2'>
            Example: Construction Firm Preparing for Handover
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            A construction firm is preparing to handover a major project to the client but faces data inconsistency between their internal systems and the client’s Common Data Environment (CDE).
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            The problem is compounded by their large supply chain of subcontractors, who have all submitted information in different formats and to different naming conventions. Without automation, manual data reclassification would take weeks of effort.
          </Typography>
          <Typography variant="body" display='block' className='mb-2'>
            Using Hoppa's Data Migration services, they automatically reformat, classify, relabel, catalogue and validate all project files, ensuring a seamless, compliant handover without delays.
          </Typography>
        </Box>
      </>
    ),
    color: '#9371D5',
    tags: ['🔄 Transfer', '💾 Systems Integration', '✅ Cross-Platform Accuracy'],
    textColor: '#fff',
    pillTextColor: '#fff', // White text for purple card pills
  },
  {
    title: 'Data Reuse',
    description: 'Transform legacy data to make it fit for modern uses e.g. Gen-AI & Digital Twin.',
    details: [
      "Turn unstructured legacy data into structured insights.",
      "Prepare legacy data for modern transformation efforts.",
      "Maximise the value of existing data with automated tools.",
      "Flexibly reshape data to suit emergent needs."
    ],
    example: (
      <>
        <Box
        >
          <Typography fontWeight="bold" display='block' className='mb-2'>
            Example: Engineering Firm Adopting AI & Digital Twins
          </Typography>
          <Typography display='block' className='mb-2'>
            An engineering firm has decades of technical reports, PDFs, and CAD files spread across different projects.
          </Typography>
          <Typography display='block' className='mb-2'>
            To integrate AI-driven analytics and other transformative technology, they need to extract structured data from their unstructured archives. Without automated support, teams would be forced to manually open documents, interpret their contents and extract key information one page at a time. The added cost of this pre-processing step in many instances is enough to render the business case unworkable.
          </Typography>
          <Typography display='block' className='mb-2'>
            Using Hoppa's Data Reuse services, they rapidly process and extract key features from historic documents into AI-ready formats, enabling advanced simulations, predictive maintenance, and smarter asset management.
          </Typography>
        </Box>
      </>
    ),
    color: '#80FFD8',
    tags: ['⛏️ Data Mining', '🤖 Transformation Ready', '🏙️ Feature Engineering'],
    textColor: '#000', // Black text for turquoise card
    pillTextColor: '#000', // Black text for turquoise card pills
  },
];

const Services = () => {
  const [flipped, setFlipped] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [fadeKey, setFadeKey] = useState(0);

  const handleServiceClick = (index) => {
    if (selectedService === index) {
      setFlipped(!flipped);
      setSelectedService(null);
    } else {
      setFlipped(true);
      setSelectedService(index);
      setFadeKey((prev) => prev + 1);
    }
  };

  return (
    <Container style={{marginBottom: '32px', marginTop: '32px', minHeight: '515px'}}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{minHeight: '400px', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
          <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
            <Box
              key="front"
              sx={{
                width: '100%',
                maxWidth: '500px',
                
                height: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <Box sx={{
                position: 'absolute',
                width: '140%',
                height: '200%',
                zIndex: -1,
                background: 'radial-gradient(ellipse, rgba(110, 88, 237, 0.328) 0%, rgba(111,88,237,0) 50%)',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -47%)',
                overflow: 'hidden'
              }}/>
                <Box
                  component="img"
                  sx={{
                    maxWidth: {xs:'100%', md: '120%'}, // Ensures responsive width
                    maxHeight: {xs: '370px', sm: '400px', md: '400px'},
                    transition: '0.3s',
                    background: 'radial-gradient(ellipse, rgba(110, 88, 237, 0.328) 0%, rgba(111,88,237,0) 50%)',
                  }}
                  src={`${process.env.PUBLIC_URL}/Hoppa_Service_Wheel.png`}
                  alt="Hoppa Service Wheel"
                />
              </Box>
            <Box
              key="back"
              sx={{
                width: '100%',
                maxWidth: '470px',
                maxHeight: {xs: '370px', md: '500px'},
                aspectRatio: { xs: 0.90, sm: 1 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                borderRadius: 10,
                backgroundColor: selectedService !== null ? services[selectedService].color : '#fff',
                color: services[selectedService]?.textColor || '#fff',
                px: 1,
                py: 1,
                transition: 'background-color 0.5s ease-in-out',
              }}
            >
              <Fade key={fadeKey} in timeout={500} onClick={() => handleServiceClick(selectedService)}>
                <Box sx={{ maxHeight: '100%' }}>
                  {selectedService !== null && (
                    <>
                      <Box
                        sx={{
                          overflowY: 'auto', // Enables scrolling
                          maxHeight: '100%',
                          scrollbarWidth: 'thin', // For Firefox
                          scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent', // Thin white scrollbar

                          '&::-webkit-scrollbar': {
                            width: '5px', // Makes scrollbar thin
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'rgba(255, 255, 255, 0.6)', // White scrollbar thumb
                            borderRadius: '10px', // Rounded edges
                          },
                          '&::-webkit-scrollbar-track': {
                            background: 'transparent', // Hides scrollbar track
                          },
                          p: 2,
                          // backgroundColor: 'rgba(255, 255, 255, 0.15)',
                          borderRadius: 2,
                          textAlign: 'left',
                          // borderLeft: '4px solid #fff',
                        }}>
                        {services[selectedService].example}
                      </Box>
                    </>
                  )}
                </Box>
              </Fade>
            </Box>
          </ReactCardFlip>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              maxHeight: {xs:'auto', md: '480px'},
              overflowY: 'auto',
              width: '100%',
              scrollbarWidth: 'thin', // For Firefox
              overflowX: 'hidden',
              paddingRight: '8px',
            }}
          >
            {services.map((service, index) => (
              <>
                <Accordion
                  onChange={() => handleServiceClick(index)}
                  expanded={selectedService === index}
                  slotProps={{ transition: { timeout: 600 } }}

                  sx={{
                    color: '#0051b6',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    fontSize: '16pt',
                    minHeight: '50px',
                    margin: '8px 0',
                    '&::before': {
                      backgroundColor: '#0051b6',
                    },
                    '&.MuiAccordionSummary-content.Mui-expanded': {
                      marginBottom: '0px'
                    },
                    '&.Mui-expanded::before': {
                      opacity: 1
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardOutlined sx={{ color: '#0051b6' }} />}
                    id={index}
                    sx={{ p: 0 }}
                  >
                    <Stack
                      direction='row'
                      sx={{ textAlign: 'left', alignItems: 'center' }}
                    >
                      <Box
                        sx={{
                          width: 20,
                          minWidth: 20,
                          height: 20,
                          borderRadius: '50%',
                          backgroundColor: service.color,
                          mr: 2,
                        }}
                      />
                      <Stack direction='column'>
                        <Typography variant="h6" fontWeight="bold">
                          {service.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {service.description}
                        </Typography>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className='pt-0'>
                    <>
                      <List className='pt-0'>
                        {service.details.map((detail, index) => (
                          <ListItem disableGutters
                            sx={{
                              '&.MuiListItem-root': {
                                width: '100%'
                              }
                            }}>
                            <ListItemIcon sx={{ width: '100%' }}>
                              <Check className='me-2' sx={{ alignSelf: 'center' }} />
                              <ListItemText>
                                <Typography>
                                  {detail}
                                </Typography>
                              </ListItemText>
                            </ListItemIcon>
                          </ListItem>
                        ))}
                      </List>
                      <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: '16px', flexWrap: 'wrap' }}>
                        {service.tags.map((tag, idx) => (
                          <Chip label={tag} variant='outlined' sx={{ border: '1px solid black', marginRight: '8px', marginBottom: '8px' }} />
                        ))}
                      </Box>
                    </>
                  </AccordionDetails>
                </Accordion>
              </>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Services;
