import React from 'react';
import { Container, Card } from 'react-bootstrap';
import FooterBar from '../subcomponents/shared/FooterBar';
import NavBar from './NavBar';
import { Chip, Avatar, Box, CardActionArea, Grid, Stack, Typography } from '@mui/material';
import ArticleCard from '../subcomponents/shared/ArticleCard';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const About = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 50, // Space on both sides
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 50, // Space on both sides
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <>
      <Box style={{
        backgroundColor: '#f0f8ff',
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 0
      }}
      >
        <NavBar variant='blue' fixed={true} />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            minHeight: '600px',
            height: '100%',
            maxHeight: '100vw',
            zIndex: -1,
            backgroundImage: `url(${process.env.PUBLIC_URL}/purple-haze.png)`,
            backgroundSize: { xs: '500px', md: '200vw' }, // Ensures the image covers the entire area
            backgroundPosition: { xs: 'top 0% left 50%', md: 'top 90% right -125vw' },
            backgroundRepeat: 'no-repeat', // Prevents tiling of the image
          }}
        ></Box>
        <Box sx={{
          maxWidth: '1320px',
          width: '90%',
          padding: '0 2%',
          paddingTop: { xs: '100px', sm: '120px' },
          paddingBottom: '0px',
          display: 'flex',         // Enables flexbox
          flexDirection: 'column', // Ensures the content stacks vertically
          alignItems: 'center',    // Centers horizontally
          margin: '0 auto',        // Horizontally center the box
        }}>
          <Stack
            spacing={4}
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Chip
                label='About'
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #0051b6',
                  color: '#0051b6',
                  m: '8px 0'
                }}
              />
              <p className='blue-font xlarge-font bold-statement'>
                Future-proofing our built world
              </p>
              <Typography sx={{ width: { xs: '100%', sm: '70%' } }}>
                Creating and improving the built world is important and complex work. We're a passionate team of industry experts and technology practitioners, commmited to supporting the builders of today and enabling the builders of tomorrow.
              </Typography>
              <Stack direction={{xs:'column', sm:'row'}} sx={{ gap: 1, margin: '8px 0', alignItems: { xs: 'flex-start', sm: 'center' } }}>
                <button className='btn btn-primary ms-0'>
                  <a href="/product" style={{ textDecoration: 'none', color: 'inherit' }}>Explore the product</a>
                </button>
                <button className='btn btn-secondary ms-0'>
                  <a href="/contact" style={{ textDecoration: 'none', color: 'inherit' }}>Speak to the team</a>
                </button>
              </Stack>

            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <Box
                component="img"
                sx={{
                  maxWidth: { xs: '450px', md: '1000px' },
                  width: { xs: '100%', md: '120%' },
                  display: 'block',
                  marginLeft: { xs: '-3%', sm: '10%' }, // Shifts image to the left on xs to centre the main screenshot
                }}
                src={`${process.env.PUBLIC_URL}/Hoppa_World.svg`}
                alt="Product Hero"
              />
            </Box>
          </Stack>
        </Box>

        <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
          <Stack style={{ maxWidth: '900px', alignItems: 'center', marginTop: '24px' }}>
            <Typography variant='h1' className='blue mt-4' sx={{ fontSize: '2rem', lineHeight: 0.9, marginBottom: '16px' }}>
              Our story
            </Typography>
          </Stack>
        </div>
        <Stack sx={{ margin: '16px 16px', justifyContent: 'center' }} direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 1, sm: 2 }}>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '16px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardActionArea style={{ padding: '16px', }} onClick={() => { window.open('https://www.linkedin.com/in/sam-rees-9062487a/', '_blank', 'noopener,noreferrer') }}>
              <Stack direction='row'>
                <Avatar
                  alt='Sam Rees'
                  src={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                  sx={{ width: 80, height: 80 }}
                />
                <Stack style={{ marginLeft: '8px', justifyContent: 'center', minWidth: '250px' }}>
                  <Typography
                    variant="body1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Sam Rees
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: '#9ca3af',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    CEO & Co-Founder
                  </Typography>
                </Stack>
              </Stack>
              <img
                alt='LinkedIn Logo'
                src={`${process.env.PUBLIC_URL}/LI-In-Bug.png`}
                style={{
                  position: 'absolute',
                  right: '12px',
                  bottom: '16px',
                  height: '24px'
                }} />
            </CardActionArea>
          </Card>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '16px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <CardActionArea style={{ padding: '16px', }} onClick={() => { window.open('https://www.linkedin.com/in/tom-goldsmith', '_blank', 'noopener,noreferrer') }}>
              <Stack direction='row'>
                <Avatar
                  alt='Tom Goldsmith'
                  src={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                  sx={{ width: 80, height: 80 }}
                />
                <Stack style={{ marginLeft: '8px', justifyContent: 'center', minWidth: '250px' }}>
                  <Typography
                    variant="body1"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Tom Goldsmith
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: '#9ca3af',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    CTO & Co-Founder
                  </Typography>
                </Stack>
              </Stack>
              <img
                alt='LinkedIn Logo'
                src={`${process.env.PUBLIC_URL}/LI-In-Bug.png`}
                style={{
                  position: 'absolute',
                  right: '12px',
                  bottom: '16px',
                  height: '24px'
                }} />
            </CardActionArea>
          </Card>
        </Stack>
        <Box sx={{
          maxWidth: '1329px',
          width: '90%',
          padding: '0 2%',
          paddingTop: '16px',
          paddingBottom: '0px',
          margin: '0 auto',        // Horizontally center the box
        }}>
          <Stack
            direction={{ sm: 'column-reverse', md: 'row' }}
            sx={{ justifyContent: 'center', alignItems: 'start', gap: 3 }}
          >
            <Box sx={{
              width: { sm: '50%', md: '40%' }, // Ensures responsive width
              textAlign: 'left'
            }}>
              <Typography className='pb-2'>
                Long-term friends & collaborators, Sam & Tom met as Civil Engineers working on some of the world's most exciting & complex projects, from bridges & railways to advanced manufacturing & nuclear power stations.
              </Typography>
              <Typography className='pb-2'>
              Everywhere they looked, well-managed information that could be readily turned back into actionable knowledge was what set the best projects apart, but teams were often stretched to their limits. More data was being produced than ever, and people & process weren’t enough to turn overload into outcomes.
              </Typography>
              <Typography className='pb-2'>
                For Sam & Tom, re-imagining the industry’s data backbone became a shared mission. Over the years the founders each honed their craft as AECO technology specialists at global corporates through to growing start-ups, before launching Hoppa as their first joint venture.
              </Typography>
              <Typography className='pb-2'>
                Hoppa is built from the ground-up by the founders, delivering the flexible, scaled knowledge management solutions their former selves wanted to use. As society reshapes the physical world, Hoppa ensures the digital asset keeps pace, safeguarding the built environment & the people who build, operate and benefit from it for generations to come.
              </Typography>
            </Box>
            <Box
              component="img"
              sx={{
                width: { xs: '100%', md: '40%' }, // Ensures 
                aspectRatio: 1,
                objectFit: 'contain',
                borderRadius: '16px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

              }}
              src={`${process.env.PUBLIC_URL}/Hoppa_Founders_Square_Edited.jpg`}
            />
          </Stack>
        </Box>

        <Container className='mt-5 mb-5'>
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
            <Stack style={{ maxWidth: '900px', alignItems: 'center' }}>
              <Typography variant='h1' className='blue' sx={{ fontSize: '2rem', lineHeight: 0.9, marginBottom: '16px' }}>
                Resource Centre
              </Typography>
              <Typography variant='body1' style={{ maxWidth: '700px', marginBottom: '12px' }}>
                Things that excite, move or inspire us that we'd like to share with you.
              </Typography>
            </Stack>
          </div>
          <Carousel
            responsive={responsive}
            containerClass="carousel-container-padding-40-px"
            showDots={true}
            renderArrowsWhenDisabled={true}
          >
            <div className='p-2'>
              <ArticleCard
                header="Tacoma Narrows: Are there more than just engineering lessons to be learnt?"
                subHeader="Tacoma Narrows popped into my head this week. Before you ask whether this is the engineer's equivalent of the Roman Empire meme, I'll start by saying it's not something I've given much thought to since my university days."
                imageURL="https://media.licdn.com/dms/image/v2/D4E12AQGjgEa74hfMXg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1738514084146?e=1744243200&v=beta&t=p0sXzf4b9rNeEipZGK1EdSTlpvXon8-6Dgy-TI5x6XY"
                tag='Article'
                duration='3-4 mins read'
                authorName='Tom Goldsmith'
                authorImageURL={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                publishedDate="Feb 02, '25"
                linkURL="https://www.linkedin.com/pulse/tacoma-narrows-more-than-just-engineering-lessons-learnt-goldsmith-4xdge"
              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Meta-Morphosis: How Metadata Transforms our AI Outlook"
                subHeader="Why 'data about data' holds the key to successfully harnessing multi-modal artificial intelligence, and a peek at how construction and asset management specialists could be working with AI tomorrow."
                imageURL="https://media.licdn.com/dms/image/v2/D4E12AQFjqWkuzMAspQ/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1736853420154?e=1744243200&v=beta&t=X_O6sx4gpWENYhBc4Af-mG52xR2ocuYv2RyQs_1wG0g"
                tag='Article'
                duration='8-10 mins read'
                authorName='Tom Goldsmith'
                authorImageURL={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                publishedDate="Jan 14, '25"
                linkURL="https://www.linkedin.com/pulse/meta-morphosis-how-metadata-transforms-our-ai-outlook-tom-goldsmith-b4cee/"
              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Knowledge management in the AI era: treading a fine line between hope & hype"
                subHeader="A brief holiday respite, a chance to think critically about some of the most talked-about tech, and how it could be used to best effect. Thoughts, analysis, hands-on tinkering with GraphRAG, and more."
                imageURL="https://substackcdn.com/image/fetch/w_1272,h_847,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_auto/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2Fad887565-fcf2-44ff-9b20-18d9972c0fd3_600x611.jpeg"
                tag='Article'
                duration='10-15 mins read'
                authorName='Sam Rees'
                authorImageURL={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                publishedDate="Jan 07, '25"
                linkURL="https://samrees.substack.com/p/knowledge-management-in-the-ai-era"
              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Text to Uniclass Classifier"
                subHeader="Need to tag your construction schedules, drawings and more to Uniclass? Input a snippet of your data and discover the matching code now for free!"
                imageURL={`${process.env.PUBLIC_URL}/text-to-uniclass.png`}
                tag='Free Tool'
                capability='Data classification'
                authorName='Hoppa'
                authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                publishedDate="Dec 05, '24"
                linkURL="/text-to-uniclass"

              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Four ways to future-proof your built asset data"
                subHeader="Why productivity gains are the tip of the iceberg when it comes to built asset data strategy, and what new technology could mean for forward-looking asset managers."
                imageURL={`${process.env.PUBLIC_URL}/building-279769_640.png`}
                tag='Article'
                duration='4-5 mins read'
                authorName='Tom Goldsmith'
                authorImageURL={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                publishedDate="Oct 30, '24"
                linkURL="https://www.linkedin.com/pulse/four-ways-future-proof-your-built-asset-data-tom-goldsmith-unskf/?trackingId=Gb%2FAl%2F%2BsqQYZ4IfVqrBn6Q%3D%3D"

              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Paying Down the Data Debt: Legacy Infrastructure in the Digital Age"
                subHeader="Why bringing legacy asset information up to modern standards holds the key to a safer, more resilient built world."
                imageURL="https://substackcdn.com/image/fetch/w_1280,h_853,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_auto/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F82352853-5e9b-41bd-80cb-a0d56ccf9f5e_1078x1063.jpeg"
                tag='Article'
                duration='5-6 mins read'
                authorName='Sam Rees'
                authorImageURL={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                publishedDate="Oct 01, '24"
                linkURL="https://samrees.substack.com/p/solving-the-data-debt-legacy-infrastructure-digital-age-asset-building-facility-management-information-safety-efficiency"

              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Is More Technology Really the Answer to Construction's Productivity Problem?"
                subHeader="An exploration of the disconnect between tech innovation and meaningful transformation in the built environment."
                imageURL="https://images.unsplash.com/photo-1561679543-696e0cf19f39?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMDAzMzh8MHwxfHNlYXJjaHwxNzZ8fGFic3RyYWN0JTIwc3RlZWx8ZW58MHx8fHwxNzI2ODMwNTQ0fDA&ixlib=rb-4.0.3&q=80&w=1080"
                tag='Article'
                duration='6-8 mins read'
                authorName='Sam Rees'
                authorImageURL={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                publishedDate="Sep 20, '24"
                linkURL="https://samrees.substack.com/p/is-more-technology-really-the-answer-to-constructions-productivity-problem-transformation-digital-data-innovation-rethink-contech-proptech"

              />
            </div>
            <div className='p-2'>
              <ArticleCard
                header="Illustrative Use Case Pack"
                subHeader="Discover the ways Hoppa supercharges teams day-to-day, and how much your business stands to save."
                imageURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.png`}
                tag='Use Cases'
                duration='10-15 mins read'
                authorName='Hoppa'
                authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                publishedDate="Sep 05, '24"
                linkURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.pdf`}

              />
            </div>
          </Carousel>
        </Container>
      </Box>
      <footer style={{ marginTop: 0 }}>
        <FooterBar />
      </footer>
    </>
  );
};

export default About;
