import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

const CustomAlert = ({message, type, showAlert, setShowAlert}) => {
    const [open, setOpen] = useState(showAlert);

    useEffect(() => {
        setOpen(showAlert); // Update the state when showAlert changes
    }, [showAlert]);    

    const handleClose = (event, reason) => {
        setOpen(false);
        setShowAlert(false); // Close the alert when handleClose is called
    };


    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>

    )

}

export default CustomAlert;

