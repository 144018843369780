import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Row, Col, Button } from 'react-bootstrap';
import { Box, Typography, Stack } from '@mui/material';

const Persona = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const customersData = [
        {
            id: 'plan',
            chipLabel: 'Plan',
            title: 'Spot information needs and stay aligned to the programme',
            description: [
                "In the early stages of a scheme, information needs and available documentation are evolving fast. ",
                "Hoppa keeps you on top of the administrative burden, sign-posting what exists and flagging where client deliverables are yet to be produced. ",
                "Planners and estimators get direct access to the facts, keeping lookaheads up to date and ensuring work packages are ready to go out on site. "
            ],
            image: `${process.env.PUBLIC_URL}/solutions-persona-plan.png`,
        },
        {
            id: 'construct',
            chipLabel: 'Construct',
            title: 'Automate collation of reports and evidence for handover',
            description: [
                "Juggling information across the supply chain is a never-ending battle, particularly at handover.",
                "Eliminate manual paper-work once and for all by filtering FPT, Cx and other documentation through Hoppa to build an evidence-based picture of status vs plan.",
                "Quickly and easily extend handover packs with new tags or metadata to derisk any last-minute customer requests derailing close-out.",
            ],
            image: `${process.env.PUBLIC_URL}/solutions-persona-construct.png`,
        },
        {
            id: 'operate',
            chipLabel: 'Operate',
            title: 'Extend operational life and emergency readiness',
            description: [
                "When a situation arises, you need to be able to find information fast. Even for routine operations and maintenance, resurveying assets can be costly and may not provide the complete picture.",
                "Build an accurate picture of engineering requirements versus the reality on the ground - and crucially, what might be out of place or missing – to feed quantitative facts into asset condition monitoring and portfolio investment models."
            ],
            image: `${process.env.PUBLIC_URL}/solutions-persona-operate.png`,
        }
    ];

    const [selectedTab, setSelectedTab] = useState(customersData[0].id);

    return (
        <div>
            {/* Navbar with Responsive Font & Wrapping */}
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                background: '#e6e6e689',
                padding: '2px 2px',
                borderRadius: '25px',
                width: 'fit-content',
                margin: '0 auto',
                flexWrap: 'wrap', // Allows wrapping on small screens
            }}>
                {customersData.map((item) => (
                    <Chip
                        key={item.id}
                        label={item.chipLabel}
                        onClick={() => setSelectedTab(item.id)}
                        style={{
                            backgroundColor: selectedTab === item.id ? '#ffffff' : 'transparent',
                            color: selectedTab === item.id ? '#000000' : '#606060',
                            cursor: 'pointer',
                            margin: '6px', // Adjusted margin for better spacing
                            padding: '8px 8px', // Slightly reduced padding
                            fontSize: '16px', // Smaller text for smaller screens
                            minWidth: '100px',
                            borderRadius: '20px',
                            boxShadow: selectedTab === item.id ? '0 4px 6px rgba(0, 0, 0, 0.2)' : 'none',
                            transition: 'box-shadow 0.3s ease'
                        }}
                    />
                ))}
            </div>
            <Box sx={{
                maxWidth: '1320px',
                width: '90%',
                padding: { xs: '0 2%' },
                margin: '32px auto',        // Horizontally center the box
                marginBottom: '50px',
                minHeight: '350px'
            }}>
                

                {customersData.map((customer) => (
                    selectedTab === customer.id && (
                        <>
                            <Stack direction={{ xs: 'column-reverse', lg: 'row' }}>
                                <Box sx={{ width: { xs: '100%', lg: '50%', marginTop: '16px' }, textAlign: 'left', minHeight: '300px' }}>
                                    <Typography variant='h5' display='block' className='mb-3 blue-font' sx={{ lineHeight: 1.2 }}>
                                        {customer.title}
                                    </Typography>
                                    {customer.description.map((paragraph) => (
                                        <Typography variant="body" display='block' className='mb-3'>
                                            {paragraph}
                                        </Typography>
                                    ))}
                                    <button className='btn btn-secondary ms-0 mt-3'>
                                <a href="/How_hoppa_can_help_your_organisation.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>Explore other use cases
                                <OpenInNewIcon style={{ fontSize: '16px', marginLeft: '6px' }} />
                                </a>
                                </button>
                                </Box>
                                <Box sx={{ pl: {xs: 0, lg: 4}, width: { xs: '100%', lg: '50%' } }}>
                                    <Box
                                        component="img"
                                        src={customer.image}
                                        sx={{
                                            width: '100%',
                                            objectPosition: 'center', // Adjusts cropping (optional)
                                            maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%)',
                                        }}
                                    >
                                    </Box>
                                </Box>
                            </Stack>
                        </>
                    )
                ))}
            </Box>
        </div>
    );
};

export default Persona;
