import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {Form } from 'react-bootstrap';
import { Typography } from '@mui/material';


const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [description, setDescription] = useState('');
    const [validated, setValidated] = useState(false);
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [companyError, setCompanyError] = useState(null);
    const [descriptionError, setDescriptionError] = useState(null);


    useEffect(() => {
        if(status === "success") clearFields();
      }, [status])
    
      const clearFields = () => {
        setName('');
        setEmail('');
        setCompany('');
        setDescription('');
      }
    
    
    const handleSubmit = (e) => {
        e.preventDefault();

        // Trigger form validation. 
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      if (!name) {setNameError('Cannot be blank')}
      if (!email) {setEmailError('Cannot be blank')} 
      else if (email.indexOf("@") < 0) {setEmailError('Invalid email address')}
      if (!company) {setCompanyError('Cannot be blank')} 
      if (!description) {setDescriptionError('Cannot be blank')}
      console.log('Error')
      return;
      
    }
        email &&
            name &&
            company &&
            description &&
            email.indexOf("@") > -1 &&
            onValidated({
                MERGE0: email,
                MERGE1: name,
                MERGE2: description,
                MERGE4: company,
                MERGE5: 'Demo request'
            });
    }

    return (
        <Form
            noValidate
            validated={validated}
            className="mc__form"
            onSubmit={(e) => handleSubmit(e)}
        >
            {status === "sending" && (
                <div className="mc__alert mc__alert--sending">
                    sending...
                </div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            {status !== "success" ? (
                <div className="mc__field-container">
                    <input
                        className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                        type="text"
                        required
                        placeholder='Name'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {nameError}
                    </Form.Control.Feedback>
                    <input
                        className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                        type="Email"
                        required
                        placeholder='Email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {emailError}
                    </Form.Control.Feedback>
                    <input
                        className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                        type="Text"
                        required
                        placeholder='Company'
                        onChange={(e) => setCompany(e.target.value)}
                        value={company}
                    />
                    <Form.Control.Feedback type="invalid">
                        {companyError}
                    </Form.Control.Feedback>
                    <textarea
                        className={'pt-3 custom-input ' + (validated ? 'custom-input-validated' : '')}
                        style={{minHeight:'100px'}}
                        required
                        placeholder='Tell us about your project.'
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        rows={4} // Adjust the number of rows as needed
                    />

                    <Form.Control.Feedback type="invalid">
                        {descriptionError}
                    </Form.Control.Feedback>
                </div>
            ) : ""}
            {
                status === 'success' ? '' :
                    <button className='btn btn-secondary m-0 mt-3'>Submit</button>
            }
        </Form>
    );
};

const RequestADemo = props => {

    const postUrl = `https://searchparty.us18.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
        <div className="mc__form-container" style={{width: '100%'}}>
            <Typography sx={{fontSize: {xs: '18pt', md: '0'}, py:2}} className='blue-font' variant='h6'>Chat to the team</Typography>
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

export default RequestADemo;