import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BrowserRouter, Link, Routes, useLocation, createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory';
import Home from './components/sales/Home';
import Product from './components/sales/Product';
import Solutions from './components/sales/Solutions';
import About from './components/sales/About';
import Contact from './components/sales/Contact';
import WelcomeUser from './components/WelcomeUser';
import OrganisationLanding from './components/OrganisationLanding';
import InitiateWorkspace from './components/subcomponents/dashboard/InitiateWorkspace';
import Workspace from './components/subcomponents/dashboard/Workspace';
import Results from './components/Results';
import CookiePolicy from './components/sales/CookiePolicy';
import PrivacyPolicy from './components/sales/PrivacyPolicy';
import BackupPolicy from './components/sales/BackupPolicy';
import TermsAndConditions from './components/sales/TermsAndConditions';
import NotFoundPage from './components/NotFoundPage';
import AccessDeniedPage from './components/AccessDeniedPage';
import Callback from './components/Callback';
import Authorize from './components/Authorize';
import CreateSession from './components/CreateSession';
import TextToUniclass from './components/sales/TextToUniclass';
import Login from './components/Login';
import { UserManagementProvider } from './contexts/UserManagementContexts';
import { UserStandardProvider } from './contexts/InitiateContexts';
import { WorkspaceStandardProvider } from './contexts/InitiateContexts';
import { SessionProvider } from './contexts/SessionContexts';
import { ResultsProvider } from './contexts/ResultsContexts';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './index.scss';
import Dashboard from './components/Dashboard';
import Integrations from './components/sales/Integrations';
import DataProcessingAddendum from './components/sales/DataProcessingAddendum';
import ModelUploadPOC from './components/subcomponents/experiments/ModelUploadPOC';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
        page_title: document.title,
      });
    }
  }, [location]);

  return null;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route element={<Auth0ProviderWithHistory />}>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/product" element={<Product />} />
      <Route path="/solutions" element={<Solutions />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/cookies" element={<CookiePolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/backup-policy" element={<BackupPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/data-processing-addendum" element={<DataProcessingAddendum />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/access-denied" element={<AccessDeniedPage />} />
      <Route path="/callback/:action/:service" element={<Callback />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/authorize" element={<Authorize />} />
      <Route path="/integrations" element={<Integrations />} />
      <Route path="/text-to-uniclass" element={<TextToUniclass />}/>
      <Route path="/login" element={<Login />}/>
      {/* <Route path="/modelupload" element={<ModelUploadPOC />} /> */}
      {/* Protected Routes */}
      
        <Route path="/welcome" element={<ProtectedRoute element={<OrganisationLanding />} />} />
        <Route path="/welcomeUser" element={<ProtectedRoute element={<WelcomeUser />} />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/dashboard/:region/:currentOrg/:workspace" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/:currentOrg/:workspace/createSession" element={<ProtectedRoute element={<CreateSession />} />} />
        <Route path="/:currentOrg/:workspace/initiateWorkspace" element={<ProtectedRoute element={<InitiateWorkspace />} />} />
        <Route path="/:currentOrg/:workspace/workspace" element={<ProtectedRoute element={<Workspace />} />} />
        <Route path="/:userId/:sessionId/results" element={<ProtectedRoute element={<Results />} />} />
        <Route path="/:currentOrg/:workspace/:sessionId/results" element={<ProtectedRoute element={<Results />} />} />
      </Route>
    </>
  )
);

function App() {
  return (
    <div className="App">
      {/* Context Providers now inside RouterProvider */}
                <RouterProvider router={router}>
                    <AnalyticsTracker />
                </RouterProvider>
    </div>
  );
}

export default App;
