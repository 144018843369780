import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, Tooltip, FormControlLabel, Switch, Checkbox, IconButton } from '@mui/material';
import { Stack } from "@mui/material";
import { Form, Nav, Tab, Table } from 'react-bootstrap';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Delete } from "@mui/icons-material";
import { useUserManagement, useCheckPermissions } from "../../../contexts/UserManagementContexts";
import { useNavigate, useParams } from "react-router-dom";


const ModifyStandard = ({ standard, setStandard }) => {
    const { currentOrg, workspace } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [showPrompt, setShowPrompt] = useState(false);
    const { user, organizations, setOrganizations, isLoading, isLoadingOrgs, fetchingWorkspaces, setFetchingWorkspaces } = useUserManagement();
    const checkPermissions = useCheckPermissions();
    const navigate = useNavigate();
    const [hasEditPermission, setHasEditPermission] = useState(false); // If user is able to create new workspaces

    // Permission check
    useEffect(() => {
        if (!isLoading && !isLoadingOrgs && currentOrg) {
            const isAuthorised = checkPermissions(['read:workspaces'], currentOrg)
            if (isAuthorised) {
                const editPermission = checkPermissions(['update:workspaces'], currentOrg)
                setHasEditPermission(editPermission)
            } else {
                navigate('/access-denied')
            }
        }
    }, [user, isLoading, isLoadingOrgs, currentOrg]);

    const addRow = (codePartIndex) => {

        // console.log(codePartIndex)
        // console.log(standard)
        setStandard((prevStandard) => ({
            ...prevStandard,
            classifiers: prevStandard.classifiers.map((codePart, index) =>
                codePart.id === codePartIndex
                    ? {
                        ...codePart,
                        data: [...codePart.data, { code: '', description: '' }], // Add the new row at the end of the data array
                    }
                    : codePart
            ),
        }));
    };

    const updateRow = (codePartIndex, rowIndex, field, value) => {
        setStandard((prevStandard) => ({
            ...prevStandard,
            classifiers: prevStandard.classifiers.map((codePart, index) =>
                index === codePartIndex
                    ? {
                        ...codePart,
                        data: codePart.data.map((rowData, rowIdx) =>
                            rowIdx === rowIndex ? { ...rowData, [field]: value } : rowData
                        ),
                    }
                    : codePart
            ),
        }));
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            // Select all
            const allSelected = new Set(filteredData.map((_, index) => index)); // Create a set of all row indexes
            setSelectedRows(allSelected); // Set selected rows state to all selected
        } else {
            // Deselect all
            setSelectedRows(new Set()); // Clear selected rows
        }
    };

    const filteredData = standard?.classifiers[selectedTab]?.data.filter(row =>
        row.code.includes(searchTerm) || row.description.includes(searchTerm)
    );

    const handleRowSelect = (rowIndex) => {
        const newSelectedRows = new Set(selectedRows);
        if (newSelectedRows.has(rowIndex)) {
            newSelectedRows.delete(rowIndex);
        } else {
            newSelectedRows.add(rowIndex);
        }
        setSelectedRows(newSelectedRows); // Trigger re-render
    };

    const handleBulkDelete = () => {
        setStandard((prevStandard) => ({
            ...prevStandard,
            classifiers: prevStandard.classifiers.map((codePart, index) =>
                index === selectedTab
                    ? {
                        ...codePart,
                        data: codePart.data.filter((_, rowIndex) => !selectedRows.has(rowIndex)),
                    }
                    : codePart
            ),
        }));
        setSelectedRows(new Set()); // Reset selection
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addRow(selectedTab); // Trigger adding a new row
        }
    };

    const handlePasteFromExcel = (e, partId) => {
        e.preventDefault()

        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text');

        // Parse the pasted data and update the tableData state
        const parsedRows = pastedData.split('\n').map((row) => {
            const [code, description, prompt] = row.split('\t');

            // Clean the description (remove unwanted characters)
            const cleanedDescription = description?.replace(/[\r\n]/g, '');

            const cleanedPrompt = prompt?.replace(/[\r\n]/g, '');


            if (code) {
                return { code: code, description: cleanedDescription || '', prompt: cleanedPrompt || '' }
            } else {
                return
            }

        }).filter(Boolean)

        setStandard((prevState) => {
            // Find the classifier (codePart) with the matching id
            const updatedClassifiers = prevState.classifiers.map((classifier) => {
                // Find the codePart that matches the partId
                if (classifier.id === partId) {
                    return {
                        ...classifier,
                        // Add an empty item to the data array
                        data: parsedRows,
                    };
                }
                return classifier;
            });

            // Return the updated state with the modified classifiers
            return {
                ...prevState,
                classifiers: updatedClassifiers,
            };
        });



    };


    return (
        <>
            {standard &&
                <Tab.Container activeKey={`codePart-${selectedTab}`}>
                    <Stack direction='row' spacing={1} style={{ width: '100%' }}>
                        <Nav style={{ borderRadius: '5px', width: '20%', minWidth: '20%' }} variant="pills" className="flex-column">
                            {standard.classifiers.map((codePart, index) => (
                                <Nav.Item key={index} style={{ marginTop: "5px" }}>
                                    <Nav.Link
                                        eventKey={`codePart-${index}`}
                                        className={selectedTab === index ? 'active custom-nav-link' : 'custom-nav-link'}
                                        onClick={() => {
                                            setSelectedTab(index)
                                            setSelectedRows(new Set())
                                        }}
                                    >
                                        {codePart.name}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content style={{ flexGrow: 1 }}>
                            {standard.classifiers.map((codePart, index) => (
                                <Tab.Pane className="tab-content" key={index} eventKey={`codePart-${index}`}>
                                    <Box sx={{ height: '100%', overflowY: 'auto' }}>
                                        <Stack direction='row' spacing={1}>
                                            <TextField
                                                type="text"
                                                placeholder="Search..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                style={{ flex: 1, maxWidth: '400px', backgroundColor: 'white' }}
                                                size='small'

                                            />
                                            <Tooltip title='Delete selected'>
                                                <span>
                                                    <IconButton
                                                        onClick={() => handleBulkDelete()}
                                                        disabled={selectedRows.size === 0}
                                                        sx={{ color: 'FireBrick' }}
                                                    >
                                                        <Delete
                                                        />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip title='Add code'>
                                                <span>
                                                    <IconButton
                                                        onClick={() => addRow(codePart.id)}
                                                        sx={{ color: 'green' }}
                                                        disabled={!hasEditPermission}
                                                    >
                                                        <AddCircleIcon
                                                        />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <FormControlLabel
                                                control={<Switch checked={showPrompt} onChange={() => setShowPrompt(!showPrompt)} sx={{ color: '#4a4bd6' }} />}
                                                label={showPrompt ? 'Hide advanced' : 'Show advanced'}
                                                labelPlacement="end"
                                                disabled={standard.classifiers.length === 0}
                                            />
                                        </Stack>
                                        <Table responsive onPaste={(e) => handlePasteFromExcel(e, codePart.id)}>
                                            <thead>
                                                <tr style={{ height: '60px' }}>
                                                    <th style={{ backgroundColor: "transparent", verticalAlign: 'middle' }}>
                                                        <div style={{}}>
                                                            <Checkbox
                                                                checked={selectedRows.size === codePart.data.length} // Check if all are selected
                                                                indeterminate={selectedRows.size > 0 && selectedRows.size < codePart.data.length} // Show indeterminate state if some are selected
                                                                onChange={(e) => handleSelectAll(e, index)} // Handle the change to select or deselect all
                                                                sx={{ p: 0 }}
                                                                disabled={!hasEditPermission}
                                                            />

                                                        </div>
                                                    </th>
                                                    <th style={{ width: '140px', backgroundColor: "transparent", verticalAlign: 'middle' }}>
                                                        Code
                                                    </th>
                                                    <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>
                                                        <div className="d-flex">
                                                            Description
                                                        </div>
                                                    </th>
                                                    {showPrompt && <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>Prompt</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {codePart.data.filter(row =>
                                                    row.code?.toLowerCase().includes(searchTerm.toLowerCase()) || row.description?.toLowerCase().includes(searchTerm.toLowerCase()) || row.prompt?.toLowerCase().includes(searchTerm.toLowerCase()))
                                                    .map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            <td style={{ width: '50px', padding: 0, textAlign: 'left', verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                <Checkbox
                                                                    onChange={() => handleRowSelect(rowIndex)}
                                                                    checked={selectedRows.has(rowIndex)}
                                                                    disabled={!hasEditPermission}
                                                                />
                                                            </td>
                                                            <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                <Form.Control
                                                                    type="text"
                                                                    id={`${rowIndex}`}
                                                                    value={row.code}
                                                                    onChange={(e) => updateRow(index, rowIndex, 'code', e.target.value)}
                                                                    style={{ width: '130px' }} // Reduce width of the code input
                                                                    onKeyDown={handleKeyDown}
                                                                    disabled={!hasEditPermission}
                                                                />
                                                            </td>
                                                            <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                <Form.Control
                                                                    as={showPrompt ? "textarea" : "input"} // Toggle between textarea and input
                                                                    rows={showPrompt ? 3 : 1} // Change rows based on the toggle
                                                                    value={row.description}
                                                                    onChange={(e) => updateRow(index, rowIndex, 'description', e.target.value)}
                                                                    style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                    onKeyDown={handleKeyDown}
                                                                    disabled={!hasEditPermission}
                                                                />
                                                            </td>
                                                            {showPrompt && (
                                                                <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                                    <Form.Control
                                                                        as="textarea" // Always a textarea for prompt
                                                                        rows={3} // Set number of visible rows for prompt
                                                                        value={row.prompt}
                                                                        onChange={(e) => updateRow(index, rowIndex, 'prompt', e.target.value)}
                                                                        style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                        onKeyDown={handleKeyDown}
                                                                        disabled={!hasEditPermission}
                                                                    />
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </Box>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Stack>
                </Tab.Container>
            }
        </>
    );
}

export default ModifyStandard;
