import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { MaterialReactTable, MRT_ActionMenuItem, useMaterialReactTable } from 'material-react-table';
import { createFilterOptions } from '@mui/material';
import { Typography, Box, Button, IconButton, ButtonGroup, Chip, Switch, FormControlLabel, Divider, Stack, ToggleButtonGroup, ToggleButton, Modal, TextField, FormControl, InputLabel, Select, DialogActions, DialogContent, Dialog, DialogTitle, ListItemIcon, ListItemText, Autocomplete, Drawer, CircularProgress, Checkbox } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import SaveIcon from '@mui/icons-material/Save';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HistoryIcon from '@mui/icons-material/History';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from "@mui/icons-material/Close";
import { Add, Delete, DeleteForever, DynamicForm, Edit, Settings as SettingsIcon, TagRounded } from '@mui/icons-material';
import { Tooltip, MenuItem } from '@mui/material';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useUserManagement } from '../../../contexts/UserManagementContexts';
import { useParams, useBlocker } from "react-router-dom";
import RenameFiles from './RenameFiles';
import { blue } from '@mui/material/colors';
import { ContinuousColorLegend } from '@mui/x-charts';
import { useResults } from '../../../contexts/ResultsContexts';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import ReactCodeMirror from '@uiw/react-codemirror';
import { materialLight } from '@uiw/codemirror-theme-material';
import { json } from '@codemirror/lang-json';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

let config = {
  headers: {
    'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
  }
}


const ResultsTable = ({ sessionStandard, classifierHeaders, searchHeaders, baseResults, rawResultsData, fileNamesFiltered, tags, handleSetLoadingState, handleOpenJSONDrawer, triggerAlert, hasEditPermission }) => {

  // Page load
  const { user } = useUserManagement();
  const { currentOrg, workspace, sessionId, userId } = useParams();

  // Table content  
  const {
    mergedResults,
    setMergedResults,
    isUnsavedEdits,
    setIsUnsavedEdits,
    savedEdits,
    setSavedEdits,
    allEdits,
    setAllEdits,
    customColumns,
    setCustomColumns
  } = useResults()

  const [modifiedFileNames, setModifiedFileNames] = useState({});

  // Table interactions
  const [showTags, setShowTags] = useState(false)
  const [showSearchTerms, setShowSearchTerms] = useState(false)
  const [showRenameSettings, setShowRenameSettings] = useState(false)
  const [newEdits, setNewEdits] = useState();
  const [isMerged, setIsMerged] = useState(false)

  // Bulk editing
  const [selectedRowCount, setSelectedRowCount] = useState(0)
  const [bulkEdit, setBulkEdit] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [edits, setEdits] = useState({})

  // Custom columns
  const [newColumn, setNewColumn] = useState(false)
  const [header, setHeader] = useState("")

  // Making search work with row expansion
  const [expanded, setExpanded] = useState({}); // Track expanded rows
  const [globalFilter, setGlobalFilter] = useState('')

  const [metadataJson, setMetadataJson] = useState(null);
  const [metadataTitle, setMetadataTitle] = useState('Source File Metadata');
  const [thumbnailSrc, setThumbnailSrc] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Export actions
  const [isOpen, setIsOpen] = useState(false);
  const [exportSelectedRows, setExportSelectedRows] = useState(false);
  const [exportMetadata, setExportMetadata] = useState(false);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUnsavedEdits) {
        event.preventDefault();
        event.returnValue = ''; // Standard way to show the confirmation dialog
      }
    };

    // Add the event listener when there are unsaved changes
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUnsavedEdits]); // Re-run the effect if `unsavedEdits` changes



  const handleBlur = (row, tags) => {

    const updatedRow = allEdits && allEdits[row.id]
      ? {
        ...allEdits[row.id],
        tags: tags
      }
      : {
        name: row.original.name,
        tags: tags
      };

    // console.log(updatedRow)

    setIsUnsavedEdits(true)

    setNewEdits((prevEditedRow) => ({
      ...prevEditedRow,
      [row.id]: updatedRow,
    }))
  }


  // Effect to manage loading edits from storage.
  useEffect(() => {
    // FIRST TIME LOAD - If base results but no merged results then populate the merged results array from storage. 
    if (baseResults && mergedResults.length == 0) {
      //console.log('Adding base results')
      setMergedResults(baseResults)
      //console.log(baseResults)
    }

    // ADDING USER EDITS FROM STORAGE - If there are merged results and edits from storage then merge them, but only execute once. 
    if (mergedResults && !isMerged && savedEdits && Object.keys(savedEdits).length > 0) {
      updateTable(savedEdits)
      setIsMerged(true)
    }

  }, [savedEdits, baseResults, mergedResults, isMerged])

  // Effect to manage merging new user edits
  useEffect(() => {

    // USER HAS REFRESHED RESULTS WITHOUT RELOADING PAGE- base results have changed
    if (mergedResults) {

      // Reset so that userEdits in blob get merged back in
      if (baseResults !== mergedResults) { // Only update if they are different
        setMergedResults(baseResults);
        updateTable(savedEdits);
      }
    }

  }, [baseResults])


  // Effect to manage merging new user edits
  useEffect(() => {
    const handleUpdateTable = async (newEdits) => {
      await updateTable(newEdits)
      setNewEdits(null)
      setIsUnsavedEdits(true)
    }

    // console.log('New edits are', newEdits)

    if (newEdits && Object.keys(newEdits).length > 0) {
      handleUpdateTable(newEdits);
    }

  }, [newEdits])


  const updateTable = async (newEdits) => {

    // Ensure newEdits is valid before proceeding
    if (!newEdits || Object.keys(newEdits).length === 0) {
      // console.log("Invalid newEdits:", newEdits);
      return;
    }

    // Call the onSaveRow prop with the edited row
    await handleTableDataChange(Object.values(newEdits));

    // Persisting edits in state for write back to database on user trigger. 
    setAllEdits((prevAllEdits) => {
      const updatedAllEdits = { ...prevAllEdits }; // Start with a copy of the existing allEdits

      // Merge newEdits into prevAllEdits
      Object.keys(newEdits)?.forEach((key) => {
        if (updatedAllEdits[key]) {
          // If the row already exists, merge the new edits into the existing row
          updatedAllEdits[key] = {
            ...updatedAllEdits[key],   // Keep previous values
            ...newEdits[key],          // Overwrite or add new values
          };
        } else {
          // If the row doesn't exist yet, just add it
          updatedAllEdits[key] = newEdits[key];
        }

        // Iterate over each item in newEdits[key] to check individual keys
        Object.keys(newEdits[key])?.forEach((innerKey) => {
          // Check if a column with this key already exists
          // console.log('Assessing:', innerKey)

          const columnExists = customColumns?.hasOwnProperty(innerKey) || // Check if customColumns has the key
          (classifierHeaders && classifierHeaders.includes(innerKey)) || // Check keys of classifierHeaders
          (searchHeaders && searchHeaders.includes(innerKey)) ||         // Check keys of searchHeaders
            ["name", "tags"].includes(innerKey)

          // If the column doesn't exist, add it
          // Also check if sessionStandard has loaded, else can't say for certain the column doesn't exist
          if (!columnExists && sessionStandard) {

            // Remove '-custom' from the header if it exists
            const header = innerKey.endsWith('-custom')
              ? innerKey.slice(0, -7)
              : innerKey;

            addColumn(innerKey, header.charAt(0).toUpperCase() + header.slice(1));
          }
        });
      });

      // console.log(updatedAllEdits)

      return updatedAllEdits
    })
  }

  const handleTableDataChange = (updatedData) => {
    setMergedResults((prevMergedResults) => {
      // Map over the existing tableData
      const mergedData = prevMergedResults.map((row) => {
        // Find the edited row in the updatedData using the name property
        const editedRow = updatedData.find((updatedRow) => updatedRow.name === row.name);
        // If the edited row exists, merge it with the existing row, otherwise, return the existing row unchanged
        return editedRow ? { ...row, ...editedRow } : row;
      });

      return mergedData;

    });
  };

  // Function to add a new column
  const addColumn = (accessorKey, header) => {

    // console.log('Adding column for:', header);
    setCustomColumns((prevColumns) => {
      // Check if the column already exists
      if (prevColumns[accessorKey]) {
        return prevColumns; // No changes if the column already exists
      }

      // Add the new column
      const updatedColumns = {
        ...prevColumns,
        [accessorKey]: header, // Add the new key-value pair
      };
      return updatedColumns;
    });
  };

  const deleteColumn = (accessorKey) => {

    setCustomColumns((prevColumns) => {
      const updatedColumns = { ...prevColumns }

      // Check if the column already exists
      if (updatedColumns[accessorKey]) {
        delete updatedColumns[accessorKey]
      }

      return updatedColumns
    })

    clearColumnEdits(accessorKey)

    triggerAlert('Custom column deleted')
  }

  const clearColumnEdits = (accessorKey) => {
    setIsUnsavedEdits(true); // Flag unsaved edits

    // Create a copy of allEdits to avoid mutating state directly
    const updatedAllEdits = { ...allEdits };

    // Iterate through each document in allEdits
    Object.keys(updatedAllEdits).forEach((document) => {
      if (updatedAllEdits[document].hasOwnProperty(accessorKey)) {
        // Remove the accessorKey from the edits
        delete updatedAllEdits[document][accessorKey];
        // console.log(`Cleared edits for ${accessorKey} in document ${document}`);
      }
    });

    // console.log(updatedAllEdits); // Log the updated state for debugging

    // Update the state with the modified edits
    setMergedResults(baseResults);
    updateTable(updatedAllEdits);

  };

  // Reset expanded state when global filter changes
  const handleGlobalFilterChange = (newFilterValue) => {
    // console.log(newFilterValue)
    setGlobalFilter(newFilterValue)
    setExpanded({}); // Collapse all rows
  };


  const handleToggleTags = () => {
    setShowTags(!showTags);
  };

  const handleToggleSearchTerms = () => {
    setShowSearchTerms(!showSearchTerms);
  };

  // Open the modal
  const handleOpenRenameSettingsModal = () => {
    setShowRenameSettings(true);
  }

  // Close the modal
  const handleCloseRenameSettingsModal = () => {
    setShowRenameSettings(false);
  }

  // Close the modal
  const handleSaveEdits = async () => {

    handleSetLoadingState('Saving edits...', true)

    // console.log(allEdits)

    const allEditsJSON = JSON.stringify(allEdits, null, 4);

    const allEditsJSONforBlob = new Blob([allEditsJSON], { type: 'application/json' });

    const formData = new FormData();

    formData.append('file', allEditsJSONforBlob, 'userEdits.json');
    formData.append('organisation', currentOrg ? currentOrg : userId);
    formData.append('workspace', workspace ? workspace : '');
    formData.append('sessionId', sessionId);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;

    })

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/FileUploadTrigger`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          },
        }
      );

    }
    catch (error) {
      console.error("Error saving edits:", error.message);
    } finally {
      handleSetLoadingState('', false)
      setSavedEdits(allEdits)
      setIsUnsavedEdits(false)
    }
  }

  const handleResetEdits = async () => {
    setAllEdits([])
    setMergedResults(baseResults)
    setIsMerged(false)
    setIsUnsavedEdits(false)
  }

  const handleDeleteAllEdits = async () => {

    handleSetLoadingState('Deleting all saved edits...', true)

    const allEditsJSON = JSON.stringify({});

    const allEditsJSONforBlob = new Blob([allEditsJSON], { type: 'application/json' });

    const formData = new FormData();

    formData.append('file', allEditsJSONforBlob, 'userEdits.json');
    formData.append('organisation', currentOrg ? currentOrg : userId);
    formData.append('workspace', workspace ? workspace : '');
    formData.append('sessionId', sessionId);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;

    })

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/FileUploadTrigger`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          },
        }
      );

    }
    catch (error) {
      console.error("Error saving edits:", error.message);
    } finally {
      handleSetLoadingState('', false)
      setAllEdits([])
      setMergedResults(baseResults)
      setIsMerged(false)
      setIsUnsavedEdits(false)
      setSavedEdits([])
    }
  }

  const handleRenameFiles = (codeSettings) => {
    //console.log("Rename taking place with following settings: ", codeSettings);
    //console.log("Current table data used for rename: ", mergedResults);
    //console.log("List of file names: ", mergedResults.map(file => file.name));
    //console.log("List of classifier names: ", classifierHeaders);

    // Initialize an object to store the new file names
    const newFileNames = {};

    // Initialize an object to store the sequential numbers for each file name pattern
    const sequentialNumbers = {};

    // Iterate over each file in tableData
    const updatedMergedResults = mergedResults.map((file) => {
      let newFileName; // Declare it here for wider scope
      let numberedFileName; // To hold the final file name with numbers

      // console.log(file)

      if (codeSettings.advancedMode) {
        // Start with the advancedMode pattern
        newFileName = codeSettings.advancedMode;

        // Find all placeholders (e.g., {Originator}, {Volume}, {Role}) using regex
        const regex = /\{(.*?)\}/g; // Matches everything inside curly braces

        // Replace placeholders with actual classifier values
        newFileName = newFileName.replace(regex, (match, p1) => {
          const classifierName = p1.trim(); // Extract the classifier name inside the curly braces

          let classifierValue;

          //console.log('Name is', classifierName)

          // First check the string contains code parts,otherwise just return the original string
          if (classifierName === "Item Name") {
            // Remove the file extension from file.name
            classifierValue = file.name.split('.').slice(0, -1).join('.'); // Gets the name without extension
          } else if (classifierName === "Number") {
            classifierValue = '{Number}'; // You can keep this empty for now
          } else {
            classifierValue = file[classifierName]?.code || file[classifierName] || file[`${classifierName.toLowerCase()}-custom`] || 'undefined'; // Get the classifier value from the file data
          }
          return classifierValue; // Replace with classifier value or an empty string if not found
        });

      } else {
        // Basic mode logic
        // Extract classifier codes for the current file
        const classifierCodes = classifierHeaders.map(classifierName => {
          return file[classifierName].code || ''; // If classifier is found, extract code part, otherwise return an empty string
        });

        // Join classifier codes into a single string using a separator
        const separator = codeSettings.documentSeparator;
        newFileName = classifierCodes.join(separator); // Assign the joined string to newFileName
      }

      // Check if the new file name pattern already exists in the sequentialNumbers object
      if (!sequentialNumbers[newFileName]) {
        // Initialize the sequential number for this pattern to 0
        sequentialNumbers[newFileName] = 0;
      }

      // Increment the sequential number for this pattern
      sequentialNumbers[newFileName]++;

      // Generate the sequential number string with leading zeros based on the specified number of digits
      const sequentialNumberString = sequentialNumbers[newFileName].toString().padStart(codeSettings.numberingSystem.digits, '0');

      // Finalize the numbered file name
      if (codeSettings.advancedMode) {
        // Replace the {Number} placeholder in the new file name with the sequential number
        numberedFileName = newFileName.replace(/\{Number\}/g, sequentialNumberString);
      } else {
        // Combine the new file name pattern and the sequential number
        numberedFileName = `${newFileName}${codeSettings.documentSeparator}${sequentialNumberString}`;
      }

      // Add the original file name and the corresponding new file name to the newFileNames object
      newFileNames[file.name] = numberedFileName;

      // Add the new file name to the file object
      return { ...file, newFileName: numberedFileName }; // Return the updated file object
    });

    // Log the updated tableData
    //console.log("Updated table data with new file names: ", updatedMergedResults);

    // Update the tableData state with the new file names
    setMergedResults(updatedMergedResults);

    // Update the modifiedFileNames state with the new file names
    setModifiedFileNames(newFileNames);
    //console.log(modifiedFileNames);
  };

  const handleDownloadFiles = async () => {

    if (isUnsavedEdits) {
      triggerAlert('Save edits before exporting', 'warning')
      return
    }
    else if (Object.keys(modifiedFileNames).length === 0) {
      triggerAlert('Generate file codes before exporting', 'warning')
      return
    } else {

      try {
        handleSetLoadingState('Zipping files for download...', true)

        let request_url

        if (userId) {
          request_url = `${API_BASE_URL}/api/DownloadDocumentsTrigger?sessionId=${sessionId}&containerName=${userId}`
        } else {
          request_url = `${API_BASE_URL}/api/DownloadDocumentsTrigger?sessionId=${sessionId}&workspace=${workspace}&containerName=${currentOrg}`
        }

        const response = await axios.post(
          request_url,
          {
            modifiedFileNames: modifiedFileNames,
          },
          {
            responseType: 'arraybuffer',
            headers: {
              'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
            }
          }
        );


        // Check if the response is successful
        if (response.status === 200) {
          // Create a blob containing the files
          const blob = new Blob([response.data], { type: 'application/zip' });

          // Create a link element to trigger the download
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'downloadedFiles.zip';

          // Trigger the download
          link.click();

          // Remove the link element only if it's a child of the document body
          if (document.body.contains(link)) {
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }

        } else {
          console.error('Failed to fetch files from the blob.');
        }
      } catch (error) {
        console.error('Error downloading files:', error);
      } finally {
        handleSetLoadingState('', false)
      }
    }
  };


  const flattenData = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, item]) => {
        if (typeof item === 'object' && item !== null && 'code' in item) {
          // Flatten if 'code' exists
          return [key, item.code];
        } else if (Array.isArray(item)) {
          // Flatten array to a semi-colon separated string
          return [key, item.join(';')];
        } else {
          // Return item as is
          return [key, item];
        }
      })
    );


  ////console.log('Columns:', columns);
  const fetchMetadataForRows = async (rows) => {
    const rowsWithMetadata = rows.filter(row => row.metadata === true);
  
    if (rowsWithMetadata.length === 0) {
      return rows; // No metadata rows to process
    }
  
    // Construct blob names list
    const blobNames = rowsWithMetadata.map(row => 
      row.fileSource 
        ? `${row.fileSource}/${row.name}_metadata.json` 
        : `${row.name}_metadata.json`
    );
  
    try {
      const metadataResponse = await axios.get(
        `${API_BASE_URL}/api/DownloadBlobs`,
        {
          params: {
            container: userId ? userId : currentOrg,
            sessionGuid: sessionId,
            blobs: blobNames.join(","), // Pass all blob names as a single request
            ...(userId ? {} : { workspace }),
          },
          headers: {
            "x-functions-key": process.env.REACT_APP_FUNCTIONAPP_KEY,
          },
        }
      );
  
      if (metadataResponse.status === 200) {
        const metadataResults = metadataResponse.data;
  
        rowsWithMetadata.forEach(row => {
          const metadataBlobName = row.fileSource
            ? `${row.fileSource}/${row.name}_metadata.json`
            : `${row.name}_metadata.json`;
  
          if (metadataResults[metadataBlobName]) {
            try {
              // Prettify JSON metadata properly for CSV output
              const parsedMetadata = JSON.parse(metadataResults[metadataBlobName].content);
              row.metadata = JSON.stringify(parsedMetadata, null, 2); // Pretty print JSON
              
            } catch (error) {
              row.metadata = metadataResults[metadataBlobName].content; // Keep original if not JSON
            }
          } else {
            row.metadata = "Metadata not found"; // Handle missing blobs
          }
        });
      }
    } catch (error) {
      console.error("Error fetching metadata:", error);
      rowsWithMetadata.forEach(row => {
        row.metadata = "Error fetching metadata"; // Handle request failure gracefully
      });
    }
  
    return rows; // Return updated rows
  };
  
  
  const handleExportRows = async (rows) => {
    if (isUnsavedEdits) {
      triggerAlert("Save edits before exporting", "warning");
      return;
    }
  
    let rowData = rows.map((row) => row.original);
  
    // If metadata option is selected, fetch metadata
    if (exportMetadata) {
      rowData = await fetchMetadataForRows(rowData);
    }
  
    // Flatten the data array
    const flattenedDataArray = rowData.map(flattenData);
  
    // Generate and download CSV
    const csv = generateCsv(csvConfig)(flattenedDataArray);
    download(csvConfig)(csv);
  };
  
  const handleExportData = async () => {
    if (isUnsavedEdits) {
      triggerAlert("Save edits before exporting", "warning");
      return;
    }
  
    let dataToExport = [...mergedResults];
  
    // If metadata option is selected, fetch metadata
    if (exportMetadata) {
      dataToExport = await fetchMetadataForRows(dataToExport);
    }
  
    // Flatten the data array
    const flattenedDataArray = dataToExport.map(flattenData);
  
    //console.log("Flattened Data:", flattenedDataArray);
  
    // Generate and download CSV
    const csv = generateCsv(csvConfig)(flattenedDataArray);
    download(csvConfig)(csv);
  };
  
  const handleConfirmExport = async () => {
    if (exportSelectedRows) {
      await handleExportRows(selectedRows || []);
    } else {
      await handleExportData();
    }
  
    setIsOpen(false);
    setExportSelectedRows(false);
    setExportMetadata(false);
  
    triggerAlert("Export successful!", "success");
  };
  
  

  const handleRerunSelectedFiles = async (rows) => {
    try {
      // Extract row data
      const rowData = rows.map((row) => row.original.name); // Extract the 'name' field for each row

      // Map file names to original_blob(s) and construct filePaths
      const filePaths = rowData.flatMap((rowName) => {
        // Find the matching entry in rawResultsData.results
        const matchingEntryKey = Object.keys(rawResultsData.results).find((key) => key.includes(rowName));

        if (!matchingEntryKey) {
          console.warn(`No matching entry found for row name: ${rowName}`);
          return [];
        }

        const originalBlobEntry = rawResultsData.results[matchingEntryKey];

        // Check if original_blob is a list or a single value
        if (Array.isArray(originalBlobEntry.original_blob)) {
          //console.log(`Original blob is an array for ${rowName}:`, originalBlobEntry.original_blob);
          return originalBlobEntry.original_blob; // Add all blobs in the list
        } else if (typeof originalBlobEntry.original_blob === "string") {
          //console.log(`Original blob is a string for ${rowName}:`, originalBlobEntry.original_blob);
          return [originalBlobEntry.original_blob]; // Wrap single blob in an array
        } else {
          console.error(`Unexpected format for original_blob in ${rowName}:`, originalBlobEntry.original_blob);
          return [];
        }
      });

      // Log the constructed filePaths
      //console.log("Constructed filePaths:", filePaths);

      // Construct the payload
      const body = {
        filePaths,
        organisation: currentOrg,
        workspace: workspace,
        sessionId: sessionId,
        userId: user.sub,
      };

      // Log the payload
      //console.log("Payload for API call:", body);

      // Construct the API URL
      const url = `${API_BASE_URL}/api/QueueFiles`;

      // Create the config with the required headers
      const config = {
        headers: {
          'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY, // Function key as a header
        },
      };

      // Make the API request
      const response = await axios.post(url, body, config);
      // console.log("Response from rerun:", response.data);
      triggerAlert('Files re-queue successful')

    } catch (error) {
      console.error("Error re-running the files:", error);
      triggerAlert('File re-queue unsuccessful.', 'error')
    }
  };

  // Open Modal and Initialize Form Values
  const handleBulkEditClick = (selectedRows) => {

    setSelectedRowCount(selectedRows.length)
    setSelectedRows(selectedRows)
    setBulkEdit(true)
  };

  const handleBulkEditClose = () => {
    setBulkEdit(false)
    setSelectedRowCount(selectedRows.length)
    setSelectedRows([])
    setEdits({})
  };

  const handleBulkEditSubmit = () => {
    setNewEdits((prevEditedRows) => {
      const updatedRows = selectedRows.reduce((acc, item) => {
        // Create a new entry with the item's id and the bulkEdits plus the 'name' from the item.original
        acc[item.id] = {
          ...edits,        // Include the bulkEdits
          name: item.original['name'],  // Add the 'name' field from item.original
        };
        return acc;
      }, {}); // Initialize an empty object for accumulating the updates

      // console.log(updatedRows); // Log to check the new data structure

      // Merge the previous edits with the new updated rows
      return {
        ...prevEditedRows,
        ...updatedRows,
      };
    });

    // Close modal after submission
    setBulkEdit(false);
    setEdits({})
  };


  // Function for persisting form changes in stage
  const handleEdit = (key, value) => {
    setEdits((prevEdits) => ({
      ...prevEdits,
      [key]: value,
    }))
  }

  const handleGetMetadataClick = async (metadata_title, metadata_blob, thumbnail_blob) => {
    setIsLoading(true);
    setMetadataJson(null);
    setThumbnailSrc(null);
    setDrawerOpen(true);
    setMetadataTitle(metadata_title);

    console.log(metadata_title)
    try {
      let metadataResponse, thumbnailResponse;
  
      if (metadata_blob) {
        const metadataUrl = userId
          ? `${API_BASE_URL}/api/DownloadBlob?container=${userId}&blob=${metadata_blob}&sessionGuid=${sessionId}`
          : `${API_BASE_URL}/api/DownloadBlob?container=${currentOrg}&blob=${metadata_blob}&sessionGuid=${sessionId}&workspace=${workspace}`;
  
        metadataResponse = await axios.get(metadataUrl, {
          headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY },
        });
      }
  
      if (thumbnail_blob) {
        const thumbnailUrl = userId
          ? `${API_BASE_URL}/api/DownloadBlob?container=${userId}&blob=${thumbnail_blob}&sessionGuid=${sessionId}`
          : `${API_BASE_URL}/api/DownloadBlob?container=${currentOrg}&blob=${thumbnail_blob}&sessionGuid=${sessionId}&workspace=${workspace}`;
  
        // Fetch image as binary data
        thumbnailResponse = await axios.get(thumbnailUrl, {
          headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY },
          responseType: 'blob', // Ensure the response is treated as a Blob
        });
      }
  
      // Handle Metadata Response
      if (metadataResponse?.status === 200) {
        setMetadataJson(JSON.stringify(metadataResponse.data, null, 2));
      } else if (metadataResponse) {
        console.error('Error fetching metadata:', metadataResponse.status, metadataResponse.data);
      }
  
      // Handle Thumbnail Response
      if (thumbnailResponse?.status === 200) {
        // Convert blob to object URL
        const imageUrl = URL.createObjectURL(thumbnailResponse.data);
        console.log(thumbnailResponse.status)
        setThumbnailSrc(imageUrl);
      } else if (thumbnailResponse) {
        console.error('Error fetching thumbnail:', thumbnailResponse.status);
      }
    } catch (error) {
      console.error('Error in handleGetMetadata:', error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };
  
  // Close metadata drawer
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setMetadataTitle('Metadata Summary')
  }
  

  //console.log("MERGED:", mergedResults)

  // Define column names to be passed to material-react-table component
  // Note, I tried putting this into the table component for simplicity, with classifiers passed as props but it created and infinite loop and I couldn't work out why
  const columns = useMemo(() => {

    // Columns are generated dynamically from the fields in the 'data' prop passed to the table component (data=tableData)
    // the material react table component takes the data prop and uses the 'accessorKey' to map data to columns
    const dynamicColumns = [
      // This first column is simple, it looks for 'name' in the data prop, which corresponds to file name
      {
        accessorKey: "name",
        header: "Item Name",
        enableEditing: false,
        //muiTableHeadCellProps: { sx: { color: "green" } }
        Cell: ({ row, renderedCellValue }) => {
          const fileName = row.original.name; // File name
          const webUrl = row.original.webUrl;
          const fileSource = row.original.fileSource; // Source of the file
          const metadataBoolean = row.original.metadata; // Check if the file has metadata
          const metadata_blob = metadataBoolean ? fileSource ? `${fileSource}/${fileName}_metadata.json` : `${fileName}_metadata.json` : null
          const thumbnailBoolean = row.original.thumbnail;
          const thumbnail_blob = thumbnailBoolean ? fileSource ? `${fileSource}/${fileName}0_image.webp` : `${fileName}0_image.webp` : null

          return (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <strong>{renderedCellValue}</strong>
              {fileSource && webUrl && (
                <Tooltip title={`Open in ${fileSource.charAt(0).toUpperCase() + fileSource.slice(1)}`} arrow>
                  <a
                    href={webUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center", gap: "4px" }}
                  >
                    {/* Material-UI OpenInNewIcon with smaller size and pointer cursor */}
                    <OpenInNewIcon
                      style={{ fontSize: "16px", cursor: "pointer" }}
                    />
                  </a>
                </Tooltip>
              )}
              {(metadataBoolean || thumbnailBoolean) && (
                <Tooltip title="Source file metadata" arrow>
                  <a
                    onClick={() => handleGetMetadataClick(fileName, metadata_blob, thumbnail_blob)}
                    style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center", gap: "4px", cursor: "pointer" }}
                  >
                    <InfoOutlinedIcon style={{ fontSize: "16px", cursor: "pointer" }} />
                  </a>
                </Tooltip>
              )}

            </div>
          );
        },
      },
      {
        // Hidden by default, added only for search/filtering purposes.
        accessorKey: "description",
        header: "Description",
        enableEditing: false,
        size: 800,
      },
    ];

    if (!sessionStandard || !classifierHeaders || !searchHeaders) {
      return dynamicColumns;
    }

    // Conditionally add the newFileName column if it exists in the tableData
    if (mergedResults && mergedResults.length > 0 && mergedResults[0].hasOwnProperty('newFileName')) {
      dynamicColumns.push({
        accessorKey: "newFileName",
        header: "Coded File Name",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      });
    }

    // The rest of the columns are more complicated; the classifier names are extracted from the 'User Standard' and iterated over
    // The classifier name is then passed as the accessorKey to get the values for that code
    classifierHeaders.forEach((classifierName, index) => {
      // Get the results data associated with each code part which needs to be unpacked positionally (code, certainty, description, emoji)
      const classifierData = sessionStandard.classifiers.find(classifier => classifier.name === classifierName);
      dynamicColumns.push({
        accessorKey: `${classifierName}.code`,
        header: classifierName,
        filterSelectOptions: (classifierData && classifierData.data) ?
          classifierData.data.map(item => ({ value: item.code, label: `${item.code} - ${item.description}` })) : [],
        filterVariant: 'select',
        editVariant: 'select',
        muiTableBodyCellProps: () => ({
          style: { cursor: 'pointer' }, // Change the cursor to pointer on hover
        }),
        editSelectOptions: ({ cell, row }) => {

          let editSelectOptions

          if (classifierData.isHierarchy) {

            console.log(classifierData.parentId)

            let parentColumnValue;

            if (classifierData?.parentId) {
              const parentColumnTitle = sessionStandard.classifiers.find(classifier => classifier.id === classifierData.parentId).name
              parentColumnValue = row.original[parentColumnTitle].code
            }
            else {
              parentColumnValue = index > 0 ? row.original[classifierHeaders[index - 1]].code : null; // Use the previous column based on the index
            }

            console.log(parentColumnValue)

            // Filter the options based on the value of the other column
            editSelectOptions = (classifierData && classifierData.data)
              ? classifierData.data.filter(item => item.parent === parentColumnValue).map(item => ({
                value: item.code,
                label: `${item.code} - ${item.description}`
              }))
              : [];

          } else {
            editSelectOptions = (classifierData && classifierData.data)
              ? classifierData.data.map(item => ({
                value: item.code,
                label: `${item.code} - ${item.description}`
              }))
              : []
          }
          return editSelectOptions
        },
        muiEditTextFieldProps: ({ row, cell }) => ({
          select: true,
          onKeyDown: (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.shiftKey = true;
            }
          },
          onChange: (e) => {
            // Update the row's cached value so that it reflects in the table
            row._valuesCache[cell.column.id] = e.target.value;

            const updatedRow = allEdits && allEdits[row.id]
              ? {
                ...allEdits[row.id],
                [classifierName]: {
                  ...allEdits[row.id][classifierName],  // Spread the existing classifierName object
                  code: e.target.value,                  // Update the 'code' field within classifierName
                }
              }
              : {
                name: row.original.name,
                [classifierName]: {
                  code: e.target.value,                  // Add or update the 'code' field within classifierName
                }
              };

            // console.log(updatedRow)

            // console.log('All edits are', allEdits)

            setIsUnsavedEdits(true)

            setNewEdits((prevEditedRow) => ({
              ...prevEditedRow,
              [row.id]: updatedRow,
            }))
          }
        }),

        Cell: ({ cell, row, renderedCellValue }) => {
          if (!classifierData) {
            return null; // Return null if classifierData is not found, i.e. if there are no classifier names
          }

          const fileName = row.original.name;
          const cellValue = cell.getValue();

          // Find the corresponding selectedCodeResults for the current filename
          const selectedFileResults = mergedResults.find(item => item.name === fileName);

          // Check if selectedCodeResults is found
          if (!selectedFileResults || !selectedFileResults[classifierName]) {
            return null; // Return null if selectedCodeResults or classifier data is not found for the current filename
          }

          // In order to display a tooltip of the description (which is drawn from the user standard), we create a variable that uses the code part from the result as the key to access its properties from the user standard
          const selectedCodeInformation = classifierData.data.find(item => item.code === cell.getValue());

          // In order to display info from the results file (certainty and explanation) they need to be mapped to the code part
          const selectedCodeResults = baseResults.find(item => item.name === fileName)[classifierName];
          const selectedCodeEmoji = selectedCodeResults.emoji;
          const selectedCodeExplanation = selectedCodeResults.explanation;
          const isEdited = cellValue !== selectedCodeResults.code;

          return (
            <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }} spacing={1}>
              {/* Check if the cell value matches the classifier code */}
              <div style={{ width: '8px', display: 'flex', justifyContent: 'center' }}>
                {isEdited ? (
                  // Render "User edited" message and apply styles
                  <Tooltip title="Edited" arrow>
                    <Edit sx={{ width: '16px', color: '#0051b6' }} />
                  </Tooltip>
                ) : (
                  // Render emoji with tooltip
                  <Tooltip title={selectedCodeExplanation} arrow>
                    <span>{selectedCodeEmoji}</span>
                  </Tooltip>
                )}
              </div>
              <Tooltip title={selectedCodeInformation ? selectedCodeInformation.description : ''} arrow>
                <span>{renderedCellValue}</span>
              </Tooltip>
            </Stack>

          );
        },
      });
    });

    const filter = createFilterOptions();

    // Add column for tags
    if (showTags) {
      dynamicColumns.push({
        accessorKey: "tags",
        muiTableBodyCellProps: () => ({
          sx: { cursor: 'pointer', padding: '4px' },
        }),
        header: "Tags",
        width: 400,
        editDisplayMode: 'custom',
        filterFn: 'tagFilter',  // Indicate a custom filter function
        Cell: ({ cell, row }) => {
          const initialTags = cell.getValue()
          const [inputTags, setInputTags] = useState(initialTags);

          // Get all tags from other rows for available options
          const availableTags = mergedResults.map((row) => row.tags).flat();

          // Deduplicate the combined list of default tags and available tags
          const combinedTags = Array.from(new Set([...tags, ...availableTags]));

          return (
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              value={inputTags}
              onChange={(event, newValue) => {
                // Handle the case where a new tag with inputValue is added
                const updatedTags = newValue.map((item) =>
                  item.inputValue ? item.inputValue : item
                );
                setInputTags(updatedTags);
              }}
              options={combinedTags} // Combine default tags and tags from other rows
              renderInput={(params) => <TextField {...params} size="small" variant="standard"
                sx={{ '& .MuiInput-underline:before': { borderBottom: 'none' }, minWidth: '150px', overflow: 'visible' }} />}
              disableCloseOnSelect
              selectOnFocus
              clearOnBlur
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option.title || option}
                  </li>
                );
              }}
              onBlur={() => handleBlur(row, inputTags)}
              // Use TagsCell to render tags as chips
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index }); // `getTagProps` must be called here
                  return (
                    <Chip
                      variant="filled"
                      sx={{ backgroundColor: '#4a4bd6', color: 'white', }}
                      label={option.inputValue || option}
                      key={key}
                      {...tagProps}
                      onDelete={() => {
                        const newTags = value.filter((_, i) => i !== index); // Remove the tag at the given index
                        setInputTags(newTags); // Update the state with the new list of tags
                      }}
                    />
                  );
                })
              }
            />
          );
        },
        Edit: ({ cell, row }) => {
          const initialTags = cell.getValue()
          const [inputTags, setInputTags] = useState(initialTags);

          // Get all tags from other rows for available options
          const availableTags = mergedResults.map((row) => row.tags).flat();

          // Deduplicate the combined list of default tags and available tags
          const combinedTags = Array.from(new Set([...tags, ...availableTags]));

          return (
            <Autocomplete
              multiple
              freeSolo
              filterSelectedOptions
              value={inputTags}
              onChange={(event, newValue) => {
                // Handle the case where a new tag with inputValue is added
                const updatedTags = newValue.map((item) =>
                  item.inputValue ? item.inputValue : item
                );
                setInputTags(updatedTags);
              }}
              options={combinedTags} // Combine default tags and tags from other rows
              renderInput={(params) => <TextField {...params} size="small" variant="standard"
                sx={{ '& .MuiInput-underline:before': { borderBottom: 'none' }, minWidth: '150px' }} />}
              disableCloseOnSelect
              selectOnFocus
              clearOnBlur
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option;
              }}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    {option.title || option}
                  </li>
                );
              }}
              onBlur={() => handleBlur(row, inputTags)}
              // Use TagsCell to render tags as chips
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index }); // `getTagProps` must be called here
                  return (
                    <Chip
                      variant="filled"
                      sx={{ backgroundColor: '#4a4bd6', color: 'white' }}
                      label={option.inputValue || option}
                      key={key}
                      {...tagProps}
                      onDelete={() => {
                        const newTags = value.filter((_, i) => i !== index); // Remove the tag at the given index
                        setInputTags(newTags); // Update the state with the new list of tags
                      }}
                    />
                  );
                })
              }
            />
          );
        },

      });
    }

    // Add columns for search terms
    if (showSearchTerms) {
      searchHeaders.forEach(searchTermName => {
        dynamicColumns.push({
          accessorKey: searchTermName,
          header: searchTermName,
          muiTableBodyCellProps: () => ({
            style: { cursor: 'pointer' }, // Change the cursor to pointer on hover
          }),
          muiTableHeadCellProps: {
          },
          size: 400,
          muiEditTextFieldProps: ({ cell, row }) => ({
            multiline: true,
            placeholder: 'Enter text',
            onBlur: (e) => {
              const updatedValue = e.target.value;

              const updatedRow = allEdits && allEdits[row.id]
                ? {
                  ...allEdits[row.id],
                  [searchTermName]: updatedValue  // Set the value for the specific searchTermName key
                }
                : {
                  name: row.original.name,
                  [searchTermName]: updatedValue  // Set the value for the specific searchTermName key
                };

              //console.log('Updated row is', updatedRow);

              // Set the state with the new edited row data
              setIsUnsavedEdits(true);
              setNewEdits((prevEditedRow) => ({
                ...prevEditedRow,
                [row.id]: updatedRow,
              }));
            }
          }),

          Cell: ({ cell, row }) => {
            const fileName = row.original.name;
            const selectedFileResults = mergedResults.find(item => item.name === fileName);
            const searchResult = selectedFileResults ? selectedFileResults[searchTermName] : [];
            const selectedCodeResults = baseResults.find(item => item.name === fileName)[searchTermName];
            const isEdited = cell?.getValue() !== selectedCodeResults;

            return (
              <div>
                <div>
                  {searchResult &&
                    <div>
                      <ReactMarkdown rehypePlugins={[
                        [
                          rehypeExternalLinks,
                          { target: '_blank', rel: ['noopener', 'noreferrer'] },
                        ],
                      ]}
                      >
                        {convertPlainLinksToMarkdown(cell.getValue())}
                      </ReactMarkdown>
                    </div>
                  }
                  <div>
                    {isEdited ? (
                      // Render "User edited" message and apply styles
                      <span style={{ fontStyle: 'italic', color: 'blue' }}>
                        edited
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

              </div >
            );
          },
        });
      });
    }

    if (Object.keys(customColumns).length > 0) {
      Object.entries(customColumns).forEach(([accessorKey, header]) => {
        // console.log('Adding column', header)
        dynamicColumns.push({
          accessorKey: accessorKey,
          header: header,
          muiTableBodyCellProps: () => ({
            style: { cursor: 'pointer' }, // Change the cursor to pointer on hover
          }),
          muiTableHeadCellProps: {
            sx: {
              color: '#4a4bd6'
            },
          },
          size: 200,
          muiEditTextFieldProps: ({ cell, row }) => ({
            multiline: true,
            placeholder: 'Enter text',
            onBlur: (e) => {
              const updatedValue = e.target.value;

              const updatedRow = allEdits && allEdits[row.id]
                ? {
                  ...allEdits[row.id],
                  [cell.column.columnDef.accessorKey]: updatedValue  // Set the value for the specific searchTermName key
                }
                : {
                  name: row.original.name,
                  [cell.column.columnDef.accessorKey]: updatedValue  // Set the value for the specific searchTermName key
                };

              // console.log('Updated row is', updatedRow);

              // Set the state with the new edited row data
              setIsUnsavedEdits(true);
              setNewEdits((prevEditedRow) => ({
                ...prevEditedRow,
                [row.id]: updatedRow,
              }));
            }
          }),
          Cell: ({ cell, row }) => {
            const accessorKey = cell.column.columnDef.accessorKey
            const fileName = row.original.name;
            const selectedFileResults = mergedResults.find(item => item.name === fileName);
            const customResult = selectedFileResults ? selectedFileResults[accessorKey] : [];

            return (
              <div>
                <div>
                  {customResult &&
                    <div>
                      <ReactMarkdown rehypePlugins={[
                        [
                          rehypeExternalLinks,
                          { target: '_blank', rel: ['noopener', 'noreferrer'] },
                        ],
                      ]}
                      >
                        {convertPlainLinksToMarkdown(cell.getValue())}
                      </ReactMarkdown>
                    </div>
                  }
                </div>

              </div >
            );
          },
        })
      })
    }

    return dynamicColumns;

  }, [mergedResults, customColumns, showTags, showSearchTerms]);

  function convertPlainLinksToMarkdown(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // console.log(text)

    // Replace plain links with markdown links
    return text?.replace(urlRegex, (url) => `[${url}](${url})`);
  }



  const table = useMaterialReactTable({
    columns,
    data: fileNamesFiltered.length === 0
      ? []  // No filtering applied if blobsFilteredByProgress is empty
      : mergedResults.filter(row =>
        fileNamesFiltered.some(blob => blob === row.name)  // Filter only when blobsFilteredByProgress is not empty
      ),
    enableRowSelection: hasEditPermission,
    enableColumnPinning: true,
    enableColumnResizing: true,
    enableFilterMatchHighlighting: true,
    autoResetPageIndex: false,
    autoResetExpanded: false,
    autoResetGlobalFilter: false,
    autoResetGroupBy: false,
    autoResetSelectedRows: false,
    autoResetSortBy: false,
    autoResetFilters: false,
    autoResetRowState: false,
    initialState: {
      columnPinning: { left: ['newFileName'] },
      columnVisibility: { description: false },
      showGlobalFilter: true
    },
    filterFns: {
      // Handles cases where cells are empty
      globalFilter: (row, id, filterValue) => {
        // Get the cell value
        const cellValue = row.getValue(id);

        // Handle undefined or null values gracefully
        if (!cellValue) return false;
        // Perform the filter
        return cellValue
          .toString()
          .toLowerCase()
          .trim()
          .includes(filterValue.toString().toLowerCase().trim());
      },
      tagFilter: (row, columnId, filterValue) => {
        const tags = row.getValue(columnId); // Retrieve the tags array
        if (!filterValue) return true; // If no tags or no filterValue, show row

        // Check if any tag matches the filter value (case-insensitive)
        return tags.some((tag) => tag.toLowerCase().includes(filterValue.toLowerCase()))
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      //conditionally style selected rows
      sx: {
        backgroundColor: row.original.name === metadataTitle ? 'aliceBlue' : undefined,
      },
    }),
    globalFilterFn: 'globalFilter',
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack
        direction='row' spacing={2}
        sx={{
          paddingLeft: '8px',
          paddingTop: '4px',
        }}
      >
        <ButtonGroup variant='contained'>
          <Tooltip title="Save edits">
            <span>
              <IconButton
                onClick={handleSaveEdits}
                disabled={!isUnsavedEdits}
              >
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Clear unsaved edits">
            <span>
              <IconButton
                onClick={handleResetEdits}
                disabled={!isUnsavedEdits}
              >
                <ClearIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete all edits and restore">
            <span>
              <IconButton
                onClick={handleDeleteAllEdits}
                disabled={Object.keys(savedEdits).length === 0}
              >
                <DeleteForever />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup >
        <ButtonGroup variant='contained'>
          <Tooltip title="Generate file codes">
            <span>
              <IconButton
                onClick={handleOpenRenameSettingsModal}
                disabled={!hasEditPermission}
              >
                <DynamicForm />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Edit selected items in bulk">
            <span>
              <IconButton
                onClick={() => handleBulkEditClick(table.getSelectedRowModel().rows)}
                disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected() || !hasEditPermission}
              >
                <DriveFileRenameOutlineIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Add custom column">
            <span>
              <IconButton
                onClick={() => setNewColumn(true)}
                disabled={!hasEditPermission}
              >
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>
        <ButtonGroup variant='contained'>
          <Tooltip title={
            hasEditPermission
              ? 'Export Coded Files'
              : 'Export Coded Files - 🚫 Off-limits to Guest users'
          }
          >
            <span>
              <IconButton
                onClick={hasEditPermission ? handleDownloadFiles : null}
                disabled={
                  !hasEditPermission
                }
              >
                <FolderZipIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Export data to CSV">
            <span>
              <IconButton
                onClick={() => {
                  setSelectedRows(table.getSelectedRowModel().rows);
                  setIsOpen(true);
                }}
                disabled={!hasEditPermission}
              >
                <FileDownloadIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>
        <ButtonGroup variant='contained'>
          <Tooltip
            title={
              hasEditPermission
                ? 'Rerun processing for selected files'
                : 'Rerun processing for selected files - 🚫 Off-limits to Guest users'
            }
          >
            <span>
              <IconButton
                onClick={
                  hasEditPermission
                    ? (!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      ? handleRerunSelectedFiles
                      : () => handleRerunSelectedFiles(table.getSelectedRowModel().rows))
                    : null
                }
                disabled={!hasEditPermission}
              >
                <AutorenewIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              hasEditPermission
                ? 'Advanced Session Settings'
                : 'Advanced Session Settings - 🚫 Off-limits to Guest users'
            }
          >
            <span>
              <IconButton
                onClick={hasEditPermission ? handleOpenJSONDrawer : null}
                disabled={!hasEditPermission}
              >
                <SettingsIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ButtonGroup>
        {/* Toggle switch for Tags */}
            <FormControlLabel
              control={<Switch checked={showTags} onChange={handleToggleTags} sx={{ color: '#4a4bd6' }} />}
              label="Tags"
              labelPlacement="end"
            />
        {/* Toggle switch for Search Terms */}
        {
          searchHeaders && searchHeaders.length > 0 && (
            <FormControlLabel
              control={<Switch checked={showSearchTerms} onChange={handleToggleSearchTerms} sx={{ color: '#4a4bd6' }} />}
              label="Search Terms"
              labelPlacement="end"
            />
          )
        }
      </Stack>
    ),

    // Custom rendering for expand button
    enableExpandAll: true, // I don't think this is particularly better functionality but it saves space and I can't edit the column header
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    //conditionally render detail panel
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr', // Single column layout
          width: '70vw',
          maxWidth: '1000px', // Maximum width set to 100% of the container width
          overflowX: 'hidden', // Enable horizontal scrolling if necessary
          justifyContent: 'start', // Justify content to the start (left)
        }}
      >
        {row.original.description && row.getIsExpanded() && (
          <>
            {/* Display description in the first column */}
            <Typography sx={{ mb: 2, pl: 2, overflowX: 'hidden' }}>
              <b>Document Summary: </b>
              <ReactMarkdown rehypePlugins={[
                [
                  rehypeExternalLinks,
                  { target: '_blank', rel: ['noopener', 'noreferrer'] },
                ],
              ]}
              >
                {convertPlainLinksToMarkdown(row.original.description)}
              </ReactMarkdown>
            </Typography>
          </>
        )}
      </Box>
    ),

    // Additional options for editing
    editDisplayMode: 'cell',
    enableCellActions: true,
    enableClickToCopy: 'context-menu',
    editVariant: 'select',
    enableEditing: hasEditPermission,
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell); //set editing cell
        //optionally, focus the text field
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField) {
            textField.focus();
            textField.select?.();
          }
        });
      },

    }),
    // Set the row ID by finding the original index in mergedResults
    getRowId: (row) => mergedResults.findIndex((item) => item.name === row.name),
    renderCellActionMenuItems: ({
      cell,
      row,
      table,
      internalMenuItems,
    }) => [
        ...internalMenuItems, //render the copy and edit actions wherever you want in the list
        <MRT_ActionMenuItem
          icon={<ClearIcon />}
          key={3}
          label="Clear edits"
          disabled={!allEdits[row.id]?.hasOwnProperty(cell.column.columnDef.header)}
          onClick={() => {

            setAllEdits((prevEdits) => {
              // Create a shallow copy of the `allEdits` object
              const updatedEdits = { ...allEdits };

              // Check if the row edits exist
              if (updatedEdits[row.id]) {
                // Create a shallow copy of the nested edits for this row
                const rowEdits = { ...updatedEdits[row.id] };

                // Remove the specific column's edits
                delete rowEdits[cell.column.columnDef.header];

                // If the row still has other edits, update it
                if (Object.keys(rowEdits).length > 1) {
                  updatedEdits[row.id] = rowEdits;
                } else {
                  // If no edits are left for the row, remove the row from the edits
                  delete updatedEdits[row.id];
                }
              }

              // Return the updated edits state
              return updatedEdits;
            });

            setMergedResults((prevMergedResults) => {

              // Create a shallow copy of `prevMergedResults`
              const mergedData = prevMergedResults.map((item, index) => {
                // Check if the current row index matches row.id

                if (index === parseInt(row.id, 10)) {
                  // Get the baseRow from baseResults at the row.id index
                  const baseRow = baseResults[row.id];

                  if (baseRow) {
                    // Insert the matching value from baseRow into the correct column of merged row
                    return {
                      ...item,
                      [cell.column.columnDef.header]: baseRow[cell.column.columnDef.header],
                    };
                  }
                }
                // Return the row unchanged if it doesn't match
                return item;
              });

              return mergedData;
            });

            setIsUnsavedEdits(true)
          }}
          table={table}
        />
      ],
    renderColumnActionsMenuItems: ({ column, closeMenu, internalColumnMenuItems }) => {
      // Create a new array to store menu items
      const menuItems = [...internalColumnMenuItems];

      // Conditionally add the "Delete Column" button if the column's accessorKey contains '-custom'
      if (column.columnDef.accessorKey?.includes('-custom')) {
        menuItems.push(
          <MenuItem
            key="clear-edits" // Always add a key for list items
            onClick={() => {
              deleteColumn(column.columnDef.accessorKey);
              closeMenu(); // Close the menu after action
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Delete custom column</ListItemText>
          </MenuItem>
        );
      } else {
        menuItems.push(
          <MenuItem
            key="clear-edits" // Always add a key for list items
            onClick={() => {
              try {
                clearColumnEdits(column.columnDef.header); // Call your clear function
                closeMenu(); // Close the menu after action
                triggerAlert('Edits cleared')
              } catch { triggerAlert('Error whilst clearing edits', 'error') }

            }}
          >
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText>Clear edits</ListItemText>
          </MenuItem>
        );
      }

      // Return the modified menu items
      return menuItems;
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {showRenameSettings && (
        <RenameFiles onClose={handleCloseRenameSettingsModal} onDownload={handleRenameFiles} />
      )}
      {/* Bulk Editing*/}
      <Dialog open={bulkEdit} onClose={() => handleBulkEditClose()} scroll='paper'>
        <DialogTitle>
          <Typography variant='h5'>
            Edit rows
          </Typography>
          <Typography variant='h6'>
            {selectedRowCount} of {mergedResults.length} row(s) selected
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form>
            {Object.entries(selectedRows[0]?.original || {}).map(([key, value]) => {
              // Step 1: Skip the non-editable fields
              if (["description", "fileSource", "webUrl", "name", "tags"].includes(key)) {
                return null;
              }
              // Step 2: Check if the field should be a picklist
              if (typeof value === "object" && value?.code) {
                return (
                  <FormControl key={key} fullWidth margin="normal">
                    <Typography variant='caption'>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                    <Select
                      displayEmpty
                      value={edits[key]?.code || ""} // Ensure it defaults to an empty string if not selected
                      onChange={(e) => handleEdit(key, { code: e.target.value })}
                      renderValue={(selected) => {
                        if (selected.length === 0) { // Check for an empty or undefined value
                          return <div style={{ color: 'silver' }}>(unchanged)</div>;
                        }
                        return selected; // Display the selected value
                      }}
                    >
                      {
                        sessionStandard.classifiers.find(classifier => classifier.name === key)
                          ?.data.map(item => (
                            <MenuItem key={item.code} value={item.code}>
                              {item.code} - {item.description}
                            </MenuItem>
                          ))
                      }
                    </Select>
                  </FormControl>
                );
              }

              // Step 3: Render a text field for all other cases
              return (
                <>
                  <Typography variant='caption'>{key.charAt(0).toUpperCase() + key.slice(1)}</Typography>
                  <TextField
                    key={key}
                    multiline
                    value={edits[key]?.code} // Ensure it defaults to an empty string if not selected
                    placeholder="(unchanged)"
                    onChange={(e) => handleEdit(key, e.target.value)}
                    fullWidth
                    margin="normal"
                  />
                </>
              );
            })}
          </form>
        </DialogContent>
        {/* Modal Footer with Submit Button */}
        <DialogActions>
          <Button onClick={() => handleBulkEditClose()} className='btn btn-secondary'>
            Cancel
          </Button>
          <Button
            onClick={handleBulkEditSubmit} // This is your function for submitting the form
            className='btn btn-primary'
            disabled={Object.keys(edits).length === 0}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Adding Columns*/}
      <Dialog open={newColumn} onClose={() => setNewColumn(false)} scroll='paper' fullWidth maxWidth='sm'>
        <DialogTitle>
          Add column
        </DialogTitle>
        <DialogContent dividers>
          <form>
            <TextField
              key="header"
              value={header} // Ensure it defaults to an empty string if not selected
              placeholder="Enter column name"
              onChange={(e) => setHeader(e.target.value)}
              fullWidth
              margin="normal"
              sx={{ marginTop: 0 }}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission
                  addColumn(`${header.toLowerCase()}-custom`, header); // Add column logic
                  triggerAlert('New column added');
                  setNewColumn(false); // Close the dialog
                }
              }}
            />
          </form>
        </DialogContent>
        {/* Modal Footer with Submit Button */}
        <DialogActions>
          <Button onClick={() => setNewColumn(false)} className='btn btn-secondary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              try {
                addColumn(`${header.toLowerCase()}-custom`, header)
                triggerAlert('New column added')
              } catch {
                triggerAlert('Failed to add new column')
              } finally {
                setNewColumn(false)
                setHeader('')
              }
            }}
            className='btn btn-primary'
            disabled={header.length === 0}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {/* Drawer for displaying metadata */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer} variant="persistent">
        <div style={{ width: "600px", padding: "20px", display: "flex", flexDirection: "column", height: "100%" }}>
          {/* Header with Close Button */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
            <h4 style={{ margin: 0 }}>{metadataTitle}</h4>
            <IconButton onClick={handleCloseDrawer} style={{ cursor: "pointer" }}>
              <CloseIcon />
            </IconButton>
          </div>

          {/* Loading Indicator */}
          {isLoading ? (
            <div style={{
              position: "absolute",
              top: "25%", /* Position it roughly in the top third */
              left: "50%", /* Horizontally center the spinner */
              transform: "translateX(-50%)", /* Ensure it's truly centered */
              zIndex: 1000
            }}>
              <CircularProgress />
            </div>
          ) : (
            /* Metadata Content */
            <div style={{ flexGrow: 1, overflow: "auto"}}>
              {thumbnailSrc ? (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={thumbnailSrc} alt="Thumbnail" style={{ width: "100%", height: "auto" }} />
                  </div>
                
              ) : 
              (
                <></>
              )
              }
              {metadataJson ? (
                <ReactCodeMirror
                  value={metadataJson}
                  extensions={[json()]}
                  height="100%"
                  basicSetup={{ lineNumbers: true, foldGutter: true, highlightActiveLine: true }}
                  editable={false}
                  theme={materialLight}
                />
              ) : (
                <p>No source file metadata available</p>
              )}
            </div>
          )}
        </div>
      </Drawer>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          borderRadius: 2
        }}>
          <Typography variant="h6" gutterBottom>
            CSV Export Settings
          </Typography>
          <FormControlLabel
            control={<Checkbox checked={exportSelectedRows && selectedRows.length > 0} disabled={!selectedRows || selectedRows.length === 0} onChange={(e) => setExportSelectedRows(e.target.checked)} />}
            label="Only export selected rows"
          />
          <FormControlLabel
            control={<Checkbox checked={exportMetadata} onChange={(e) => setExportMetadata(e.target.checked)} />}
            label="Export source file metadata if available"
          />
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 0.1 }}>
            <Button className="btn btn-secondary" onClick={() => setIsOpen(false)} variant="outlined">Cancel</Button>
            <Button className="btn btn-primary" onClick={handleConfirmExport} variant="contained">Export</Button>
          </Box>
        </Box>
      </Modal>

    </>
  );

};

export default ResultsTable;
