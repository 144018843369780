import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap'
import HeaderBar from '../subcomponents/shared/HeaderBar';
import FooterBar from '../subcomponents/shared/FooterBar';
import ReactGA from 'react-ga';
import NavBar from './NavBar';

const DataProcessingAddendum = () => {

    useEffect(() => {
        document.title = "Data Processing Addendum | Hoppa"; // Set the browser tab title here
    }, []); // Empty dependency array ensures this runs only on component mount

    // Google analytics ping on component mount
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    const htmlContent = `
  <article>
    <style>
        [data-custom-class='body'], [data-custom-class='body'] * {
            background: transparent !important;
        }
        [data-custom-class='title'], [data-custom-class='title'] * {
            font - family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
        }
        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            font - family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
        }
        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font - family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
        }
        [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
            font - family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
        }
        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        }
        [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
        }
    </style>
    <div data-custom-class="body">
        <div>
            <strong><span style="font-size: 26px;">
                <span data-custom-class="title"><bdt class="block-component"></bdt>
                    <bdt class="question">DATA PROCESSING ADDENDUM (DPA)</bdt>
                </span>
            </span>
            </strong>
            <div><br></div>
            <strong>
                <span style="font-size: 15px;">
                    <span data-custom-class="subtitle">Last updated <bdt class="question">January 09, 2025</bdt>
                    </span>
                </span>
            </strong>
        </div>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    This Data Processing Addendum, including the Standard Contractual Clauses where applicable (“DPA”), is entered into between Hoppa Technologies Ltd. (“Hoppa”) and the entity identified in the Agreement (“Customer”) (each referred to as a “Party” and collectively as the “Parties”). This DPA is incorporated by reference into the applicable subscription agreement governing use of the Service (the “Agreement”) between the Parties. All capitalised terms used in this DPA but not defined will have the meaning set forth in the Agreement. To the extent of any conflict or inconsistency between this DPA, any previously executed data processing agreement, and the remaining terms of the Agreement, this DPA will govern.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        This DPA sets out the terms that apply when personal data is processed by Hoppa under the Agreement. The purpose of the DPA is to ensure such processing is conducted in accordance with Applicable Law and respects the rights of individuals whose personal data are processed under the Agreement.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            1. DEFINITIONS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Applicable Law(s)</b>. Any entity that Controls, is Controlled by, or is under common Control with Hoppa or Customer entity agreeing to these terms, where "Control” means ownership of more than 50% of the voting interests of the subject entity or having the power to direct or cause the direction of the management and policies of an entity, whether through ownership, by contract, or otherwise.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Hoppa</b>. Hoppa Technologies Ltd. a company incorporated in the United Kingdom, and its Affiliates.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>“controller”</b>, <b>“business operator”</b>, <b>“personal data”</b>, <b>“process”</b>, <b>“processing”</b>, <b>“processor”</b>, and <b>“data subject”</b> will have the same meanings as defined by Applicable Law. Other relevant terms such as <b>“business”</b>, <b>“business purpose”</b>, <b>“consumer”</b>, <b>“personal information”</b>, <b>“sale”</b> (including the terms <b>“sell”</b>, <b>“selling”</b>, <b>“sold”</b>, and other variations thereof), <b>“service provider”</b>, <b>“share”</b> or <b>“sharing”</b> for purposes of <b>“cross-context behavioral advertising”</b>, and <b>“third party”</b> have the meanings given to those terms under Applicable Law.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Customer Personal Data</b>. Personal data, personal information or personally identifiable information Customer uploads or otherwise inputs into the Service and which is processed in connection with the provision of the Service under the Agreement by Hoppa on behalf of the Customer. Unless otherwise agreed to in writing, Customer Personal Data processed pursuant to the Agreement explicitly excludes Restricted Data.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Data Privacy Principles</b>. The Data Privacy Framework principles (as supplemented by the Supplemental Principles).
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Data Privacy Frameworks</b>. The EU-U.S Data Privacy Framework (“EU-U.S. DPF”), the Swiss-U.S. Data Privacy Framework (“Swiss DPF”), and the UK Extension to the EU-U.S. DPF (“UK Extension”) as administered by the U.S. Department of Commerce.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>EEA</b>. The European Economic Area, which constitutes the member states of the European Union and Norway, Iceland, and Liechtenstein.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Restricted Data</b>. Personal data that may be categorised as “special categories of data” under Applicable Laws including, but not limited to, social security numbers, financial account numbers, credit card information, or health information.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Restricted Transfer</b>. (i) Where the GDPR applies, a transfer of personal data from the EEA to a country outside of the EEA which is not subject to an adequacy determination by the European Commission; (ii) where the UK GDPR applies, a transfer of personal data from the United Kingdom to any other country which is not subject to adequacy regulations adopted pursuant to Section 17A of the UK DPA 2018; and (iii) where the Swiss DPA applies, a transfer of personal data to a country outside of Switzerland which is not included on the list of adequate jurisdictions published by the Swiss Federal Data Protection and Information Commissioner.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Security Incident</b>. Means any confirmed breach of security that results in the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, Customer Personal Data processed by Hoppa and/or its subprocessors in connection with the provision of the Service.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>Security Incident</b>. Means (i) where the GDPR applies, the standard contractual clauses annexed to the European Commission's Implementing Decision 2021/914 of 4 June 2021 on standard contractual clauses for the transfer of personal data to third countries pursuant to Regulation (EU) 2016/679 of the European Parliament and of the European Council (available as of June 2021 <a data-custom-class="link" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj" rel="noopener noreferrer" target="_blank">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj</a>), (the “EU SCCs”); (ii) where the UK GDPR applies, the applicable standard data protection clauses adopted pursuant to Article 46(2)(c) or (d) of the UK GDPR including the standard data protection clauses issued by the commissioner under s119A(1) of the UK DPA 2018 as revised from time to time (“UK Addendum”); and (iii) where the Swiss DPA applies, the applicable standard data protection clauses issued, approved or recognised by the Swiss Federal Data Protection and Information Commissioner (the “Swiss SCCs”), in each case as completed as described in Section 9 (Data Transfers) below.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            2. RELATIONSHIP OF THE PARTIES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.1. Hoppa as a Processor and Service Provider</b>. The Parties acknowledge and agree that with regard to Customer Personal Data, Customer is a controller and business and Hoppa is a processor and service provider, as defined by Applicable Law.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>2.2. Hoppa as a Subprocessor</b>. In circumstances in which Customer may be a processor, Customer appoints Hoppa as Customer’s subprocessor, which will not change the obligations of either Customer or Hoppa under this DPA.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            3. CUSTOMER’S INSTRUCTIONS TO HOPPA
                        </span>
                    </strong>
                </span>
            </div>
        </div>
        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.1. Purpose Limitation</b>. Hoppa will process Customer Personal Data (a) in order to provide the Service in accordance with the Agreement; (b) with Customer’s lawful instructions as set forth under Section 3.3; (c) as necessary to comply with Applicable Law; and (d) as otherwise agreed in writing. Customer, as the controller, acknowledges that the Service as provided is not intended for the storage or use of Restricted Data. At its sole discretion, Customer determines all categories and types of Customer Personal Data it may submit and transfer to Hoppa through the Service. Customer is responsible for secure and appropriate use of the Service to ensure a level of security appropriate to the risk in respect of the Customer Personal Data and agrees that compliance and security measures as set forth in the Agreement and this DPA are deemed sufficient safeguards for processing of any such Restricted Data that Customer provides to the Service.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.2. No Sale of Personal Information/Sharing for Targeted Advertising</b>. Hoppa will not sell (as defined by Applicable Law) Customer Personal Data, share Customer Personal Data for purposes of cross-context behavioral advertising or otherwise process Customer Personal Data for any purpose other than as set forth in the Agreement, unless obligated to do so under Applicable Law. In such case, Hoppa will inform Customer of that legal requirement before such processing unless legally prohibited from doing so. Hoppa will not retain, use, or disclose Customer’s Personal Data for any commercial purposes (as defined by Applicable Law) other than to provide the Service. Hoppa understands its obligations as set forth in this section and will comply with them. Further details regarding Hoppa’s processing operations are set forth in Exhibit A.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>3.3. Lawful Instructions/Sharing for Targeted Advertising</b>. Customer appoints Hoppa as a processor (or subprocessor) to process Customer Personal Data on behalf of, and in accordance with, Customer’s instructions. Customer will not instruct Hoppa to process Customer Personal Data in violation of Applicable Law. Hoppa will promptly inform Customer if, in Hoppa’s opinion, an instruction from Customer infringes Applicable Law. The Agreement, including this DPA, along with Customer’s configuration of the Service (as Customer may be able to modify from time to time), constitutes Customer’s complete and final instructions to Hoppa regarding the processing of Customer Personal Data, unless otherwise agreed in writing.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            4. SUBPROCESSING
                        </span>
                    </strong>
                </span>
            </div>
        </div>
        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>4.1. Subprocessors</b>. Customer appoints Hoppa as a processor (or subprocessor) to process Customer Personal Data on behalf of, and in accordance with, Customer’s instructions. Customer will not instruct Hoppa to process Customer Personal Data in violation of Applicable Law. Hoppa will promptly inform Customer if, in Hoppa’s opinion, an instruction from Customer infringes Applicable Law. The Agreement, including this DPA, along with Customer’s configuration of the Service (as Customer may be able to modify from time to time), constitutes Customer’s complete and final instructions to Hoppa regarding the processing of Customer Personal Data, unless otherwise agreed in writing.
                    </span>
                </span>
            </span>
        <div><br></div>
        <table style="border-collapse: collapse; max-width: 100%; min-width: 450px;">
            <thead>
                <tr style="border-bottom: 1px solid black;">
                <th>Entity Name</th>
                <th>Subprocessing Activity</th>
                </tr>
            </thead>
            <tbody>
                <tr style="border-bottom: 1px solid black;">
                <td>Microsoft Corporation</td>
                <td>Cloud service provider</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Auth0 by Okta</td>
                <td>Access management</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Autodesk Inc.</td>
                <td>Workflow automation</td>
                </tr>
            </tbody>
        </table>
        <div><br></div>
        <span data-custom-class="body_text">
                    Hoppa will impose contractual obligations on any Subprocessor Hoppa appoints requiring it to protect Customer Personal Data to standards which are no less protective than those set forth under this DPA. Hoppa remains liable for its Subprocessors’ performance under this DPA to the same extent Hoppa is liable for its own performance. Hoppa will notify Customer in writing of new Subprocessors ten (10) business days before Hoppa authorises such Subprocessor to process Customer Personal Data (or in the case of an emergency, as soon as reasonably practicable). The subprocessor agreements to be provided under Clause 9 of the Standard Contractual Clauses may have all commercial information, or provisions unrelated to the Standard Contractual Clauses, redacted prior to sharing with Customer, and Customer agrees that such copies will be provided only upon Customer’s written request.
        </span>
        <div><br></div>
        <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>4.2. Right to Object</b>. Customer may object to Hoppa’s use of a new Subprocessor (based on reasonable grounds relating to data protection) by notifying Hoppa promptly in writing at <a data-custom-class="link" href="mailto:support@hoppa.ai" rel="noopener noreferrer" target="_blank">support@hoppa.ai</a> within thirty (30) days after receipt of Hoppa’s notice as described in Section 4.1. In the event Customer objects to a new Subprocessor, Hoppa will use commercially reasonable efforts to make available to Customer a change in the Service or Customer’s configuration or use of the Service to avoid processing of Customer Personal Data by the objected-to new Subprocessor. If Hoppa is unable to make available such change within a reasonable period of time, which will not exceed thirty (30) days, either Party may upon written notice terminate without penalty the applicable Proposal(s) or the Agreement.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            5. ASSISTANCE AND COOPERATION
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>5.1. Security</b>. Hoppa will use appropriate technical and organisational measures to protect Customer Personal Data that it processes. Such measures will take into account the state of the art, the costs of implementation and the nature, scope, context and purposes of processing, as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, so as to ensure a level of security appropriate to the risk. Hoppa will ensure that the persons Hoppa authorises to process Customer Personal Data are subject to written confidentiality agreements or a statutory obligation of confidentiality no less protective than the confidentiality obligations set forth in the Agreement.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>5.2. Security Incident Notification and Response</b>. To the extent required by Applicable Law and taking into account the nature of processing and the information available to Hoppa, Hoppa will assist Customer by notifying it of a Security Incident without undue delay or within the time period required under Applicable Law. To the extent available, this notification will include Hoppa’s then-current assessment of the following:
                    </span>
                    <div><br></div>
                    <span data-custom-class="body_text">
                    (a) the nature of the Security Incident, including, where possible, the categories and approximate number of data subjects concerned and the categories and approximate number of personal data records concerned;
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    (b) the likely consequences of the Security Incident; and
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    (c) measures taken or proposed to be taken by Hoppa to address the Security Incident, including, where applicable, measures to mitigate its possible adverse effects.
                    </span>
                    <div><br></div>
                    <span data-custom-class="body_text">
                    Hoppa will provide timely and periodic updates to Customer as additional information regarding the Security Incident becomes available. Customer acknowledges that any updates may be based on incomplete information. Hoppa will not assess the contents of Customer Data for the purpose of determining if such Customer Data is subject to any requirements under Applicable Law. Nothing in this DPA or in the Standard Contractual Clauses will be construed to require Hoppa to violate, or delay compliance with, any legal obligation it may have with respect to a Security Incident or other security incidents generally.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            6. RESPONDING TO INDIVIDUALS EXERCISING THEIR RIGHTS UNDER APPLICABLE LAW
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    To the extent legally permitted, Hoppa will refer the individual back to the Customer if Hoppa receives any requests from an individual seeking to exercise any rights afforded to them under Applicable Law regarding their personal data, which may include: access, rectification, restriction of processing, erasure (“right to be forgotten”), data portability, objection to the processing, or to not be subject to an automated individual decision making (each, a “Data Subject Request”). In the event Customer is unable to address a Data Subject Request in its use of the Service, Hoppa will, upon Customer’s request, provide commercially reasonable efforts to assist Customer in responding to such Data Subject Request, to the extent Hoppa is legally permitted to do so and the response to such Data Subject Request is required under Applicable Law. To the extent legally permitted, Customer will be responsible for any costs arising from Hoppa’s provision of additional functionality that Customer has requested to assist with a Data Subject Request.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            7. DPIAS AND CONSULTATION WITH SUPERVISORY AUTHORITIES OR OTHER REGULATORY AUTHORITIES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    Taking into account the nature of the processing and the information available to Hoppa, Hoppa will provide reasonable assistance to and cooperation with Customer for Customer’s performance of any legally required data protection impact assessment of the processing or proposed processing of Customer Personal Data involving Hoppa, and in consultation with supervisory authorities or other regulatory authorities as required, by providing Customer with any publicly available documentation for the Service or by complying with Section 10 (Audits) below. Additional support for data protection impact assessments or relations with regulators may be available and would require mutual agreement on fees, the scope of Hoppa’s involvement, and any other terms that the Parties deem appropriate.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            8. RESPONDING TO LAW ENFORCEMENT REQUESTS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    Hoppa will not disclose or provide access to any Processed Data to law enforcement unless required by law. If law enforcement contacts Hoppa with a demand for Processed Data, Hoppa will attempt to redirect the law enforcement agency to request that data directly from Customer. If compelled to disclose or provide access to any Processed Data to law enforcement, Hoppa will promptly notify Customer and provide a copy of the demand unless legally prohibited from doing so.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            9. DATA TRANSFERS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span data-custom-class="body_text">
                    <span data-custom-class="body_text">
                    <b>9.1.</b> Customer authorises Hoppa and its Subprocessors to make international transfers of Customer Personal Data in accordance with this DPA and Applicable Law.
                    </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>9.2.</b> Customer acknowledges and agrees that, subject to compliance with Applicable Laws, Hoppa may process Customer Personal Data where Hoppa, its Affiliates or its subprocessors maintain data processing operations. The Parties agree that when the transfer of Customer Personal Data from Customer (as “data exporter”) to Hoppa (as “data importer”) requires that certain appropriate safeguards (“Transfer Mechanism(s)”) are put in place, the Parties will be subject to the following frameworks and Transfer Mechanisms which will be deemed incorporated into and form a part of this DPA, as follows:
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (a) Order of precedence. In the event the Service is covered by more than one Transfer Mechanism, the transfer of personal data will be subject to a single Transfer Mechanism, as applicable, and in accordance with the following order of precedence: (a) the Data Privacy Frameworks; (b) the Standard Contractual Clauses as set forth in Section 9.2(c)-(e); and, if neither of the preceding is applicable, then (c) other alternative data Transfer Mechanisms permitted under Applicable Laws will apply.
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (b) Data Privacy Frameworks. To the extent Hoppa processes Customer Personal Data originating from the EEA, United Kingdom, or Switzerland, Hoppa represents that Hoppa is self-certified under the Data Privacy Frameworks and will adhere to the Data Privacy Principles.
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (c) EU Standard Contractual Clauses. The EU SCCs will apply to Restricted Transfers of Customer Personal Data protected by the GDPR and will be completed as follows:
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (i) The clauses as set forth in Module Two (controller to processor) will apply only to the extent Customer is a controller and Hoppa is a processor;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (ii) The clauses as set forth in Module Three (processor to processor) will only apply to the extent Customer is a processor and Hoppa is a subprocessor;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (iii) The “data exporter” is the Customer, and the exporter’s contact information is set forth below;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (iv) The “data importer” is Hoppa, and Hoppa’s contact information is set forth below;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (v) In Clause 7, the optional docking clause will apply;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (vi) In Clause 9, Option 2 will apply, and the time period for prior notice of subprocessor changes will be as set out in Section 4.1 of this DPA;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        (vii) In Clause 11, the optional language will not apply;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                           (viii) In Clause 17, Option 1 will apply, and the EU SCCs will be governed by Irish law;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                           (ix) In Clause 18(b), disputes will be resolved before the courts of Ireland; and
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                       (x) Annexes I and II of the Appendix are set forth in Exhibit A below.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (d) UK International Data Transfer Addendum. The UK Addendum will apply to Restricted Transfers of Customer Personal Data protected by the UK GDPR and will be completed as follows:
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (i) Table 1 will be completed with the relevant information in Annex I set forth in Exhibit A;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (ii) Table 2 will be completed with the selected modules and clauses the EU SCCs as identified in Section 9.2(c) of this DPA;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (iii) Table 3 will be completed with the relevant information from Annexes I and II set forth in Exhibit A and Section 4.1 of this DPA; and
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (iv) In Table 4, the Importer may end the UK Addendum in accordance with the terms of the UK Addendum.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (e) Swiss Standard Contractual Clauses. In relation to Restricted Transfers of Customer Personal Data protected by the Swiss DPA, the EU SCCs will also apply to such transfers in accordance with paragraph (c) above, subject to the following:
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (i) Any references in the EU SCCs to “Directive 95/46/EC” or “Regulation (EU) 2016/679” will be interpreted as references to the Swiss DPA;
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (ii)Any references to “EU”, “Union” and “Member State law” will be interpreted as references to Swiss law; and
                    </span>
                </span>
            </span>
            <div></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    (iii) Any references to the “competent supervisory authority” and “competent courts” will be interpreted as references to the relevant data protection authority and courts in Switzerland;
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    unless the EU SCCs as implemented above cannot be used to lawfully transfer such Customer Personal Data in compliance with the Swiss DPA, in which event the Swiss SCCs will instead be incorporated by reference and form an integral part of this DPA and will apply to such transfers. Where this is the case, the relevant Annexes or Appendices of the Swiss SCCs will be populated using the information contained in Exhibit A of this DPA (as applicable).
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>9.3.</b> It is not the intention of either Party to contradict or restrict any of the provisions set forth in the Standard Contractual Clauses and, accordingly, if and to the extent the Standard Contractual Clauses conflict with any provision of the Agreement (including this DPA) the Standard Contractual Clauses will prevail to the extent of such conflict.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>9.4.</b> By entering into this DPA, the Parties are deemed to be signing the applicable Standard Contractual Clauses and its applicable Appendices and Annexes.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            10. AUDITS
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.1. Audits</b>. Hoppa will allow for and contribute to audits conducted by Customer (or a third party auditor mutually agreed by both parties (“Auditor”)) of documentation, data, certifications, reports, and records relating to Hoppa's processing of Customer Personal Data (“Records”) for the sole purpose of determining Hoppa's compliance with this DPA subject to the terms of this Section 10 provided the Agreement remains in effect and such audit is at Customer’s sole expense (an “Audit”).
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.2. Written Notice</b>. Customer may request an Audit upon fourteen (14) days’ prior written notice to Hoppa, no more than once annually, except, in the event of a Security Incident occurring on Hoppa’s systems, in which case Customer may request an Audit within a reasonable period of time following such Security Incident.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.3. Further Written Requests and Inspections</b>. To the extent that the provision of Records does not provide sufficient information to allow Customer to determine Hoppa’s compliance with the terms of this DPA, Customer may, as necessary: (i) request additional information from Hoppa in writing, and Hoppa will respond to such written requests in within a reasonable period of time (“Written Requests”); and (ii) only where Hoppa's responses to such Written Requests do not provide the necessary level of information required by Customer, request access to Hoppa's premises, systems and staff, upon twenty one (21) days prior written notice to Hoppa (an “Inspection”) subject to the parties having mutually agreed upon (a) the scope, timing, and duration of the Inspection, (b) the use of an Auditor to conduct the Inspection, (c) the Inspection being carried out only during Hoppa's regular business hours, with minimal disruption to Hoppa’s business operations, and (d) all costs associated with the Inspection being borne by Customer (including Hoppa's time in connection with facilitating the Inspection, charged at Hoppa's then-current rates). Inspections will be permitted no more than once annually, except in the event of a Security Incident.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.4. Confidentiality</b>. In connection with any Audit or Inspection conducted in accordance with this Section 10, the Auditor must be bound by obligations of confidentiality no less protective than those contained in the Agreement. Auditors will not be entitled to receive any data or information pertaining to other clients of Hoppa or any other Confidential Information of Hoppa that is not directly relevant for the authorised purposes of the Audit or Inspection.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    <b>10.5. Corrective Action</b>. If any material non-compliance is identified by an Audit or Inspection, Hoppa will take prompt action to correct such non-compliance.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            11. RETURN OR DESTRUCTION OF CUSTOMER PERSONAL DATA
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                    Upon termination of the Agreement and written verified request from Customer’s authorised representative (which for purposes of this section is either a billing owner or an Administrator of the Service or a Customer personnel who has confirmed in writing that they are authorised to make decisions on behalf of the Customer), Hoppa will delete Customer Personal Data, unless prohibited by Applicable Law. If no such request is received by Hoppa following termination, Hoppa may delete Customer Personal Data in line with its obligations under Applicable Law.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            EXHIBIT A
                        </span>
                    </strong>
                </span>
            </div>
        </div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            ANNEX I TO THE STANDARD CONTRACTUAL CLAUSES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_2">
                    A. LIST OF PARTIES
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE TWO: Transfer controller to processor
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE THREE: Transfer processor to processor
                    </span>
                </span>
            </span>
            <div><br></div>
            <table style="border-collapse: collapse; max-width: 100%; min-width: 400px;">
                <thead>
                    <tr style="border-bottom: 1px solid black;">
                    <th>Data exporter(s):</th>
                    <th>Details/Descriptions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Name:</td>
                    <td>Customer, a user of the Service</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Address:</td>
                    <td>Address as listed in the Agreement</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Contact person’s name, position and contact details:</td>
                    <td>Contact information as listed in the Agreement</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Activities relevant to the data transferred under these Clauses:</td>
                    <td>Activities relevant are described in Section B below</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Signature and date:</td>
                    <td>See Section 9.4 of DPA</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Role (controller/processor):</td>
                    <td>Controller and/or processor</td>
                    </tr>
                </tbody>
            </table>
            <div><br></div>
            <table style="border-collapse: collapse; max-width: 100%; min-width: 400px;">
            <thead>
                <tr style="border-bottom: 1px solid black;">
                <th>Data importer(s):</th>
                <th>Details/Descriptions</th>
                </tr>
            </thead>
            <tbody>
                <tr style="border-bottom: 1px solid black;">
                <td>Name:</td>
                <td>Hoppa Technologies Ltd., provider of the Service</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Address:</td>
                <td>6 Landor Avenue, Killay, Swansea, SA2 7BP</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Contact person’s name, position and contact details:</td>
                <td>Sam Rees<br>support@hoppa.ai</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Activities relevant to the data transferred under these Clauses:</td>
                <td>Activities relevant are described in Section B below</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Signature and date:</td>
                <td>See Section 9.4 of DPA</td>
                </tr>
                <tr style="border-bottom: 1px solid black;">
                <td>Role (controller/processor):</td>
                <td>Processor</td>
                </tr>
            </tbody>
            </table>
            <div><br></div>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_2">
                    B. DESCRIPTION OF TRANSFER
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE TWO: Transfer controller to processor
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE THREE: Transfer processor to processor
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_3">
                    Categories of data subjects whose personal data is transferred
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    The categories of data subjects whose personal data is transferred are determined solely by the data exporter. In the normal course of the data importer's Service, the categories of data subject might include (but are not limited to): the data exporter’s personnel, customers, service providers, business partners, affiliates and other End Users.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    Categories of personal data transferred
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    The categories of personal data transferred are determined solely by the data exporter. In the normal course of the data importer's Service, the categories of personal data transferred might include (but are not limited to): name, email address, telephone, title, free text projects, and task lists entered by the data exporter or its End Users.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    Sensitive data transferred (if applicable) and applied restrictions or safeguards that fully take into consideration the nature of the data and the risks involved, such as for instance strict purpose limitation, access restrictions (including access only for staff having followed specialised training), keeping a record of access to the data, restrictions for onward transfers or additional security measures.
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    At its sole discretion, Customer determines all categories and types of Customer Personal Data it may submit and transfer to Hoppa through the Service. Customer is responsible for the secure and appropriate use of the Service to ensure a level of security appropriate to the risk in respect to Customer Personal Data and agrees that compliance and security measures as set forth in the Agreement and this DPA are deemed sufficient safeguards for processing of any such data that Customer provides to the Service.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    The frequency of the transfer (e.g. whether the data is transferred on a one-off or continuous basis).
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    Continuous with use of the Service.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    Nature of the processing
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    The provision of the Service to Customer in accordance with the Agreement.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    Purpose(s) of the data transfer and further processing
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    To provide the Service to Customer as described in the Agreement.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    The period for which the personal data will be retained, or, if that is not possible, the criteria used to determine that period
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    For as long as necessary to provide the Service as described in the Agreement, as legally or contractually required, or upon receipt of Customer’s written request for deletion.
                    </span>
                    <div><br></div>
                    <span data-custom-class="heading_3">
                    For transfers to (sub-) processors, also specify subject matter, nature and duration of the processing 
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    The subject matter, nature and duration of the processing are specified above and in the Agreement.
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_2">
                    C. COMPETETENT SUPERVISORY AUTHORITY
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE TWO: Transfer controller to processor
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE THREE: Transfer processor to processor
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_3">
                    Identify the competent supervisory authority/ies in accordance with Clause 13
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    Customer agrees the competent supervisory authority will be Information Commissioner’s Office (ICO) of United Kingdom.
                    </span>
                </span>
            </span>
            <div><br></div>
            <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0); font-size: 15px;">
                    <strong>
                        <span data-custom-class="heading_1">
                            ANNEX II TO THE STANDARD CONTRACTUAL CLAUSES
                        </span>
                    </strong>
                </span>
            </div>
        </div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_2">
                    TECHNICAL AND ORGANISATIONAL MEASURES INCLUDING TECHNICAL AND ORGANISATIONAL MEASURES TO ENSURE THE SECURITY OF THE DATA
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE TWO: Transfer controller to processor
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    MODULE THREE: Transfer processor to processor
                    </span>
                </span>
            </span>
            <div><br></div>
            <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="heading_3">
                    Description of the technical and organisational measures implemented by the data importer(s) (including any relevant certifications) to ensure an appropriate level of security, taking into account the nature, scope, context and purpose of the processing, and the risks for the rights and freedoms of natural persons.
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    Hoppa emphasises the following principles in the design and implementation of its security program and practices: (a) physical and environmental security to protect the Service against unauthorised access, use, or modification; (b) maintaining availability for operation and use of the Service; (c) confidentiality to protect customer data; and (d) integrity to maintain the accuracy and consistency of data over its life cycle.
                    </span>
                    <div><br></div>
                    <span data-custom-class="body_text">
                    Description of Hoppa’s current technical and organisational security measures can be found in Hoppa’s Evaluation Against the NCSC Cloud Security Principles, available upon request to <a data-custom-class="link" href="mailto:support@hoppa.ai" rel="noopener noreferrer" target="_blank">support@hoppa.ai</a>. Specific measures:
                    </span>
                </span>
                <div><br></div>
                <table style="border-collapse: collapse; max-width: 100%; min-width: 400px;">
                <thead>
                    <tr style="border-bottom: 1px solid black;">
                    <th>Measure</th>
                    <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures of pseudonymisation and encryption of personal data</td>
                        <td>Hoppa will encrypt Customer Data in transit and at rest using industry-standard encryption algorithms that are appropriate for the mechanism of transfer (e.g. TLS 1.2, AES-256).</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring ongoing confidentiality, integrity, availability and resilience of processing systems and services</td>
                        <td>Hoppa will implement and maintain a risk-based information security program that includes administrative, technical, and organisational safeguards designed to protect the confidentiality, integrity, and availability of Customer Data. Hoppa performs periodic assessments to monitor its information security program to identify risks and ensure controls are operating effectively by performing penetration tests, internal audits, and risk assessments. Hoppa maintains a risk management program to identify, monitor, and manage risks that may impact the confidentiality, integrity, and availability of Customer Data.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring the ability to restore the availability and access to personal data in a timely manner in the event of a physical or technical incident</td>
                        <td>Hoppa will implement and maintain a documented set of disaster recovery policies and procedures to enable the recovery or continuation of vital technology infrastructure and systems following a disaster. Additionally, Hoppa will perform annual tests of its disaster recovery plan and will make available a summary of the results to its customers. Hoppa will perform regular backups of Customer Data and ensure that backups have the same protections in place as production databases.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for user identification and authorisation</td>
                        <td>Access to Customer Data is restricted to authorised Hoppa personnel who are required to access Customer Data to perform functions as part of the delivery of services. Access to Customer Data must be through unique usernames and passwords and multi-factor authentication must be enabled. Access is disabled within one business day after an employee’s termination.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for the protection of data during transmission</td>
                        <td>Hoppa will encrypt Customer Data in transit and at rest using industry-standard encryption algorithms that are appropriate for the mechanism of transfer (e.g. TLS 1.2, AES-256).</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for the protection of data during storage</td>    
                        <td>Customer Data is stored with Microsoft Azure. Data backups are encrypted. Customer Data is encrypted in transit and at rest using industry-standard encryption algorithms that are appropriate for the mechanism of transfer (e.g. TLS 1.2, AES-256).</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring physical security of locations at which personal data are processed</td>
                        <td>Hoppa will ensure that all physical locations that process, store, or transmit Customer Data are located in a secure physical facility. Hoppa will review third-party security certifications (e.g. SOC 2 Type 2) of its third-party cloud hosting providers on at least an annual basis to ensure that appropriate physical security controls are in place.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring events logging</td>
                        <td>All access to information security management systems at Hoppa are restricted, monitored, and logged. At a minimum, log entries include date, timestamp, action performed, and the user ID or device ID of the action performed. The level of additional detail to be recorded by each audit log will be proportional to the amount and sensitivity of the information stored and/or processed on that system. All logs are protected from change.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring system configuration, including default configuration</td>
                        <td>To prevent and minimise the potential for threats to Hoppa’s systems, baseline configurations are required prior to deployment of any user, network, or production equipment. Baseline configurations are in place for wireless security settings in order to ensure strong encryption and replace vendor default settings as part of deployment of network devices. Systems are centrally managed and configured to detect and alert on suspicious activity.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for internal IT and IT security governance and management/td>
                        <td>IT Security Governance and Management structures and processes are designed to ensure compliance with data protection principles at their effective implementation. Hoppa will have a dedicated security team responsible for implementing, maintaining, monitoring, and enforcing security safeguards aligned with the information security management system.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for certification/assurance of processes and products</td>
                        <td>Hoppa’s information security framework will be based on the NCSC Cyber Essentials Scheme and will cover the following areas: security risk management, policies and procedures, security incident management, access controls, vulnerability management, physical security, operational security, corporate security, infrastructure security, product security, business continuity disaster recovery, personnel security, security compliance, and vendor security. Hoppa is certified with the NCSC Cyber Essentials Plus Scheme in the UK and is working towards engaging qualified external auditors to perform assessments of its information security program against the SOC 2 AICPA Trust Services Criteria for Security, Availability, and Confidentiality, and the following standards ISO/IEC 27001:2013, ISO/IEC 27017:2015, ISO/IEC 27018:2019, ISO/IEC 27701:2019.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring data minimisation</td>
                        <td>Hoppa only collects information that is necessary in order to provide the Services outlined in our <a data-custom-class="link" href="https://hoppa.ai/terms-and-conditions" rel="noopener noreferrer" target="_blank">Terms of Business</a>, <a data-custom-class="link" href="https://hoppa.ai/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>, and the Customer Agreement. Our employees are directed to access only the minimum amount of information necessary to perform the task at hand.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring data quality</td>
                        <td>Hoppa maintains web server and application log details that include any changes to sensitive configuration settings and files. At minimum, log entries include date, timestamp, action performed, and the user ID or the device ID of the action performed. Logs are protected from change. Users who would like to exercise their rights under applicable law to update information which is out of date or incorrect may do so at any time by emailing <a data-custom-class="link" href="mailto:support@hoppa.ai" rel="noopener noreferrer" target="_blank">support@hoppa.ai</a>. More information on data subject rights can be found in our <a data-custom-class="link" href="https://hoppa.ai/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring limited data retention</td>
                        <td>Hoppa will retain information for the period necessary to fulfil the purposes outlined in our <a data-custom-class="link" href="https://hoppa.ai/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>, unless a longer retention period is required or permitted by law, or where the Customer Agreement requires or permits specific retention or deletion periods. Customer may request deletion of data at any time and Customer Personal Data is deleted or anonymised upon termination of the Agreement.</td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                        <td>Measures for ensuring accountability</td>
                        <td>Hoppa has established a comprehensive GDPR compliance program and is committed to partnering with its customers and vendors on GDPR compliance eﬀorts. Some significant steps Hoppa has taken to align its practices with the GDPR include: Revisions to our policies and contracts with our partners, vendors, and users; Enhancements to our security practices and procedures; Closely reviewing and mapping the data we collect, use, and share; Creating more robust internal privacy and security documentation; Training employees on GDPR requirements and privacy and security best practices generally; and Carefully evaluating and building a data subject rights’ policy and response process.
                        <div><br></div>
                        Below, we provide additional details about the core areas of Hoppa’s GDPR compliance program and how customers can use Hoppa to support their own GDPR compliance initiatives. Appointed a Data Protection Officer (“DPO”), who can be reached at <a data-custom-class="link" href="mailto:support@hoppa.ai" rel="noopener noreferrer" target="_blank">support@hoppa.ai</a>.
                        <div><br></div>
                        Hoppa oﬀers its customers who are controllers of EU personal data the option to enter into a robust data processing addendum (“DPA”) under which Hoppa commits to process and safeguard personal data in accordance with GDPR requirements. This includes current Standard Contractual Clauses and Hoppa’s commitment to process personal data consistent with the instructions of the data controller.
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid black;">
                    <td>Measures for allowing data portability and ensuring erasure</td>
                        <td>Hoppa provides a mechanism for individuals to exercise their privacy rights in accordance with applicable law. Individuals may contact Hoppa at any time using this form. More information can be found in our <a data-custom-class="link" href="https://hoppa.ai/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>.
                        </td>
                    </tr>
                </tbody>
                </table>
            </span>
            <div><br></div>
            <span data-custom-class="heading_3">
                    For transfers to (sub-) processors, also describe the specific technical and organisational measures to be taken by the (sub-) processor to be able to provide assistance to the controller and, for transfers from a processor to a sub-processor, to the data exporter
                    </span>
                    <div></div>
                    <span data-custom-class="body_text">
                    As described in the DPA, Hoppa has measures in place to provide assistance to controllers as needed. Such measures include, but are not limited to, the ability to delete all Customer Personal Data associated with a domain and making available APIs to allow controllers to better manage and control their data. With regard to Data Subject Requests, in the event the controller is unable to address a Data Subject Request in its use of the Service, Hoppa will, upon request, provide commercially reasonable efforts to assist the controller in responding to such Data Subject Request, to the extent Hoppa is legally permitted to do so and the response to such Data Subject Request is required under Applicable Law. Data subjects may also exercise their rights by contacting Hoppa at any time by emailing <a data-custom-class="link" href="mailto:support@hoppa.ai" rel="noopener noreferrer" target="_blank">support@hoppa.ai</a>.
                    </span>
                </span>
                <div><br></div>

                        `
    return (
        <div >
            <NavBar variant='blue' />
            <div className="full-width-primary-section">
                <Container>
                    <Container className='rounded-box' style={{ marginTop: '9%', padding: '4vw' }}>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </Container>
                </Container>
                <footer>
                    <FooterBar />
                </footer>
            </div>
        </div>
    )
}

export default DataProcessingAddendum;
